import {
  UseMutationOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
  useQueries,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { IAllCompanyInfo, IAuth, ICompanyInfo, ICompanyRole } from "types/auth";
import axios, { AxiosResponse } from "axios";
import {
  fetchAuthDataByRefreshToken,
  fetchDocument,
  fetchIssuesFromArray,
  fetchProjects,
  fetchCompanyUsers,
  updateCompanyUsers,
  IFetchCompanyRequestParams,
  loginByCode,
  fetchCompanyData,
  inviteCompanyUsers,
  deleteCompanyRoles,
  createProperty,
  fetchAllCompanyData,
  updateCompanyData,
  fetchApiKeys,
  createApiKeys,
  uploadAccountLogo,
  createExternalApiRecord,
  updateCCIntegration,
  createDocumentURL,
  getCategories,
  createTemplateFromGlobal,
  getTemplates,
  updateDocument,
  createTemplate,
  uploadIssueImagePresigned,
  uploadS3Image,
  deleteTemplate,
  updateTemplate,
  updateProperty,
  fetchDocumentList,
  deleteDocument,
  getProjectsWithSearchPagination,
  createIssuesFromTemplateMultipleProperties,
  fetchAllProjects,
  createCategory,
  deleteCategory,
  initCCamIntegration,
} from "./requests";
import {
  IDocument,
  IIssue,
  IPagination,
  ICompanyUser,
  IInviteCompanyUsers,
  ICreatePropertyPayload,
  ICreatedPropertyResponse,
  IApiKeys,
  ICategory,
  IPropertyItem,
  IProperty,
} from "types/entities";
import { useInView } from "react-intersection-observer";
import { ITemplateIssue } from "modules/CompanySettings/subroutes/Templates";

interface IALoginByCodeQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IAuth>>> {
  code: string;
}

export function useLoginByCode({ code, ...rest }: IALoginByCodeQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    [code],
    async () => {
      return await loginByCode(code, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,

      retry: false,
      ...rest,
    }
  );
}

interface IAuthByRefreshTokenQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IAuth>>> {
  token: string;
}

export function useAuthByRefreshToken({
  token,
  ...rest
}: IAuthByRefreshTokenQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    [token],
    async () => {
      return await fetchAuthDataByRefreshToken(token, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetDocumentQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IDocument>>> {
  companyId: string;
  projectId: string;
  documentId: string;
}

export function useGetDocument({
  companyId,
  projectId,
  documentId,
  ...rest
}: IGetDocumentQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getDocument", companyId, projectId, documentId],
    async () => {
      return await fetchDocument(companyId, projectId, documentId, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetIssuesFromArrayQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IIssue[]>>> {
  companyId: string;
  projectId: string;
  issueArray: string[];
}

export function useGetIssuesFromArray({
  companyId,
  projectId,
  issueArray,
  ...rest
}: IGetIssuesFromArrayQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getIssuesFromArray", companyId, projectId, issueArray],
    async () => {
      return await fetchIssuesFromArray(
        companyId,
        projectId,
        issueArray,
        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetProjectQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<any>>> {
  companyId: string;
  projectId: string;
}

export function useGetProject({
  companyId,
  projectId,

  ...rest
}: IGetProjectQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getProject", companyId, projectId],
    async () => {
      return await fetchProjects(
        companyId,
        projectId,

        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetAllProjectsQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<any>>> {
  companyId: string;
}

export function useGetAllProjects({
  companyId,

  ...rest
}: IGetAllProjectsQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return useQuery(
    ["getAllProjects", companyId],
    async () => {
      return await fetchAllProjects(companyId, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetCompanyUsers
  extends Partial<UseQueryOptions<AxiosResponse<IPagination<ICompanyUser>>>>,
    Partial<IFetchCompanyRequestParams> {}

export function useGetCompanyUsers({
  companyId,
  filters,
  PageSize,
  LastEvaluatedKey = null,
  ...rest
}: IGetCompanyUsers) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getCompanyUsers", companyId, filters, PageSize, LastEvaluatedKey],
    async () => {
      return await fetchCompanyUsers<IPagination<ICompanyUser>>(
        companyId,
        filters,
        PageSize,
        LastEvaluatedKey,
        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IGetInfiniteCompanyUsers extends Partial<IGetCompanyUsers> {}

export function useGetInfiniteCompanyUsers({
  companyId,
  filters,
  PageSize,
  ...rest
}: IGetInfiniteCompanyUsers) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const filtersValues = Object.values(filters || {});
  const { fetchNextPage, isFetching, refetch, ...settings } = useInfiniteQuery(
    ["getCompanyUsers", companyId, PageSize, ...filtersValues],
    async ({ pageParam }) => {
      return await fetchCompanyUsers<IPagination<ICompanyUser>>(
        companyId,
        filters,
        PageSize,
        pageParam,
        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.LastEvaluatedKey;
      },
      retry: false,
      keepPreviousData: true,
      ...rest,
    }
  );
  const { ref } = useInView({
    //react-intersection-observer
    initialInView: true,
    onChange: (inView) => {
      if (inView) {
        fetchNextPage();
      }
    },
  });
  return { ...settings, ref, fetchNextPage };
}

interface IUpdateCompanyUser
  extends Partial<UseMutationOptions<AxiosResponse<ICompanyUser>, any, any>> {
  companyId: string;
}

interface ICompanyUserPayload {
  id: string[];
  status?: string;
  order_access?: string;
  user_management?: boolean;
  order_notification?: boolean;
}

export function useUpdateCompanyUsers({
  companyId,
  ...rest
}: IUpdateCompanyUser) {
  return useMutation<AxiosResponse<ICompanyUser>, any, any>(
    async (data: ICompanyUserPayload[]) =>
      await updateCompanyUsers(data, companyId),
    {
      ...rest,
    }
  );
}

interface IGetCompanyData
  extends Partial<UseQueryOptions<AxiosResponse<ICompanyInfo>>> {
  companyId: string;
}

export function useGetCompanyData({ companyId, ...rest }: IGetCompanyData) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return useQuery(
    ["getCompanyData", companyId],
    async () => {
      return await fetchCompanyData(companyId, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,

      ...rest,
    }
  );
}

interface IGetAllCompanyData
  extends Partial<UseQueryOptions<AxiosResponse<IAllCompanyInfo>>> {}

export function useGetAllCompanyData({ ...rest }: IGetAllCompanyData) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return useQuery(
    ["getAllCompanyData"],
    async () => {
      return await fetchAllCompanyData(source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,

      ...rest,
    }
  );
}

export interface IUpdatetedInvitedUserResponse {
  params: IInviteCompanyUsers;
  roleAdded: boolean;
  existingUserLevel: any;
  emailConfirmed: any;
}

export function useInviteCompanyUsers() {
  return useMutation<AxiosResponse<IUpdatetedInvitedUserResponse>, any, any>(
    async (data: Partial<IInviteCompanyUsers>[]) =>
      await inviteCompanyUsers<IUpdatetedInvitedUserResponse>(data)
  );
}

interface IDeleteCompanyUser
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {}

interface ICompanyUserPayload {
  companyId: string;
  email: string[];
}

export function useDeleteCompanyUser({ ...rest }: IDeleteCompanyUser) {
  return useMutation<AxiosResponse<any>, any, any>(
    async (data: ICompanyUserPayload) =>
      await deleteCompanyRoles(data.companyId, data.email),
    {
      ...rest,
    }
  );
}

interface IUserMultipleCompanies
  extends Partial<UseQueryOptions<AxiosResponse<ICompanyInfo>>> {
  querySource: any[];
}

export function useMultipleUserCompanies({
  querySource,
  ...rest
}: IUserMultipleCompanies) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return useQueries({
    queries: querySource.map((queryData, i) => {
      return {
        queryKey: ["getCompanyDataBatch", i],
        queryFn: () =>
          fetchCompanyData((queryData as ICompanyRole).companyId, source),
        ...rest,
        onError: () => {},
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        useErrorBoundary: false,

        retry: false,
      };
    }),
  });
}

export function useCreateProperty() {
  return useMutation<AxiosResponse<ICreatedPropertyResponse>, any, any>(
    async (data: ICreatePropertyPayload) =>
      await createProperty<ICreatedPropertyResponse>(data)
  );
}

interface IUpdateCompanyData
  extends Partial<UseMutationOptions<AxiosResponse<ICompanyInfo>, any, any>> {
  companyId: string;
}

export function useUpdateCompanyData({
  companyId,
  ...rest
}: IUpdateCompanyData) {
  return useMutation<AxiosResponse<ICompanyInfo>, any, any>(
    async (data: ICompanyInfo) => await updateCompanyData(data, companyId),
    {
      ...rest,
    }
  );
}

interface IUploadAccountLogo
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IUploadLogoPayload {
  fileData: File;
  companyId: string;
}

export function useUploadAccountLogo({
  companyId,
  ...rest
}: IUploadAccountLogo) {
  return useMutation<AxiosResponse<any>, any, any>(
    async ({ fileData, companyId }: IUploadLogoPayload) =>
      await uploadAccountLogo(fileData, companyId),
    {
      ...rest,
    }
  );
}

interface IGetApiKeysQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IApiKeys>>> {
  companyId: string;
  whichKeys: string;
}

export function useGetApiKeys({
  companyId,
  whichKeys,
  ...rest
}: IGetApiKeysQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getApiKeys", companyId, whichKeys],
    async () => {
      return await fetchApiKeys(companyId, whichKeys, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface ICreateApiKeyOptions
  extends Partial<UseMutationOptions<AxiosResponse<ICompanyInfo>, any, any>> {
  companyId: string;
}

export function useCreateApiKey({ companyId, ...rest }: ICreateApiKeyOptions) {
  return useMutation<any, any>(async () => await createApiKeys(companyId), {
    ...rest,
  });
}

interface ICreateExternalApiRecordOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface ICreateExternalApiRecordPayload {
  value: string;
  description: string;
}

export function useCreateExternalApiRecord({
  companyId,
  ...rest
}: ICreateExternalApiRecordOptions) {
  return useMutation<any, any, any>(
    async ({ value, description }: ICreateExternalApiRecordPayload) =>
      await createExternalApiRecord(companyId, value, description),
    {
      ...rest,
    }
  );
}

interface IInitCCamIntegrationOptions {} //TODO - APPLY THIS EVERYWHERE
interface IInitCCamIntegrationPayload {
  //TODO - APPLY THIS EVERYWHERE
  companyId: string;
  addIssues: boolean;
  apiKey: string;
}

export function useInitCCamIntegration({
  ...rest
}: IInitCCamIntegrationOptions) {
  //TODO - APPLY THIS EVERYWHERE
  return useMutation<any, any, any>(
    async (
      { companyId, addIssues, apiKey }: IInitCCamIntegrationPayload //TODO - APPLY THIS EVERYWHERE
    ) => await initCCamIntegration(companyId, addIssues, apiKey),
    {
      ...rest,
    }
  );
}

interface IGetCategoriesQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<ICategory[]>>> {
  companyId: string;
}

export function useGetCategories({
  companyId,

  ...rest
}: IGetCategoriesQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getCategories", companyId],
    async () => {
      return await getCategories(companyId, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface ICreateCategoryOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

export function useCreateCategory({
  companyId,
  ...rest
}: ICreateCategoryOptions) {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>(
    async ({ category }: { category: string }) =>
      await createCategory(companyId, category),
    {
      ...rest,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["getCategories"] });
      },
    }
  );
}

interface IDeleteCategoryOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IDeleteCategoryPayload {
  category: string;
}

export function useDeleteCategory({
  companyId,
  ...rest
}: IDeleteCategoryOptions) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<any>, any, any>(
    async ({ category }: IDeleteCategoryPayload) =>
      await deleteCategory(companyId, category),
    {
      ...rest,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["getCategories"] });
      },
    }
  );
}

interface IUpdateCCIntegrationOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IUpdateCCIntegrationPayload {
  action: string;
}

export function useUpdateCCIntegration({
  companyId,
  ...rest
}: IUpdateCCIntegrationOptions) {
  return useMutation<any, any, any>(
    async ({ action }: IUpdateCCIntegrationPayload) =>
      await updateCCIntegration(companyId, action),
    {
      ...rest,
    }
  );
}

interface ICreateDocumentURLOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface ICreateDocumentURLPayload {
  propertyId: string;
  documentId: string;
  origin: string;
  private_desc: boolean;
  price: boolean;
}

export function useCreateDocumentURL({
  companyId,
  ...rest
}: ICreateDocumentURLOptions) {
  return useMutation<any, any, any>(
    async ({
      propertyId,
      documentId,
      origin,
      private_desc,
      price,
    }: ICreateDocumentURLPayload) =>
      await createDocumentURL(
        companyId,
        propertyId,
        documentId,
        origin,
        private_desc,
        price
      ),
    {
      ...rest,
    }
  );
}

interface ICreateTemplateFromGlobalOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface ICreateTemplateFromGlobalPayload {
  id: string;
}

export function useCreateTemplateFromGlobal({
  companyId,
  ...rest
}: ICreateTemplateFromGlobalOptions) {
  return useMutation<any, any, any>(
    async ({ id }: ICreateTemplateFromGlobalPayload) =>
      await createTemplateFromGlobal(companyId, id),
    {
      ...rest,
    }
  );
}

interface IGetTemplatesQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<ITemplateIssue[]>>> {
  companyId: string | undefined;
  tab: string;
}

export function useGetTemplates({
  companyId,
  tab,
  ...rest
}: IGetTemplatesQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getTemplates", companyId, tab],
    async () => {
      return await getTemplates(companyId, source);
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IUpdateDocumentOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IUpdateDocumentPayload {
  propertyId: string;
  id: string;
  form_data: string[];
  name: string;
}

export function useUpdateDocument({
  companyId,
  ...rest
}: IUpdateDocumentOptions) {
  return useMutation<any, any, any>(
    async ({ propertyId, id, form_data, name }: IUpdateDocumentPayload) =>
      await updateDocument(companyId, propertyId, id, form_data, name),
    {
      ...rest,
    }
  );
}

interface ICreateTemplateOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface ICreateTemplatePayload {
  companyId: string;
  fileName: string;
  priority: number;
  hazzard: boolean;
  price: number;
  category: string;
  recommend: boolean;
  label: string;
  description: string;
  description_int: string;
  status: string;
}

export function useCreateTemplate({
  companyId,
  ...rest
}: ICreateTemplateOptions) {
  return useMutation<any, any, any>(
    async ({
      companyId,
      fileName,
      priority,
      hazzard,
      price,
      recommend,
      category,
      label,
      description,
      description_int,
      status,
    }: ICreateTemplatePayload) =>
      await createTemplate(
        companyId,
        fileName,
        priority,
        category,
        hazzard,
        price,
        recommend,
        label,
        description,
        description_int,
        status
      ),
    {
      ...rest,
    }
  );
}

interface IUploadIssueImagesOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
  projectId: string;
}

interface IUploadIssueImagesPayload {
  imageNames: string[];
}

export function useUploadIssueImagesPresignedUrl({
  companyId,
  projectId,
  ...rest
}: IUploadIssueImagesOptions) {
  return useMutation<any, any, any>(
    async ({ imageNames }: IUploadIssueImagesPayload) =>
      await uploadIssueImagePresigned(companyId, projectId, imageNames),
    {
      ...rest,
    }
  );
}

interface IUploadS3ImageOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {}

interface IUploadS3ImagePayload {
  url: string;
  image: File;
  contentType: string;
}

export function useUploadS3Image({ ...rest }: IUploadS3ImageOptions) {
  return useMutation<any, any, any>(
    async ({ url, image, contentType }: IUploadS3ImagePayload) =>
      await uploadS3Image(url, image, contentType),
    {
      ...rest,
    }
  );
}

interface IDeleteTemplateOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {}

interface IDeleteTemplatePayload {
  companyId: string;
  templateId: string;
}

export function useDeleteTemplate({ ...rest }: IDeleteTemplateOptions) {
  return useMutation<AxiosResponse<any>, any, any>(
    async ({ companyId, templateId }: IDeleteTemplatePayload) =>
      await deleteTemplate(companyId, templateId),
    {
      ...rest,
    }
  );
}

interface IUpdateTemplateOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IUpdateTemplatePayload {
  companyId: string;
  id: string;
  data: Partial<ITemplateIssue>;
}

export function useUpdateTemplate({
  companyId,
  ...rest
}: IUpdateTemplateOptions) {
  return useMutation<any, any, any>(
    async ({ companyId, id, ...data }: IUpdateTemplatePayload) =>
      await updateTemplate({
        companyId,
        id,
        ...data,
      }),
    {
      ...rest,
    }
  );
}

interface IUpdatePropertyOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}

interface IUpdatePropertyPayload {
  data: Partial<IPropertyItem>;
}

export function useUpdateProperty({
  companyId,
  ...rest
}: IUpdatePropertyOptions) {
  return useMutation<any, any, any>(
    async ({ data }: IUpdatePropertyPayload) => await updateProperty(data),
    {
      ...rest,
    }
  );
}

interface IGetProjectsWithSearchPaginationQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IPagination<IPropertyItem>>>> {
  companyId: string;
  pageSize: number;
  lastEvaluatedKey?: string;
  search?: string;
}

export function useGetProjectsWithSearchPagination({
  companyId,
  pageSize,
  lastEvaluatedKey,
  search,

  ...rest
}: IGetProjectsWithSearchPaginationQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getProject", companyId, pageSize, lastEvaluatedKey, search],
    async () => {
      return await getProjectsWithSearchPagination<IPagination<IPropertyItem>>(
        companyId,
        pageSize,
        lastEvaluatedKey,
        search,
        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface ICreateIssuesFromTemplateMultiplePropertiesOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {
  companyId: string;
}
interface ICreateIssuesFromTemplateMultiplePropertiesPayload {
  propertyId: string | string[];
  templateId: string;
  numIssues: string;
  companyId: string;
  template: {
    fileName: string;
    priority: number;
    hazzard: boolean;
    price: number;
    category: string;
    recommend: boolean;
    label: string;
    description: string;
    description_int: string;
    status: string;
  };
}

export function useCreateIssuesFromTemplateMultipleProperties({
  companyId,
  ...rest
}: ICreateIssuesFromTemplateMultiplePropertiesOptions) {
  return useMutation<any, any, any>(
    async ({
      propertyId,
      companyId,
      templateId,
      numIssues,
      template,
    }: ICreateIssuesFromTemplateMultiplePropertiesPayload) =>
      await createIssuesFromTemplateMultipleProperties(
        propertyId,
        companyId,
        templateId,
        numIssues,
        template
      ),
    {
      ...rest,
    }
  );
}

interface IGetDocumentListQueryOptions
  extends Partial<UseQueryOptions<AxiosResponse<IDocument[]>>> {
  companyId: string;

  filter?: {
    filterList?: {
      filterName: string;
      filterValue: string;
    }[];
    sort?: string;
  };
}

export function useGetDocumentList({
  companyId,

  filter,
  ...rest
}: IGetDocumentListQueryOptions) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return useQuery(
    ["getDocument", companyId, filter],
    async () => {
      return await fetchDocumentList(
        companyId,

        filter,
        source
      );
    },
    {
      //@ts-ignore
      refetchOnWindowFocus: false,
      retry: false,
      ...rest,
    }
  );
}

interface IDeleteDocumentOptions
  extends Partial<UseMutationOptions<AxiosResponse<any>, any, any>> {}

interface IDeleteDocumentPayload {
  companyId: string;
  propertyId: string;
  id: string;
}

export function useDeleteDocument({ ...rest }: IDeleteDocumentOptions) {
  return useMutation<AxiosResponse<any>, any, any>(
    async ({ companyId, propertyId, id }: IDeleteDocumentPayload) =>
      await deleteDocument(companyId, propertyId, id),
    {
      ...rest,
    }
  );
}
