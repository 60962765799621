import { useEffect } from "react";

export function useOnClickOutside(
  ref: React.MutableRefObject<
    HTMLDivElement | HTMLParagraphElement | HTMLButtonElement | null
  >,
  handler: any,
  refButton?: Array<React.MutableRefObject<any | null>>
) {
  useEffect(() => {
    const element = ref.current;
    const listener = (event: any) => {
      let returnFlag = false;
      refButton?.forEach((refs) => {
        const refFromArray = refs.current;

        if (!refFromArray || refFromArray.contains(event.target)) {
          returnFlag = true;
          return;
        }
      });
      if (returnFlag) {
        return;
      }

      if (!element || element.contains(event.target)) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, refButton]);
}
