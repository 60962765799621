import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { ILocation } from "types/utility";
import { debounce } from "lodash";
import { useOnClickOutside } from "hooks/utility/useOnClickOutside";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import { GoogleCustom } from "utilities/customGoogleGeocoding";

import AutoCompleteSelectField from "../AutoCompleteSelectField";
//old key: AIzaSyDQpuI06rzKj1Nn8TlfCkvM_fNQkVEt-5M
interface IGeocodeSearchInputProps {
  className?: string;
  searchResults: any[];
  searchAddress?: string;
  setSearchResults: React.Dispatch<React.SetStateAction<any[]>>;
  setSearchAddress?: React.Dispatch<React.SetStateAction<string>>;
  setLocation?: React.Dispatch<React.SetStateAction<ILocation | null>>;
  location?: ILocation | null;
  isBadResult?: boolean;
  map: Map | null;
}

function GeocodeSearchInput({
  className,
  searchAddress,
  searchResults,
  setSearchResults,
  setSearchAddress,
  setLocation,
  location,
  isBadResult = false,
  map,
}: IGeocodeSearchInputProps) {
  const [focused, setFocused] = useState(false);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const [showUseCurrentLocation, setShowUseCurrentLocation] =
    useState<boolean>(true);

  const handleGeocoder = useCallback(
    (searchAddress: string | undefined) => {
      if (searchAddress && map) {
        //@ts-ignore
        //old key: AIzaSyDQpuI06rzKj1Nn8TlfCkvM_fNQkVEt-5M
        const geocoderCustom = new GoogleCustom({
          apiKey: "AIzaSyBvt6egPu_HcfYPDiQnoPFCCSZ-FM1zGfs",
          geocodingQueryParams: { language: "en", region: "us" },
        });

        geocoderCustom.geocode(searchAddress, (results: any) => {
          if (results && results.length > 0) {
            setSearchResults(results);

            const location = results[0].center;
            const defaultZoom = map.getZoom();
            setLocation &&
              setLocation({ lat: location.lat, lng: location.lng });

            map.setView(
              [location.lat, location.lng],
              defaultZoom <= 4 ? 14 : defaultZoom
            );
            setSearchAddress && setSearchAddress(results[0].name);
          } else {
            setSearchResults(results);
            console.error("Geocode was not successful.");
          }
        });
      }
    },
    [setLocation, setSearchAddress, setSearchResults, map]
  );

  const debouncedGeocoder = useMemo(
    () => debounce(handleGeocoder, 300),
    [handleGeocoder]
  );

  useEffect(() => {
    debouncedGeocoder(searchAddress);
  }, [debouncedGeocoder, searchAddress]);

  function handleUseCurrentLocation() {
    setShowDropdown(true);
    setSearchAddress && setSearchAddress("");
    setSearchResults([]);
    if (navigator.geolocation) {
      //@ts-ignore

      const geocoderCustom = new GoogleCustom({
        apiKey: "AIzaSyBvt6egPu_HcfYPDiQnoPFCCSZ-FM1zGfs",
        reverseQueryParams: { language: "en", region: "us" },
      });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (map) {
            geocoderCustom.reverse(
              { lat: latitude, lng: longitude },
              20,
              (results: any) => {
                if (results && results.length > 0) {
                  setSearchResults(results);
                  const location = results[0].center;
                  setSearchAddress && setSearchAddress(results[0].name);
                  setLocation &&
                    setLocation({ lat: location.lat, lng: location.lng });
                  const defaultZoom = map.getZoom();
                  map.setView(
                    [location.lat, location.lng],
                    defaultZoom <= 4 ? 14 : defaultZoom
                  );

                  setShowUseCurrentLocation(false);
                  setFocused(false);
                  setShowDropdown(false);
                } else {
                  setSearchResults([]);
                  console.error("Geocode was not successful.");
                }
              }
            );
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  }

  function handleUseSelectedLocation(place: {
    name: string;

    center: ILocation;
  }) {
    setSearchAddress && setSearchAddress(place.name);

    setLocation &&
      setLocation({
        ...place.center,
        metaData: { zoom: map ? map.getZoom() : 14 },
      });
    setShowUseCurrentLocation(false);
    setFocused(false);
    setShowDropdown(false);
  }

  useEffect(() => {
    if (map && location) {
      map.setView([location.lat, location.lng], location?.metaData?.zoom);
    }
  }, [map, location]);
  const refContainer = useRef<HTMLInputElement | null>(null);
  const refDropdown = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(
    refContainer,
    () => {
      if (searchAddress === "") {
        setShowDropdown(false);
      }

      setFocused(false);
    },
    [refDropdown]
  );

  useEffect(() => {
    if (isBadResult) {
      setShowDropdown(true);
    }
  }, [isBadResult]);

  const handleClear = useCallback(() => {
    setSearchAddress && setSearchAddress("");
    setLocation && setLocation(null);
    setShowDropdown(false);
  }, [setLocation, setSearchAddress]);

  return (
    <div className={twMerge(classnames("flex flex-col w-full", className))}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className="relative w-full flex items-center justify-center"
      >
        {/* {isBadResult && (
          <div className="absolute bottom-[50px] p-1 px-2 bg-white text-[#000] w-[99%] border rounded-md text-xs z-[100] shadow-lg">
            Your search did not match any results. Please specify the address
            starting with the city name, or move the pin on the map.
          </div>
        )} */}
        <AutoCompleteSelectField
          square
          handleAutocompleteGeocoder={handleGeocoder}
          handleCurrentLocation={handleUseCurrentLocation}
          handleClear={handleClear}
          isSearchInput
          showSearchIcon
          filterOption={() => {
            return true;
          }}
          value={{ label: searchAddress || "", value: searchAddress || "" }}
          placeholder="Search by location"
          className=" w-full max-w-[700px]   !flex-row !items-center !pb-0  border-b focus-within:border-black border-transparent"
        />
      </div>
    </div>
  );
}

export default GeocodeSearchInput;
