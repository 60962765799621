import { useField } from "formik";
import classnames from "classnames";
import { ITextareaProps, Textarea } from "../Textarea";
import { twMerge } from "tailwind-merge";

interface IProps extends Omit<ITextareaProps, "classNames"> {
  classNames?: {
    input?: string;
    error?: string;
  };
  className?: string;
  withErrorMessage?: boolean;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  name: string;
}

export default function TextareaField({
  classNames,
  name,
  className,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const errorMessage = form.touched && form.error;
  const showError = !!errorMessage;

  return (
    <div className={twMerge(classnames(className, " flex flex-col relative "))}>
      <Textarea
        className={classnames(
          classNames?.input,
          { "!px-0": rest.readOnly },
          "placeholder-gray-400 text-sm font-light min-h-[80px]"
        )}
        {...field}
        {...rest}
      />
      {showError && (
        <div
          className={classnames(classNames?.error, "  font-light text-sm", {
            "text-red-400": showError,
          })}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}
