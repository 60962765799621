export interface IValues {
  propertyName: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateRegion: string;
  postalCode: string;
}

export enum FIELD_NAMES {
  PROPERTY_NAME = "propertyName",
  COUNTRY = "country",
  ADDRESS_LINE_1 = "addressLine1",
  ADDRESS_LINE_2 = "addressLine2",
  CITY = "city",
  STATE_REGION = "stateRegion",
  POSTAL_CODE = "postalCode",
}
