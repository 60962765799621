import ReactDOM from "react-dom/client";
import "./assets";
import App, { AppWrapper } from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, RouterProvider } from "react-router-dom";

import { Suspense } from "react";
import { Loader } from "components/Elementary/Loader/Loader";
import { ErrorBoundary } from "react-error-boundary";
import routes from "router/router.v6.config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//https://www.codemzy.com/blog/react-query-cachetime-staletime
// helps to manage number of requests
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // 10 minutes
      cacheTime: 1000 * 60 * 15, // 15 hours
    },
  },
});

function Render() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={routes} />
      </Suspense>
    </QueryClientProvider>
  );
}
root.render(<Render />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
