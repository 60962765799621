import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IURLParams } from "types/utility";

export function useGetSetUrlParamsKeysValues() {
  const [searchParams, setUrlParams] = useSearchParams();
  const [params, setParams] = useState<Array<IURLParams>>([]);
  useEffect(() => {
    setParams([]);
    searchParams.forEach((value, key) => {
      setParams((oldArray) => [...oldArray, { key, value }]);
    });
  }, [searchParams]);

  return { params, setUrlParams, searchParams };
}
