import {
  IUpdatetedInvitedUserResponse,
  useInviteCompanyUsers,
} from "api/query-hooks";
import { inviteCompanyUsers } from "api/requests";
import { FormikHelpers } from "formik";
import { useAuth } from "hooks/authContext";
import { useCallback, useMemo } from "react";
import {
  InviteUserFormInitialState,
  InviteUserFormPayload,
} from "./InviteUserForm.constants";
import { useQueryClient } from "@tanstack/react-query";
import { useSegment } from "hooks/utility/useSegment";

export function useInitialState() {
  ///make request and get initial data from server
  const initial = useMemo(
    () =>
      new InviteUserFormInitialState({
        emails: [],
        userManagement: false,
        permission: null,
        orderNotifications: false,
        billing: false,
        notifyPeople: false,
        message: "",
      }), // hardcoded empty array
    []
  );

  return initial;
}

export function useSubmitHandler(onSuccess: (() => void) | undefined) {
  const context = useAuth();
  const { mutateAsync } = useInviteCompanyUsers();
  const queryClient = useQueryClient();
  const { memberInvitedCallback } = useSegment()
  return useCallback(
    async (
      props: InviteUserFormInitialState,
      formikHelpers: FormikHelpers<InviteUserFormInitialState>
    ) => {
      try {
        formikHelpers.setSubmitting(true);
        const payload = new InviteUserFormPayload({
          ...props,
          companyId: context.companyInfo?.id,
          inviter_email: context.user?.email,
          companyName: context.companyInfo?.name,
        }).getPayload();
        mutateAsync(payload).then((res) => {
          if (onSuccess) {
            onSuccess();
          }
  
          formikHelpers.setSubmitting(false);
          queryClient.invalidateQueries(["getCompanyUsers"]);
          memberInvitedCallback({
            ...props,
            companyId: context.companyInfo?.id,
            inviter_email: context.user?.email,
            companyName: context.companyInfo?.name,
          })
        })       
      } catch (error: any) {
        formikHelpers.setErrors(error.response.data);
        formikHelpers.setSubmitting(true);
      }
    },
    [
      context.companyInfo?.id,
      context.companyInfo?.name,
      context.user?.email,
      mutateAsync,
      queryClient,
      onSuccess,
    ]
  );
}
