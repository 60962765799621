import { AxiosRequestConfig, CancelTokenSource } from "axios";
import { axiosDefault, axiosApp } from "./axios";

export function axiosGET(url: string, data?: any, source?: CancelTokenSource) {
  const promise = axiosApp.get<any>(url, data);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function axiosPOST<T = any>(
  url: string,
  data?: any,
  source?: CancelTokenSource,
  standard?: boolean
) {
  if (standard === true) {
    const promise = axiosDefault.post<T>(url, data);
    //@ts-ignore
    promise.cancel = () => {
      source?.cancel("Query was cancelled by React Query");
    };
    return promise;
  }
  const promise = axiosApp.post<T>(url, data);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function axiosPUT(
  url: string,
  data?: any,
  source?: CancelTokenSource,
  config?: AxiosRequestConfig<any>,
  standard?: boolean
) {
  if (standard === true) {
    const promise = axiosDefault.put<any>(url, data, config);
    //@ts-ignore
    promise.cancel = () => {
      source?.cancel("Query was cancelled by React Query");
    };
    return promise;
  }
  const promise = axiosApp.put<any>(url, data, config);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function axiosPATCH(
  url: string,
  data?: any,
  source?: CancelTokenSource,
  standard?: boolean
) {
  if (standard === true) {
    const promise = axiosDefault.patch<any>(url, data);
    //@ts-ignore
    promise.cancel = () => {
      source?.cancel("Query was cancelled by React Query");
    };
    return promise;
  }
  const promise = axiosApp.patch<any>(url, data);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export function axiosDELETE(
  url: string,
  data?: any,
  source?: CancelTokenSource,
  standard?: boolean
) {
  if (standard === true) {
    const promise = axiosDefault.delete<any>(url, data);
    //@ts-ignore
    promise.cancel = () => {
      source?.cancel("Query was cancelled by React Query");
    };
    return promise;
  }
  const promise = axiosApp.delete<any>(url, data);
  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };
  return promise;
}
