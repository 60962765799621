import { useField } from "formik";
import classnames from "classnames";
import {
  IToggleButtonProps,
  ToggleButtonComponent,
} from "../ToggleButtonComponent/ToggleButtonComponent";
import { twMerge } from "tailwind-merge";

interface IProps extends Omit<IToggleButtonProps, "classNames"> {
  classNames?: IToggleButtonProps["classNames"] & {
    error?: string;
    toggleButton?: string;
  };
  className?: string;
  name: string;
}

export default function FormikToggleField({
  name,
  className,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const error = form.touched && form.error;
  const showError = !!error;
  return (
    <div className={twMerge(classnames(className, "relative"))}>
      <ToggleButtonComponent
        className={rest?.classNames?.toggleButton}
        {...field}
        {...rest}
      />
      {showError && (
        <div
          className={twMerge(
            classnames(rest?.classNames?.error, " text-sm", {
              "text-red-400": showError,
            })
          )}
        >
          {error}
        </div>
      )}
    </div>
  );
}
