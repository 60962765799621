import { default as axiosService } from "axios";
import { useAtom } from "jotai/react";
import { APP_ROUTES } from "const/appRoutes";
import { IAuth } from "types/auth";
import { fetchAuthDataByRefreshToken } from "./requests";
import { authAtom } from "store/atoms";
import { logoutUtility } from "utilities/common";

export const axiosApp = axiosService.create();
export const axiosDefault = axiosService.create();

axiosApp.interceptors.request.use(
  async function (config) {
    try {
      const storageAuth = localStorage.getItem("auth");
      if (storageAuth) {
        const authStorage: IAuth = JSON.parse(storageAuth);
        const token = authStorage?.access_token;
        const idToken = authStorage?.id_token;
        // add logic if needed
        //@ts-ignore
        config.headers = {
          ...config.headers,
          Authorization: "Bearer " + token,
          Idtoken: idToken,
        };
      }
    } catch (error) {
      console.log("Error obtaining auth token in interceptor, ", error);
    }

    return config;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
  }
);

axiosApp.interceptors.response.use(
  async (response) => {
    const refreshStorage = localStorage.getItem("refresh");

    const originalRequest = response.config;

    if (response?.status === 401) {
      localStorage.removeItem("auth");
      if (refreshStorage) {
        try {
          const refreshedAuth = await fetchAuthDataByRefreshToken(
            refreshStorage
          );
          localStorage.setItem("auth", JSON.stringify(refreshedAuth.data));
          const token = refreshedAuth?.data.access_token;
          const idToken = refreshedAuth?.data.id_token;
          // add logic if needed
          //@ts-ignore
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: "Bearer " + token,
            Idtoken: idToken,
          };

          const retryResponse = await axiosApp(originalRequest);
          return retryResponse;
        } catch (error) {
          localStorage.removeItem("auth");
          localStorage.removeItem("refresh");
          logoutUtility();
          return Promise.reject(error);
        }
      }
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const code = error?.response?.status || error?.response?.error?.statusCode;
    if (code === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshStorage = localStorage.getItem("refresh");

      if (refreshStorage) {
        localStorage.removeItem("auth");
        try {
          const refreshedAuth = await fetchAuthDataByRefreshToken(
            refreshStorage
          );
          localStorage.setItem("auth", JSON.stringify(refreshedAuth.data));
          const token = refreshedAuth?.data.access_token;
          const idToken = refreshedAuth?.data.id_token;
          // add logic if needed
          //@ts-ignore
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: "Bearer " + token,
            Idtoken: idToken,
          };

          const retryResponse = await axiosApp(originalRequest);
          return retryResponse;
        } catch (error) {
          localStorage.removeItem("auth");
          localStorage.removeItem("refresh");
          logoutUtility();
          return Promise.reject(error);
        }
      }

      //
    }

    return Promise.reject(error);
  }
);

export default axiosApp;
