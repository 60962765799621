import { Form, useFormikContext } from "formik";
import { FIELD_NAMES, IValues } from "./CreatePropertyForm.constants";
import { PrimaryButton } from "components/Elementary/PrimaryButton/PrimaryButton";

import { FormikInputField } from "components/Elementary/FormikInputField";
import { IGeocoderResult } from "types/googleApiTypes";
import geocodeFormatter from "utilities/common";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Link } from "react-router-dom";

export interface ICreatePropertyFormComponentProps {
  className?: string;
  onClose?: () => void;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  geocodingResult?: IGeocoderResult;
}

export function CreatePropertyFormComponent({
  onClose,
  className,
  ...rest
}: ICreatePropertyFormComponentProps) {
  const { setValues, errors, values, isSubmitting, handleSubmit } =
    useFormikContext<IValues>();

  const displayName = useMemo(
    () => rest?.geocodingResult?.name,
    [rest?.geocodingResult?.name]
  );

  return (
    <Form
      id={"create-property-form"}
      className=" w-full max-w-[560px] flex flex-col"
    >
      <div className="px-[16px]   flex flex-col    ">
        <h1 className=" font-[500] h-[64px] pb-4 flex items-center text-2xl">
          Confirm the address
        </h1>
        <h2 className=" font-semibold pb-3  flex items-center text-base">
          Name the property
        </h2>
        <p className=" font-normal  pb-3  flex items-center text-sm text-[#4A534C]">
          Enter your property's name. If unnamed, we'll use service address for
          name.
        </p>
      </div>
      <div className="px-[16px] pt-[16px]  border-gray-300 rounded flex flex-col justify-between">
        <FormikInputField
          label="Property Name"
          placeholder="Your property's name"
          classNames={{
            input:
              "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
          }}
          name={FIELD_NAMES.PROPERTY_NAME}
        />
        <div className="   mb-[16px]  flex flex-col    ">
          <h2 className=" font-semibold  flex items-center text-base ">
            Address
          </h2>
          <p className=" font-normal  pb-3  flex items-center text-sm text-[#4A534C]">
            We use Google Maps for address validation to improve site accuracy.
          </p>
        </div>
        <FormikInputField
          label="Country"
          classNames={{
            input:
              "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
            mainContainer: "hidden",
          }}
          disabled={!!values.country}
          name={FIELD_NAMES.COUNTRY}
          require
        />
        <FormikInputField
          label="Street Address"
          classNames={{
            input:
              "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
            mainContainer: "hidden",
          }}
          disabled={!!values.addressLine1}
          name={FIELD_NAMES.ADDRESS_LINE_1}
          require
        />
        <FormikInputField
          label="Street Address"
          classNames={{
            input:
              "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
          }}
          disabled={!!values.addressLine1}
          name={"placeholder"}
          value={displayName || ""}
          require
        />
        <FormikInputField
          label="Address Line 2"
          classNames={{
            input:
              "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
          }}
          placeholder="Apt. number, suite, etc."
          name={FIELD_NAMES.ADDRESS_LINE_2}
        />
        <div className="flex flex-col xs:flex-row gap-4">
          <FormikInputField
            label="City"
            classNames={{
              input:
                "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
            }}
            disabled={!!values.city}
            name={FIELD_NAMES.CITY}
            require
          />
          <FormikInputField
            label="State / Region"
            classNames={{
              input:
                "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
            }}
            disabled={!!values.stateRegion}
            name={FIELD_NAMES.STATE_REGION}
            require
          />
          <FormikInputField
            label="Postal Code"
            classNames={{
              input:
                "bg-white pl-2 disabled:bg-[#14201708] disabled:text-[#1420174F]",
            }}
            disabled={!!values.postalCode}
            name={FIELD_NAMES.POSTAL_CODE}
            require
          />
        </div>
        <div>
          <span
            className="text-[#2767F6] text-sm font-[500] cursor-pointer hover:underline"
            onClick={() => {
              rest.setShowForm(false);
            }}
          >
            Change location
          </span>
        </div>
      </div>
    </Form>
  );
}
