/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition, TransitionClasses } from "@headlessui/react";

import classnames from "classnames";

interface IProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
  preventClose?: boolean;
  title?: string;
  headerIcon?: JSX.Element;
  description?: string;
  position?: "left" | "right";
  classNames?: {
    dialogPanel?: string;
  };
  customTransitionChildClasses?: TransitionClasses;
}

const rightClasses = {
  enterFrom: "translate-x-full",
  enterTo: "translate-x-0",
  leaveFrom: "translate-x-0",
  leaveTo: "translate-x-full",
};

const leftClasses = {
  enterFrom: "translate-x-[-100%]",
  enterTo: "translate-x-0",
  leaveFrom: "translate-x-0",
  leaveTo: "translate-x-[-100%]",
};

export function SlideOver({
  open,
  close,
  children,
  title,
  preventClose = false,
  headerIcon,
  description,
  classNames,
  position = "right",
  customTransitionChildClasses = {},
}: IProps) {
  const [localPreventClose, setLocalPreventClose] = useState(false);

  let classes = {};

  if ("right" === position) {
    classes = rightClasses;
  }
  if ("left" === position) {
    classes = leftClasses;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[3500]"
        onClose={() => {
          if (!preventClose && !localPreventClose) {
            close();
          }
        }}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={classnames(
                "pointer-events-none fixed inset-y-0  flex max-w-full ",
                {
                  "right-0 pl-10": position === "right",
                  "left-0 pr-10": position === "left",
                }
              )}
            >
              <Transition.Child
                as={Fragment}
                {...classes}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
              >
                <Dialog.Panel className={classnames(classNames?.dialogPanel)}>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
