import React, { cloneElement, useEffect, useRef } from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";
import { Loader } from "components/Elementary/Loader/Loader";

interface ICustomModalProps
  extends Partial<React.HTMLAttributes<HTMLDivElement>> {
  children: React.ReactNode;
  classNames?: {
    container?: string;
  };
  onClose?: (value?: boolean) => void;
  headerShow?: boolean;
  showLoader?: boolean;
  tabIndex?: number;
  closeOnOutsideClick?: boolean;
  open: boolean;
}

export function CustomModal({
  open,
  children,
  className,
  classNames,
  tabIndex = 1,
  onClose,
  headerShow = true,
  showLoader = false,
  closeOnOutsideClick, // make sure that header button callback exist
}: ICustomModalProps) {
  //
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (closeOnOutsideClick) {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose && onClose();
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [closeOnOutsideClick, onClose]);

  useEffect(() => {
    if (open && ref.current) {
      ref.current.focus();
    }
  }, [open]);

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <div
            tabIndex={tabIndex}
            onKeyDown={(e) => {
              if (e.key === "Escape" && onClose) {
                onClose();
                e.stopPropagation();
              }
            }}
            className={twMerge(
              classnames(
                className,
                "z-[3501] top-0 fixed    left-0 w-full bg-[#131313] bg-opacity-30 h-full outline-none  justify-center  items-center overflow-y-auto flex"
              )
            )}
          >
            {showLoader && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className="absolute h-full w-full z-[2002] bg-[#1313131e] pointer-events-none flex items-center justify-center"
              >
                <Loader />
              </div>
            )}
            <div
              tabIndex={-1}
              className={twMerge(
                classnames(
                  "flex flex-col h-full overflow-y-auto justify-center",
                  classNames?.container,
                  {
                    "pointer-events-none": showLoader,
                  }
                )
              )}
              onFocus={(e) => {}}
              onClick={(e) => {}}
              ref={ref}
            >
              {React.isValidElement(children) &&
                cloneElement(children, {
                  //@ts-ignore
                  onClose,
                })}
              {!React.isValidElement(children) && children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
