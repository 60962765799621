import React from "react";
import classnames from "classnames";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { twMerge } from "tailwind-merge";

export interface IProps extends TextareaAutosizeProps {
  className?: string;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
}

function Textarea({ className, customRef, ...rest }: IProps) {
  const classes = twMerge(
    classnames(
      "w-full focus:border-focus-border focus:ring-focus-border focus:placeholder-opacity-0 rounded-[8px] block",
      {
        " select-none resize-none bg-gray-200": rest.disabled,
      },
      className
    )
  );
  return (
    <TextareaAutosize
      ref={customRef}
      {...rest}
      className={classes}
      placeholder="Place for additional info"
    />
  );
}

export default Textarea;
