import { useGetSetUrlParamsKeysValues } from "hooks/utility/useUrlParamsKeysValues";
import { useAtom } from "jotai/react";
import { authAtom } from "store/atoms";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetCompanyData,
  useGetDocument,
  useGetIssuesFromArray,
  useGetProject,
} from "api/query-hooks";
import PDFSequence from "components/Complex/PDFOrderedIssues/PDFOrderedIssues";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { IIssue } from "types/entities";
import { Loader } from "components/Elementary/Loader/Loader";
import { useAuth } from "hooks/authContext";
import PdfHeader from "assets/img/pdf/pdfLogoHeader.png";

function calculateSum(array: IIssue[], property: keyof IIssue) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);

  return total;
}

export default function Pdf() {
  document.title = "Pdf | IssueID";
  const [downloadOnce, setDownloadOnce] = useState(false);
  const [loading, setLoading] = useState(true);
  const context = useAuth();

  const { searchParams, setUrlParams } = useGetSetUrlParamsKeysValues();
  const companyId = searchParams.get("companyId");
  const projectId = searchParams.get("projectId");
  const documentId = searchParams.get("documentId");
  const origin = searchParams.get("origin");
  const orderId = searchParams.get("orderId");

  const { data: documentData } = useGetDocument({
    enabled:
      !!companyId &&
      !!projectId &&
      !!documentId &&
      !!context.auth?.access_token,
    companyId: companyId!,
    documentId: documentId!,
    projectId: projectId!,
  });

  const { data: issuesData } = useGetIssuesFromArray({
    enabled:
      !!companyId &&
      !!projectId &&
      !!documentData?.data.form_data &&
      !!context?.auth?.access_token,
    companyId: companyId!,
    issueArray: documentData?.data.form_data || [],
    projectId: projectId!,
  });

  const { data: projectData } = useGetProject({
    enabled: !!companyId && !!projectId && !!context?.auth?.access_token,
    companyId: companyId || "",
    projectId: projectId || "",
  });

  const { data: accountData, isFetched: isAccountDataFetched } =
    useGetCompanyData({ companyId: companyId || "" });

  const companyLogo = useMemo(
    () =>
      accountData?.data?.logo[1]?.uri ||
      accountData?.data?.logo[1]?.url ||
      PdfHeader,
    [accountData?.data.logo]
  );

  const totalSum = issuesData?.data && calculateSum(issuesData?.data, "price");

  const [pdfDocument, setPdfDocument] = useState<null | JSX.Element>(null);

  useEffect(() => {
    if (
      isAccountDataFetched &&
      issuesData?.data &&
      totalSum !== undefined &&
      projectData?.data &&
      documentData?.data &&
      context.auth
    ) {
      setPdfDocument(
        <PDFSequence
          issues={issuesData?.data || []}
          email={context.auth.user?.email || "Email"}
          phone="phone"
          totalSum={totalSum}
          organizationName={
            documentData.data.company_info.name || "Organization Name"
          }
          organizationLogo={companyLogo}
          propertyName={projectData?.data.Items[0].name}
        />
      );
    }
  }, [
    issuesData,
    isAccountDataFetched,
    companyLogo,
    totalSum,
    documentData,
    projectData,
    context?.auth,
  ]);

  const renderUrl = useCallback(
    async () =>
      new Promise(async (resolve, reject) => {
        if (pdfDocument) {
          const blob = await ReactPDF.pdf(pdfDocument).toBlob();
          setDownloadOnce(true);
          saveAs(
            blob,
            `${documentData?.data.company_info.name.replace(
              " ",
              "_"
            )}_${projectData?.data.Items[0].name.replace(" ", "_")}_${format(
              new Date(),
              "yyyy.MM.dd"
            ).toString()}.pdf`
          );
          const url = URL.createObjectURL(blob);
          if (url && url.length > 0) {
            resolve(url);
          }
        }
      })
        .then((res) => {
          setLoading(false);
          setUrlParams();
          if (origin) {
            if (orderId) {
              window.open(origin + `&orderId=${orderId}`, "_self");
            } else {
              window.open(origin, "_self");
            }
          }

          return res;
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setUrlParams();
          if (origin) {
            if (orderId) {
              window.open(origin + `&orderId=${orderId}`, "_self");
            } else {
              window.open(origin, "_self");
            }
          }
        }),
    [
      pdfDocument,
      origin,
      orderId,
      documentData?.data,
      projectData?.data.Items,
      setUrlParams,
    ]
  );

  useEffect(() => {
    if (
      pdfDocument &&
      !downloadOnce &&
      issuesData?.data &&
      projectData?.data &&
      accountData?.data
    ) {
      renderUrl();
    }
  }, [
    pdfDocument,
    downloadOnce,
    renderUrl,
    issuesData,
    projectData,
    accountData,
  ]);

  return (
    <div className="flex flex-col p-12 h-full items-center justify-center flex-grow">
      {loading && <Loader />}
      {!loading && pdfDocument && (
        <PDFViewer
          showToolbar={false}
          style={{
            width: "100%",
            height: "95%",
          }}
        >
          {documentData?.data && context?.auth && (
            <PDFSequence
              issues={issuesData?.data || []}
              email={context.auth.user?.email || "Email"}
              phone="phone"
              totalSum={totalSum}
              organizationLogo={companyLogo}
              organizationName={
                documentData.data.company_info.name || "Organization Name"
              }
              propertyName={projectData?.data.Items[0].name}
            />
          )}
        </PDFViewer>
      )}

      {!loading && !pdfDocument && <div>Something went wrong!</div>}
    </div>
  );
}
