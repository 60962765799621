import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import React, { useCallback, useRef } from "react";
import { ReactComponent as CloseIcon } from "assets/img/general/close.svg";
import { ReactComponent as SearchIcon } from "assets/img/general/search.svg";

enum SIZE {
  "regular" = "h-11",
  "sm" = "h-10",
  "lg" = "h-12",
}
interface ISearchBox
  extends Partial<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  containerSize: "regular" | "sm" | "lg";

  classNames?: {
    container?: string;
    searchIconStyle?: string;
    closeIconStyle?: string;
    input?: string;
  };
  onClearCallback?: () => void;
}

export function SearchBox({
  containerSize,

  classNames,
  onClearCallback,

  ...rest
}: ISearchBox) {
  const isErr = false;
  const isDisabled = rest["aria-disabled"] ? true : false;

  const defaultSearchBoxStyle =
    "relative flex justify-start space-x-2.5 items-center w-full min-w-[180px] ";
  const searchBoxSize = SIZE[containerSize];
  const defaultInputStyle = `text-textBase placeholder-textSecondary text-sm h-full w-full rounded-default   ${
    isErr ? "pl-3" : " [@media(max-width:500px)]:pl-5 pl-10"
  } bg-surface border gap-2 outline-primaryBase`;
  const disabledInputStyle = `text-textBase placeholder-disabledItem text-sm h-full w-full rounded-default pl-10 bg-disabledContainer border gap-2`;
  const closeIconStyle = `h-8 pl-1 w-6 rounded-md absolute right-[1px] pr-3  cursor-pointer bg-surface ${
    isErr
      ? "fill-errorBase"
      : isDisabled
      ? "fill-disabledItem"
      : "fill-gray-500"
  }`;

  const searchIconStyle = `absolute [@media(max-width:500px)]:left-4 left-5 h-4 w-4 ${
    isErr
      ? "fill-errorBase"
      : isDisabled
      ? "fill-disabledItem"
      : "fill-gray-500"
  }`;
  const errorStyles =
    "border-errorBase text-errorBase placeholder-errorBase outline-errorBase bg-white";

  const inputRef = useRef<HTMLInputElement | null>(null);

  const closeCallBack = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = "";
      onClearCallback && onClearCallback();
    }
  }, [onClearCallback]);

  return (
    <div
      className={twMerge(
        classnames(defaultSearchBoxStyle, searchBoxSize, classNames?.container)
      )}
    >
      {isErr ? (
        ""
      ) : (
        <SearchIcon
          className={twMerge(
            classnames(searchIconStyle, classNames?.searchIconStyle)
          )}
        ></SearchIcon>
      )}
      <input
        ref={inputRef}
        disabled={rest["aria-disabled"] ? true : false}
        {...rest}
        className={twMerge(
          classnames(
            isDisabled ? disabledInputStyle : defaultInputStyle,
            isErr ? errorStyles : "",
            classNames?.input
          )
        )}
      ></input>
      {inputRef.current?.value && (
        <CloseIcon
          className={twMerge(
            classnames(closeIconStyle, classNames?.closeIconStyle)
          )}
          onClick={isDisabled ? () => {} : closeCallBack}
        ></CloseIcon>
      )}
    </div>
  );
}
