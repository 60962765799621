import { useField } from "formik";
import { KeyboardEventHandler, useCallback, useEffect, useState } from "react";
import { MultiValue, SingleValue, Options } from "react-select";
import { ISelectOption } from "types/forms";
import AdvanceSelect, {
  IAdvanceFieldProps,
} from "../AdvanceSelect/AdvanceSelect";
export type CommonSelectType = Omit<IAdvanceFieldProps, "message"> & {
  name: string;
  options?: Options<ISelectOption>;
  message?: string;
};

interface IAdvancedMultiSelectError {
  value: string;
}
function getUniqError(error: string | any) {
  let errorMessage = "";
  if (Array.isArray(error) && error?.length) {
    const uniq: { [key: string]: string } = {};
    const err = error as unknown as ISelectOption[];
    err.forEach((e) => {
      uniq[e.value] = e.value;
    });
    errorMessage = Object.values(uniq).join(" ");
  }
  if (typeof error === "string" && error.length) {
    errorMessage = error[0].toUpperCase() + error.slice(1).toLowerCase();
  }
  return errorMessage;
}
export type SearchableSelectType = CommonSelectType & {
  isSearchable: true;
  makeSearch: (value: string) => void;
  isLoading?: boolean;
};
const createOption = (label: string) => ({
  label,
  value: label,
});
type PropsType<T> = T extends { isSearchable: true }
  ? SearchableSelectType
  : CommonSelectType;
export function FormikAdvanceSelect<T>({ ...rest }: PropsType<T>) {
  const [field, meta, { setValue, setTouched }] = useField<
    MultiValue<ISelectOption> | SingleValue<ISelectOption>
  >(rest.name);
  const [input, setInput] = useState("");
  const error: IAdvancedMultiSelectError[] | string = meta.error || "";
  const setValueOnKeyDown = (event: React.KeyboardEvent<Element>) => {
    if (rest.isMulti && rest.isCreatable) {
      const val = field.value as MultiValue<ISelectOption<string, string>>;

      //@ts-ignore
      const newVal = createOption(event.target.value);
      const newValueAlreadyExists = !!val.find(
        (value) => value.value === newVal.value
      );
      //@ts-ignore
      if (newValueAlreadyExists || event.target.value.length < 1) {
        setInput("");
        return;
      }

      setValue([...val, newVal]);

      setInput("");
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const setValueOnBlur = useCallback(
    (inputValue: string) => {
      if (rest.isMulti && rest.isCreatable) {
        const val = field.value as MultiValue<ISelectOption<string, string>>;

        //@ts-ignore
        const newVal = createOption(inputValue);
        const newValueAlreadyExists = !!val.find(
          (value) => value.value === newVal.value
        );
        //@ts-ignore
        if (newValueAlreadyExists || inputValue.length < 1) {
          setInput("");
          return;
        }

        setValue([...val, newVal]);

        setInput("");
      }
    },
    [field.value, rest.isMulti, rest.isCreatable, setValue]
  );

  const onKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case "Enter":
        setValueOnKeyDown(event);
        break;
      case "Tab":
        setValueOnKeyDown(event);
        break;
      case ",":
        setValueOnKeyDown(event);
        break;
    }
  };
  let errorMessage = meta.touched ? getUniqError(error) : "";

  return (
    <AdvanceSelect
      inputValue={input}
      onInputChange={(event) => {
        setInput(event);
      }}
      onChange={useCallback(
        (value: MultiValue<ISelectOption> | SingleValue<ISelectOption>) => {
          setTouched(true);
          setValue(value);
        },
        [setValue, setTouched]
      )}
      message={errorMessage || `Paste emails separated by ",".`}
      value={field.value}
      onBlur={useCallback(() => {
        setTouched(true);
        setValueOnBlur(input);
      }, [setTouched, input, setValueOnBlur])}
      onKeyDown={onKeyDown}
      {...rest}
      isError={!!error}
    />
  );
}
