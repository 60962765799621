export enum USER_STATUSES {
  Active = "Active",
  Deactivated = "Deactivated",
  Pending = "Pending Invite",
}

export enum USER_ROLE_DESCRIPTIONS {
  CAN_EDIT = "Can Edit",
  CAN_VIEW = "Can View",
  CAN_ORDER = "Can Order",
}

export const statusNameMap = {
  100: "Unpublished",
  200: "Awaiting Approval",
  300: "Out Of Scope",
  4: "In Cart",
  400: "Not Now",
  500: "Ordered",
  600: "Scheduled",
  700: "In Progress",
  800: "Awaiting Payment",
  900: "Resolved",
};

export const priorityNameMap = {
  0: "Low",
  1: "Medium",
  2: "High",
};
