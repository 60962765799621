import React from "react";
import classnames from "classnames";
import { twMerge } from "tailwind-merge";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  customRef?: React.MutableRefObject<HTMLInputElement | null>;
}

function Input({ className, customRef, ...rest }: IProps) {
  const classes = twMerge(classnames("focus:placeholder-opacity-0", className));

  return (
    <input autoComplete="off" ref={customRef} {...rest} className={classes} />
  );
}

export default Input;
