import { useField } from "formik";
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import { Dropdown, IDropdownProps } from "components/Complex/Dropdown/Dropdown";
import { IDropdownOptions } from "types/utility";

interface IProps extends Omit<IDropdownProps, "classNames"> {
  name: string;
  className?: string;
  classNames?: IDropdownProps["classNames"] & {
    dropdown?: string;
    error?: string;
  };
}

export default function FormikDropdownField({
  name,
  className,
  ...rest
}: IProps) {
  const [field, form, helpers] = useField(name);
  const error = form.touched && form.error;
  const showError = !!error;
  return (
    <div className={twMerge(classnames(className, "relative"))}>
      <Dropdown
        className={rest.classNames?.dropdown}
        {...field}
        {...rest}
        selectedOption={field.value}
        onChange={(selected: IDropdownOptions | undefined) => {
          helpers.setValue(selected);
        }}
      />
      {showError && (
        <div
          className={twMerge(
            classnames(rest?.classNames?.error, " text-sm", {
              "text-red-400": showError,
            })
          )}
        >
          {error}
        </div>
      )}
    </div>
  );
}
