import { AsyncLocalStorage } from "async_hooks";
import { atomWithStorage, createJSONStorage, loadable } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { IAuth, ICompanyInfo } from "types/auth";

export const authAtom = atomWithStorage<IAuth | undefined>("auth", undefined);
//JSON.parse(localStorage.getItem("auth") || "")
export const getAsyncAuthAtom = atom(async (get) => get(authAtom));

export const loadableAuthAtom =
  loadable<Promise<IAuth | undefined>>(getAsyncAuthAtom);

export const refreshAtom = atomWithStorage("refresh", "");

export const currentCompanyAtom = atomWithStorage<ICompanyInfo | undefined>(
  "currentCompany",
  undefined
);

export const guestAtom = atomWithStorage<undefined | boolean>(
  "guest",
  undefined
);
