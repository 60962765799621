import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { ILocation } from "types/utility";
import { Map } from "leaflet";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import GeocodeSearchInput from "components/Complex/GeocodeSearchInput/GeocodeSearchInput";
import LeafletMap from "components/Complex/LeafletMap/LeafletMap";
import { ReactComponent as CloseCircle } from "assets/img/burger/close.svg";
import { useGetSetUrlParamsKeysValues } from "hooks/utility/useUrlParamsKeysValues";
import { PrimaryButton } from "components/Elementary/PrimaryButton/PrimaryButton";
import CreatePropertyForm from "components/Complex/CreatePropertyForm/CreatePropertyForm.container";
import { Loader } from "components/Elementary/Loader/Loader";
import { vanillaJsOriginURL } from "const/urls";
import geocodeFormatter from "utilities/common";
import { useGetProject } from "api/query-hooks";
import { useAuth } from "hooks/authContext";
import { GoogleCustom } from "utilities/customGoogleGeocoding";
import { debounce } from "lodash";

export default function CreateProperty() {
  document.title = "Create Property | IssueID";
  const navigate = useNavigate();
  const context = useAuth();
  const { searchParams, setUrlParams } = useGetSetUrlParamsKeysValues();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [searchAddress, setSearchAddress] = useState<string>("");
  const [location, setLocation] = useState<ILocation | null>(null);
  const mapRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [map, setMap] = useState<Map | null>(null);
  const originUrl = searchParams.get("origin");

  const companyId = searchParams.get("companyId");
  const projectId = searchParams.get("projectId");

  const { data: projectData, isFetching } = useGetProject({
    enabled: !!companyId && !!projectId && !!context?.auth?.access_token,
    companyId: companyId || "",
    projectId: projectId || "",
  });

  const mode = useMemo(
    () => (projectData?.data?.Items.length > 0 ? "edit" : "create"),
    [projectData?.data?.Items.length]
  );

  useEffect(() => {
    if (mode === "edit") {
      setShowForm(true);
    }
  }, [mode]);

  const handlePropertyLocation = useMemo(
    () =>
      function getPropertyLocation() {
        const geocoderCustom = new GoogleCustom({
          apiKey: "AIzaSyBvt6egPu_HcfYPDiQnoPFCCSZ-FM1zGfs",
          reverseQueryParams: { language: "en", region: "us" },
        });

        if (
          map &&
          projectData?.data?.Items[0]?.coordinates?.lat &&
          projectData?.data?.Items[0]?.coordinates?.lon
        ) {
          const latitude = projectData?.data?.Items[0]?.coordinates?.lat;
          const longitude = projectData?.data?.Items[0]?.coordinates?.lon;

          geocoderCustom.reverse(
            { lat: latitude, lng: longitude },
            20,
            (results: any) => {
              if (results && results.length > 0) {
                const location = results[0].center;
                setSearchAddress && setSearchAddress(results[0].name);

                setLocation &&
                  setLocation({ lat: location.lat, lng: location.lng });
                const defaultZoom = map.getZoom();
                map.setView(
                  [location.lat, location.lng],
                  defaultZoom <= 4 ? 14 : defaultZoom
                );
              } else {
                console.error("Geocode was not successful.");
              }
            }
          );
        }
      },
    [map, projectData?.data?.Items]
  );

  const debounceLoadData = useMemo(
    () => debounce(handlePropertyLocation, 500),
    [handlePropertyLocation]
  );

  useEffect(() => {
    if (projectData?.data?.Items[0]) {
      debounceLoadData();
    }
    return () => {
      debounceLoadData.cancel();
    };
  }, [debounceLoadData, projectData?.data?.Items]);

  const normalizedSearchResult =
    searchResult.length > 0
      ? geocodeFormatter(searchResult[0]?.properties)
      : undefined;

  const disableNextButton =
    !searchAddress ||
    searchResult.length <= 0 ||
    normalizedSearchResult?.streetNumber === "" ||
    normalizedSearchResult?.street === "" ||
    normalizedSearchResult?.street === "" ||
    normalizedSearchResult?.country === "" ||
    normalizedSearchResult?.city === "" ||
    normalizedSearchResult?.state === "" ||
    normalizedSearchResult?.zip === "";

  const missingData = useMemo(() => {
    return {
      streetNumber: !normalizedSearchResult?.streetNumber
        ? "Street number"
        : undefined,
      street: !normalizedSearchResult?.street ? "Street" : undefined,
      country: !normalizedSearchResult?.country ? "Country" : undefined,
      city: !normalizedSearchResult?.city ? "City" : undefined,
      state: !normalizedSearchResult?.state ? "State" : undefined,
      zip: !normalizedSearchResult?.zip ? "Zip" : undefined,
    };
  }, [normalizedSearchResult]);

  return (
    <div className="relative flex flex-col w-full h-full flex-1 gap-6 items-center justify-between ">
      {(isSubmitting || isFetching) && (
        <div className="absolute w-full  h-full pointer-events-none z-[100] bg-black bg-opacity-20" />
      )}
      {(isSubmitting || isFetching) && <Loader position="absolute" />}
      <div
        className={twMerge(
          classnames("flex flex-col w-full  items-center overflow-auto", {
            "h-full": !showForm,
          })
        )}
      >
        <div className="  flex flex-row items-start w-full py-2 px-8">
          <button
            type="button"
            className=" h-[48px] w-[48px] flex items-center justify-center"
            onClick={() => {
              if (originUrl) {
                window.location.replace(originUrl);
              } else {
                window.location.replace(vanillaJsOriginURL);
              }
            }}
          >
            <span className="sr-only">Close panel</span>
            <CloseCircle className="h-4 w-4" fill="#aaa" aria-hidden="true" />
          </button>
        </div>
        <div
          className={twMerge(
            classnames(
              "h-full w-full   max-w-[614px] relative flex flex-col gap-6 px-6",
              {
                hidden: showForm,
                "opacity-0": isSubmitting || isFetching,
              }
            )
          )}
        >
          <p className=" text-[#202B23] text-2xl font-[500]">
            {!searchAddress && `Enter property address`}
            {searchAddress && `Confirm pin location`}
          </p>
          <div
            className={twMerge(
              classnames("  h-full w-full relative flex flex-col items-center")
            )}
          >
            {disableNextButton && searchAddress && (
              <div className="absolute z-[12] top-[96px] py-[2px] px-1.5 bg-[#4A534C] flex flex-col rounded-[3px]">
                <span className="text-white text-sm">
                  Some data is missing:
                </span>
                {Object.entries(missingData) &&
                  Object.entries(missingData).map(([key, value]) => {
                    return (
                      value && (
                        <span key={key} className="text-white text-sm">
                          {value}
                        </span>
                      )
                    );
                  })}
              </div>
            )}
            <GeocodeSearchInput
              isBadResult={!!searchAddress && disableNextButton}
              setSearchResults={setSearchResult}
              searchResults={searchResult}
              className=" absolute z-10 top-[24px] px-2 xs:px-[32px] "
              searchAddress={searchAddress}
              setSearchAddress={setSearchAddress}
              location={location}
              setLocation={setLocation}
              map={map}
            />
            <LeafletMap
              showLayersControl
              location={location}
              setLocation={setLocation}
              setSearchAddress={setSearchAddress}
              setSearchResult={setSearchResult}
              mapInstance={map}
              onLoad={(props: { target: Map }) => {
                setMap(props.target);
              }}
            />
          </div>
        </div>
        {showForm && searchResult[0] && (
          <CreatePropertyForm
            setShowForm={setShowForm}
            setIsSubmitting={setIsSubmitting}
            mode={mode}
            propertyData={projectData?.data?.Items[0]}
            geocodingResult={searchResult[0]}
          />
        )}
      </div>

      <div className="min-h-[72px] max-h-[72px] flex flex-col items-center  w-full  border-t border-[#14201724]">
        <div className="h-full flex flex-row items-center max-w-[560px] w-full justify-between px-6">
          <button
            type="button"
            className=" "
            onClick={() => {
              if (!showForm) {
                if (originUrl) {
                  window.open(originUrl);
                }
              } else {
                setShowForm(false);
              }
            }}
          >
            Back
          </button>
          {!showForm && (
            <PrimaryButton
              disabled={disableNextButton}
              styles="solid"
              contentType="default"
              classNames={{ text: " " }}
              size="lg"
              text="Next"
              onClick={() => {
                setShowForm(true);
              }}
            />
          )}
          {showForm && (
            <PrimaryButton
              disabled={isSubmitting}
              form="create-property-form"
              styles="solid"
              contentType="default"
              classNames={{ text: " " }}
              type="submit"
              size="lg"
              text={mode === "create" ? "Create" : "Update"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
