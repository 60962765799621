import { useField } from "formik";
import classnames from "classnames";
import { IInputProps, Input } from "../Input";
import { twMerge } from "tailwind-merge";
import React from "react";

interface IProps extends Omit<IInputProps, "customRef"> {
  classNames?: {
    mainContainer?: string;
    input?: string;
    inputContainer?: string;
    error?: string;
    label?: string;
    description?: string;
  };
  overrideStyles?: {
    mainContainer?: boolean;
    input?: boolean;
    inputContainer?: boolean;
    error?: boolean;
    label?: boolean;
    description?: boolean;
  };
  characterBeforeValue?: string;
  className?: string;
  withErrorMessage?: boolean;
  customRef?: React.MutableRefObject<
    HTMLInputElement | null | HTMLTextAreaElement
  >;
  name: string;
  label?: string;
  require?: boolean;
  description?: string | React.ReactNode;
}

export default function FormikInputField({
  classNames,
  name,
  characterBeforeValue,
  overrideStyles,
  className,
  label,
  withErrorMessage = true,
  require,
  description,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const message = form.touched && form.error;
  const showError = withErrorMessage && !!message;

  return (
    <div
      className={twMerge(
        classnames(
          {
            "flex flex-col gap-1 relative": !overrideStyles?.mainContainer,
            "pb-4": !showError && !overrideStyles?.mainContainer,
          },
          classNames?.mainContainer
        )
      )}
    >
      {label && (
        <p
          className={twMerge(
            classnames(
              {
                "text-[#4A534C] font-semibold text-xs flex flex-row":
                  !overrideStyles?.label,
              },
              classNames?.label
            )
          )}
        >
          {label}
          <span className="text-errorBase">{require && `*`}</span>
        </p>
      )}
      <div
        className={twMerge(
          classnames(
            {
              "flex items-center h-full rounded-md border-gray-200 border overflow-hidden":
                !overrideStyles?.inputContainer,
            },
            classNames?.inputContainer
          )
        )}
      >
        {characterBeforeValue && (
          <span className="absolute  leading-none left-[3px] text-md text-gray-400">
            {characterBeforeValue}
          </span>
        )}
        <Input
          className={twMerge(
            classnames(
              {
                "p-3 pl-5 w-full h-full text-sm bg-[#F5FCFF]":
                  !overrideStyles?.input,
              },
              classNames?.input
            )
          )}
          {...field}
          {...rest}
          customRef={
            rest.customRef as React.MutableRefObject<HTMLInputElement | null>
          }
        />
      </div>
      {showError && (
        <div
          className={twMerge(
            classnames(
              { "text-xs text-errorBase": !overrideStyles?.error },
              classNames?.error
            )
          )}
        >
          {message}
        </div>
      )}
      {!showError && description && (
        <div
          className={twMerge(
            classnames(
              { "text-xs text-[#646C66]": !overrideStyles?.description },
              classNames?.description
            )
          )}
        >
          {description}
        </div>
      )}
    </div>
  );
}
