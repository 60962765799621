import {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Marker as LeafletMarker } from "leaflet";
import { Marker, MarkerProps, Popup } from "react-leaflet";
import { Map } from "leaflet";
import { GoogleCustom } from "utilities/customGoogleGeocoding";
import { ILocation } from "types/utility";

interface IDraggableMarkerProps extends MarkerProps {
  mapInstance?: Map | null;
  setSearchAddress?: React.Dispatch<React.SetStateAction<string>>;
  setSearchResult?: React.Dispatch<React.SetStateAction<any[]>>;
  setLocation?: React.Dispatch<React.SetStateAction<ILocation | null>>;
}

function DraggableMarker({ ...rest }: IDraggableMarkerProps) {
  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState(rest.position);

  useEffect(() => {
    if (rest.position) {
      setPosition(rest.position);
    }
  }, [rest.position]);

  const markerRef = useRef<LeafletMarker>(null);
  const geocoderCustom = useMemo(
    () =>
      new GoogleCustom({
        apiKey: "AIzaSyDQpuI06rzKj1Nn8TlfCkvM_fNQkVEt-5M",
        reverseQueryParams: { language: "en", region: "us" },
      }),
    []
  );
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          if (rest.mapInstance) {
            geocoderCustom.reverse(
              { lat: marker.getLatLng().lat, lng: marker.getLatLng().lng },
              20,
              (results: any) => {
                if (results && results.length > 0) {
                  const location = results[0].center;
                  rest.setSearchResult && rest.setSearchResult(results);
                  rest.setSearchAddress &&
                    rest.setSearchAddress(results[0].name);
                  rest.setLocation &&
                    rest.setLocation({
                      lat: location.lat,
                      lng: location.lng,
                      metaData: { zoom: rest.mapInstance?.getZoom() },
                    });

                  rest.mapInstance?.setView([location.lat, location.lng]);
                } else {
                  console.error("Geocode was not successful.");
                }
              }
            );
          }
          setPosition(marker.getLatLng());
        }
      },
    }),
    [geocoderCustom, rest]
  );
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position || [0, 0]}
      icon={rest.icon}
      ref={markerRef}
    ></Marker>
  );
}

export default DraggableMarker;
