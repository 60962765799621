import { Formik, FormikProps, FormikValues } from "formik";
import {
  ICreatePropertyFormComponentProps,
  CreatePropertyFormComponent,
} from "./CreatePropertyForm.component";

import { useInitialState, useSubmitHandler } from "./CreatePropertyForm.hooks";
import formValidationSchema from "./CreatePropertyForm.schema";
import { IValues } from "./CreatePropertyForm.constants";
import { IGeocoderResult } from "types/googleApiTypes";
import { Dispatch, Ref, SetStateAction } from "react";

interface IProps extends Partial<IGeocoderResult> {
  geocodingResult: IGeocoderResult;
  mode: "create" | "edit";
  propertyData?: any;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export default function CreatePropertyForm(props: IProps) {
  const initialState = useInitialState(
    props.propertyData,
    props.geocodingResult
  );

  return (
    <Formik<IValues>
      enableReinitialize
      initialValues={initialState}
      validationSchema={formValidationSchema()}
      onSubmit={useSubmitHandler(
        props.mode,
        props.geocodingResult,
        props.setIsSubmitting,
        props.propertyData
      )}
    >
      <CreatePropertyFormComponent {...props} />
    </Formik>
  );
}
