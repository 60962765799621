import { Outlet, useLocation } from "react-router-dom";

import classNames from "classnames";
import { Suspense, useState } from "react";

import { useAuth } from "hooks/authContext";
import Header from "components/Complex/Header/Header";
import { Loader } from "components/Elementary/Loader/Loader";
import Footer from "components/Complex/Footer/Footer";
import ActionBar from "components/Complex/ActionBar/ActionBar";
import { SlideOver } from "components/Complex/SlideOver/SlideOver";
import BurgerMenu from "components/Complex/BurgerMenu/BurgerMenu";
import { InviteUserModal } from "components/Complex/Modals/InviteUserModal/InviteUserModal";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import { EmptyUserSearch } from "components/Elementary/EmptyUserSearch/EmptyUserSearch";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IMainLayoutProps {
  displayHeader?: boolean;
  displayFooter?: boolean;
}

export function MainLayout({
  displayHeader = true,
  displayFooter = true,
}: IMainLayoutProps) {
  const location = useLocation();
  const context = useAuth();

  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [showChangeCompanyPopup, setShowChangeCompanyPopup] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  const openInviteUserModal = () => {
    setOpen(true);
  };

  return (
    <div className="flex flex-col h-full items-start">
      {!context?.isLoading && (
        <div
          className={classNames(
            "flex flex-col w-full transition-[margin] duration-300 flex-1 relative  overflow-hidden "
          )}
        >
          {displayHeader && (
            <Header
              context={context}
              setOpenBurgerMenu={setOpenBurgerMenu}
              className="w-full"
            />
          )}
          <InviteUserModal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          />
          <Suspense
            fallback={
              <Loader classNames={{ spinner: " w-[100px] h-[100px]" }} />
            }
          >
            <Outlet context={context} />
            {isEmptySearchResult && (
              <EmptyUserSearch
                onInviteButtonClick={openInviteUserModal}
                context={context}
              />
            )}

            {displayFooter && <Footer className="w-full" />}
          </Suspense>
        </div>
      )}
      {context?.isLoading && (
        <div className="flex flex-col flex-1 items-center justify-center w-full ">
          <Loader classNames={{ spinner: " w-[50px] h-[50px]" }} />
        </div>
      )}
      <SlideOver
        open={openBurgerMenu}
        close={() => {
          setShowChangeCompanyPopup(false);
          setOpenBurgerMenu(false);
        }}
        classNames={{
          dialogPanel:
            "pointer-events-auto w-screen max-w-[329px] border border-[#aaa] relative",
        }}
        position="right"
      >
        <BurgerMenu
          context={context}
          setShowChangeCompanyPopup={setShowChangeCompanyPopup}
          showChangeCompanyPopup={showChangeCompanyPopup}
          close={() => {
            setShowChangeCompanyPopup(false);
            setOpenBurgerMenu(false);
          }}
        />
      </SlideOver>
      <ToastContainer />
    </div>
  );
}
export default MainLayout;
