export const DEV_ENDPOINTS_GIT = [
  {
    name: "initccamintegration",
    stage: "dev",
    method: "POST",
    endpoint: "https://8ky1stw2p6.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createcategory",
    stage: "dev",
    method: "POST",
    endpoint: "https://0vtzlytqdk.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deletecategory",
    stage: "dev",
    method: "DELETE",
    endpoint: "https://81a9xt2m12.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojectsall",
    stage: "dev",
    method: "POST",
    endpoint: "https://7j3dx8bvl1.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-deletedocument",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://k91xoofiaa.execute-api.us-east-1.amazonaws.com/doc-deletedocument-git",
  },
  {
    name: "doc-getdocumentlist",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://lji7bhp37g.execute-api.us-east-1.amazonaws.com/doc-getdocumentlist-git",
  },
  {
    name: "createissuesfromtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://skuzfphhkc.execute-api.us-east-1.amazonaws.com/createissuesfromtemplate-git",
  },
  {
    name: "updateproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://fwg3x1rex5.execute-api.us-east-1.amazonaws.com/updateproject-git",
  },
  {
    name: "receiveappcomplaints",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://xirwr2073g.execute-api.us-east-1.amazonaws.com/receiveappcomplaints-git",
  },
  {
    name: "updatetemplate",
    stage: "staging",
    method: "POST",
    endpoint: "https://zpy8afd566.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deletetemplate",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://2rr1qd00g9.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteuseraccount",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://l5z8ons9w3.execute-api.us-east-1.amazonaws.com/deleteuseraccount-git",
  },
  {
    name: "createtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://dp5wvg158k.execute-api.us-east-1.amazonaws.com/createtemplate-git",
  },
  {
    name: "gettemplates",
    stage: "staging",
    method: "POST",
    endpoint: "https://6o0x08os61.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createtemplatefromglobal",
    stage: "staging",
    method: "POST",
    endpoint: "https://iidi3du97c.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-updateildoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://biyo6i9azf.execute-api.us-east-1.amazonaws.com/doc-updateildoc",
  },
  {
    name: "createdocurl",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://qsooumffs1.execute-api.us-east-1.amazonaws.com/createdocurl-git",
  },
  {
    name: "companycamintegration",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://lhbw2nr79l.execute-api.us-east-1.amazonaws.com/companycamintegration-git",
  },
  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://bbvtnr6k0a.execute-api.us-east-1.amazonaws.com/getissuesfromarray-git",
  },
  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://nvxuau7noh.execute-api.us-east-1.amazonaws.com/createproject-git",
  },
  {
    name: "getapikeys",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://s2ww2y3de6.execute-api.us-east-1.amazonaws.com/getapikeys-git",
  },
  {
    name: "createexternalapirecord",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://dnuwzmkz6d.execute-api.us-east-1.amazonaws.com/createexternalapirecord-git",
  },
  {
    name: "createapikey",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://8s91k4li37.execute-api.us-east-1.amazonaws.com/createapikey-git",
  },
  {
    name: "uploadaccountlogo",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://ycl8mbq5pl.execute-api.us-east-1.amazonaws.com/uploadaccountlogo-git",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://56hpv3pp1b.execute-api.us-east-1.amazonaws.com/getacompanyusers-git",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://cn4dc6iyp3.execute-api.us-east-1.amazonaws.com/doc-getdocdata-git",
  },
  {
    name: "getusercompanydata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://mdguo95pzb.execute-api.us-east-1.amazonaws.com/getusercompanydata-git",
  },
  {
    name: "updateaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://mqtcrdwh60.execute-api.us-east-1.amazonaws.com/updateaccountdata-git",
  },
  {
    name: "uploadissueimagepresignedurl",
    stage: "dev",
    method: "POST",
    endpoint:
      "https://3zb1po5n04.execute-api.us-east-1.amazonaws.com/uploadissueimagepresignedurl-git",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint:
      "https://n2224cs5th.execute-api.us-east-1.amazonaws.com/updatecompanyrole-git",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint:
      "https://t3boprig26.execute-api.us-east-1.amazonaws.com/invitecompanyusersarray-git",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://7msbyba86g.execute-api.us-east-1.amazonaws.com/deletecompanyroles-git",
  },
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://593ixvxczb.execute-api.us-east-1.amazonaws.com/AddChildIssuesToParent-git",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://9lusf5x223.execute-api.us-east-1.amazonaws.com/addorderlineitems-git",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://0yqwzhp8y2.execute-api.us-east-1.amazonaws.com/ccamwebhookphoto-git",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://doc4bb97ni.execute-api.us-east-1.amazonaws.com/cloneissues-git",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://f427m1kg87.execute-api.us-east-1.amazonaws.com/createissue-git",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://hdfak0srm9.execute-api.us-east-1.amazonaws.com/createorder-git",
  },
  {
    name: "doc-createdoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://rytg30jsv0.execute-api.us-east-1.amazonaws.com/doc-createdoc-git",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://a45wu86vs2.execute-api.us-east-1.amazonaws.com/DeleteIssue-git",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://9lgn16qyua.execute-api.us-east-1.amazonaws.com/deleteorder-git",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://0r17iqvw21.execute-api.us-east-1.amazonaws.com/deleteorderlineitem-git",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://exah1b7jve.execute-api.us-east-1.amazonaws.com/deleteproject-git",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://d93kv5f1vc.execute-api.us-east-1.amazonaws.com/getaccountdata-git",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ikmw18b8vb.execute-api.us-east-1.amazonaws.com/getcategories-git",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://g04ha1nmxk.execute-api.us-east-1.amazonaws.com/getcompanyorders-git",
  },
  {
    name: "getprojetusers",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://l0fsb5wfqb.execute-api.us-east-1.amazonaws.com/getprojetusers-git ",
  },
  {
    name: "getorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://i507yl4k6e.execute-api.us-east-1.amazonaws.com/getorder-git",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://irc6w5n1d7.execute-api.us-east-1.amazonaws.com/getprojectorder-git",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://2tnftz5frh.execute-api.us-east-1.amazonaws.com/getprojects-git",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://h54zl21y4g.execute-api.us-east-1.amazonaws.com/getprojectsfromuserdata-git",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://b81e5xp191.execute-api.us-east-1.amazonaws.com/getservertimestamp-git",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://je36s1u6yh.execute-api.us-east-1.amazonaws.com/inviteprojectuser-git",
  },
  {
    name: "login-post-authentication",
    stage: "staging",
    method: "",
    endpoint: "no-endpoint",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://j1dbcsnlbk.execute-api.us-east-1.amazonaws.com/loginuser-git",
  },
  {
    name: "loginuserlocalhost",
    stage: "staging",
    method: "POST",
    endpoint: "https://4b2jh3lmuc.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://j1drc7c6d0.execute-api.us-east-1.amazonaws.com/queryissues-git",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://jo21k0rgbc.execute-api.us-east-1.amazonaws.com/refreshaccesstoken-git",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://iydkdq7p22.execute-api.us-east-1.amazonaws.com/RemoveChildIssueFromParent-git",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://juolok0jo1.execute-api.us-east-1.amazonaws.com/updateorder-git",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://lcc3ivna35.execute-api.us-east-1.amazonaws.com/UpdatePhotos1-git",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint: "https://worxqucx3l.execute-api.us-east-1.amazonaws.com/prod/",
  },
  {
    name: "issueidreportbug",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://amjuljgiz9.execute-api.us-east-1.amazonaws.com/issueidreportbug-git",
  },
  {
    name: "deleteprojectuser",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://91octg52ei.execute-api.us-east-1.amazonaws.com/deleteprojectuser-git",
  },
];

export const STAGING_ENDPOINTS_GIT = [
  {
    name: "initccamintegration",
    stage: "dev",
    method: "POST",
    endpoint: "https://z04tp3z3k8.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createcategory",
    stage: "dev",
    method: "POST",
    endpoint:
      "https://j61evvprdi.execute-api.us-east-1.amazonaws.com/createcategory-git",
  },
  {
    name: "deletecategory",
    stage: "dev",
    method: "DELETE",
    endpoint:
      "https://zc17sgwnqi.execute-api.us-east-1.amazonaws.com/deletecategory-git",
  },
  {
    name: "getprojectsall",
    stage: "staging",
    method: "POST",
    endpoint: "https://8tpjlxpcuf.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-getdocumentlist",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://u4epi1wskk.execute-api.us-east-1.amazonaws.com/doc-getdocumentlist-git",
  },
  {
    name: "doc-deletedocument",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://pnkm5q30pi.execute-api.us-east-1.amazonaws.com/doc-deletedocument-git",
  },
  {
    name: "receiveappcomplaints",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://yhh5dhyy9f.execute-api.us-east-1.amazonaws.com/receiveappcomplaints-git",
  },
  {
    name: "updateproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://zw75xvlai6.execute-api.us-east-1.amazonaws.com/updateproject-git",
  },
  {
    name: "createtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://cisfq6noua.execute-api.us-east-1.amazonaws.com/createtemplate-git",
  },
  {
    name: "createissuesfromtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://pdpqq9f7wa.execute-api.us-east-1.amazonaws.com/createissuesfromtemplate-git",
  },
  {
    name: "createtemplatefromglobal",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://wbpslqhi36.execute-api.us-east-1.amazonaws.com/createtemplatefromglobal-git",
  },
  {
    name: "gettemplates",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://6jzfg1ot3c.execute-api.us-east-1.amazonaws.com/gettemplates-git",
  },
  {
    name: "updatetemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://s128qmvfq0.execute-api.us-east-1.amazonaws.com/updatetemplate-git",
  },
  {
    name: "deletetemplate",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://pvojmp8ex8.execute-api.us-east-1.amazonaws.com/deletetemplate-git",
  },
  {
    name: "deleteuseraccount",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://7yeigny4f3.execute-api.us-east-1.amazonaws.com/deleteuseraccount-git",
  },
  {
    name: "doc-updateildoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://e8eqh7r3yj.execute-api.us-east-1.amazonaws.com/doc-updateildoc",
  },
  {
    name: "companycamintegration",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://m6t0cv54sj.execute-api.us-east-1.amazonaws.com/companycamintegration-git",
  },
  {
    name: "createdocurl",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ivpgxdaub8.execute-api.us-east-1.amazonaws.com/createdocurl-git",
  },
  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://10g6xi7g89.execute-api.us-east-1.amazonaws.com/createproject-git",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://8o1qslf6zi.execute-api.us-east-1.amazonaws.com/doc-getdocdata-git",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint:
      "https://q63e5g20r5.execute-api.us-east-1.amazonaws.com/invitecompanyusersarray-git",
  },
  {
    name: "uploadaccountlogo",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://bnq4qfw3p0.execute-api.us-east-1.amazonaws.com/uploadaccountlogo-git",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint:
      "https://doxuunddle.execute-api.us-east-1.amazonaws.com/updatecompanyrole-git",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://oos2fgjvek.execute-api.us-east-1.amazonaws.com/deletecompanyroles-git",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://bsvtfqjkle.execute-api.us-east-1.amazonaws.com/getacompanyusers-git",
  },
  {
    name: "updateaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://y3gjrek1hc.execute-api.us-east-1.amazonaws.com/updateaccountdata-git",
  },
  {
    name: "getapikeys",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://bpnlbbksn4.execute-api.us-east-1.amazonaws.com/getapikeys-git",
  },
  {
    name: "createapikey",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://lpoummvo1b.execute-api.us-east-1.amazonaws.com/createapikey-git",
  },
  {
    name: "createexternalapirecord",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://fe4sd15chd.execute-api.us-east-1.amazonaws.com/createexternalapirecord-git",
  },
  {
    name: "getusercompanydata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://o6w451eku9.execute-api.us-east-1.amazonaws.com/getusercompanydata-git",
  },
  {
    name: "uploadissueimagepresignedurl",
    stage: "dev",
    method: "POST",
    endpoint:
      "https://9tza2yg4g0.execute-api.us-east-1.amazonaws.com/uploadissueimagepresignedurl-git",
  },

  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://cxzldsyv5h.execute-api.us-east-1.amazonaws.com/getissuesfromarray-git",
  },
  {
    name: "doc-createdoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://uzpgt9mo74.execute-api.us-east-1.amazonaws.com/doc-createdoc-git",
  },
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://k2gudens39.execute-api.us-east-1.amazonaws.com/AddChildIssuesToParent-git",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://c46qekyxo3.execute-api.us-east-1.amazonaws.com/addorderlineitems-git",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://hn35f61eid.execute-api.us-east-1.amazonaws.com/ccamwebhookphoto-git",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://0tdmq6v1zf.execute-api.us-east-1.amazonaws.com/cloneissues-git",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://gt6ukjxjzb.execute-api.us-east-1.amazonaws.com/createissue-git",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://h4lmky7eqb.execute-api.us-east-1.amazonaws.com/createorder-git",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://hcny84gux4.execute-api.us-east-1.amazonaws.com/DeleteIssue-git",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://qv30a2nwcl.execute-api.us-east-1.amazonaws.com/deleteorder-git",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://hgi083c51l.execute-api.us-east-1.amazonaws.com/deleteorderlineitem-git",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://9en5yyovh0.execute-api.us-east-1.amazonaws.com/deleteproject-git",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://6kvpx64mz0.execute-api.us-east-1.amazonaws.com/getaccountdata-git",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://qosqdycc07.execute-api.us-east-1.amazonaws.com/getcategories-git",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://f8kno9j26a.execute-api.us-east-1.amazonaws.com/getcompanyorders-git",
  },
  {
    name: "getprojetusers",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://agfas4fp30.execute-api.us-east-1.amazonaws.com/getprojetusers-git",
  },
  {
    name: "getorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://13d8kgjpye.execute-api.us-east-1.amazonaws.com/getorder-git",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://g5ys04ddck.execute-api.us-east-1.amazonaws.com/getprojectorder-git",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://i5l7lg3lxi.execute-api.us-east-1.amazonaws.com/getprojects-git",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://fp8f7yhc6k.execute-api.us-east-1.amazonaws.com/getprojectsfromuserdata-git",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://hsyftv32hj.execute-api.us-east-1.amazonaws.com/getservertimestamp-git",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://52ioj3fnhg.execute-api.us-east-1.amazonaws.com/inviteprojectuser-git",
  },
  {
    name: "login-post-authentication",
    stage: "staging",
    method: "",
    endpoint: "no-endpoint",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://3fkg3rpyxj.execute-api.us-east-1.amazonaws.com/loginuser-git",
  },
  {
    name: "loginuserlocalhost",
    stage: "staging",
    method: "POST",
    endpoint: "https://du25x1kdl6.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://1b5le3ei6l.execute-api.us-east-1.amazonaws.com/queryissues-git",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ig3ujww0yg.execute-api.us-east-1.amazonaws.com/refreshaccesstoken-git",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://8m3yxxva45.execute-api.us-east-1.amazonaws.com/RemoveChildIssueFromParent-git",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://qx5anoskee.execute-api.us-east-1.amazonaws.com/updateorder-git",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://xvm1xkddo8.execute-api.us-east-1.amazonaws.com/UpdatePhotos1-git",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint:
      "https://zhsviglgyc.execute-api.us-east-1.amazonaws.com/prod/verifyrolesfromidtoken-git",
  },
  {
    name: "issueidreportbug",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://txs2sx0xk9.execute-api.us-east-1.amazonaws.com/issueidreportbug-git",
  },
  {
    name: "deleteprojectuser",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://daf238r0x7.execute-api.us-east-1.amazonaws.com/deleteprojectuser-git",
  },
];

export const PROD_ENDPOINTS_GIT = [
  {
    name: "initccamintegration",
    stage: "dev",
    method: "POST",
    endpoint: "https://x9js195gag.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "createcategory",
    stage: "dev",
    method: "POST",
    endpoint:
      "https://gahnl4usql.execute-api.us-east-2.amazonaws.com/createcategory-git",
  },
  {
    name: "deletecategory",
    stage: "dev",
    method: "DELETE",
    endpoint:
      "https://xfbqdvhutc.execute-api.us-east-2.amazonaws.com/deletecategory-git",
  },
  {
    name: "getprojectsall",
    stage: "staging",
    method: "POST",
    endpoint: "https://j98z8gcz4h.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "doc-deletedocument",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://ymcm3am60d.execute-api.us-east-2.amazonaws.com/doc-deletedocument-git",
  },
  {
    name: "doc-getdocumentlist",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://z4ep18cjhc.execute-api.us-east-2.amazonaws.com/doc-getdocumentlist-git",
  },
  {
    name: "receiveappcomplaints",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://cfltflwvfl.execute-api.us-east-2.amazonaws.com/receiveappcomplaints-git",
  },
  {
    name: "updateproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://yj2tayzyz3.execute-api.us-east-2.amazonaws.com/updateproject-git",
  },
  {
    name: "createtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://y1wbkqeobg.execute-api.us-east-2.amazonaws.com/createtemplate-git",
  },
  {
    name: "createissuesfromtemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://g2l2bxzns7.execute-api.us-east-2.amazonaws.com/createissuesfromtemplate-git",
  },
  {
    name: "createtemplatefromglobal",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://tm8oe9gqq6.execute-api.us-east-2.amazonaws.com/createtemplatefromglobal-git",
  },
  {
    name: "gettemplates",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://wc4qy3zcdh.execute-api.us-east-2.amazonaws.com/gettemplates-git",
  },
  {
    name: "updatetemplate",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://p0pmv2y2z8.execute-api.us-east-2.amazonaws.com/updatetemplate-git",
  },
  {
    name: "deletetemplate",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://2aheqayani.execute-api.us-east-2.amazonaws.com/deletetemplate-git",
  },
  {
    name: "deleteuseraccount",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://jgt5wsl972.execute-api.us-east-2.amazonaws.com/deleteuseraccount-git",
  },
  {
    name: "doc-updateildoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://yyxm14nh47.execute-api.us-east-2.amazonaws.com/doc-updateildoc",
  },
  {
    name: "companycamintegration",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://g8yka8lh90.execute-api.us-east-2.amazonaws.com/companycamintegration-git",
  },
  {
    name: "createdocurl",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://5r3k2pvuzi.execute-api.us-east-2.amazonaws.com/createdocurl-git",
  },
  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://9mv0r0dig6.execute-api.us-east-2.amazonaws.com/createproject-git",
  },
  {
    name: "uploadaccountlogo",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://5yjq6x4ht4.execute-api.us-east-2.amazonaws.com/uploadaccountlogo-git",
  },
  {
    name: "getapikeys",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ggzsfv9otc.execute-api.us-east-2.amazonaws.com/getapikeys-git",
  },
  {
    name: "createapikey",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://j7nr8bww91.execute-api.us-east-2.amazonaws.com/createapikey-git",
  },
  {
    name: "createexternalapirecord",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ua32w1ylnj.execute-api.us-east-2.amazonaws.com/createexternalapirecord-git",
  },
  {
    name: "uploadissueimagepresignedurl",
    stage: "dev",
    method: "POST",
    endpoint:
      "https://0d3clonplk.execute-api.us-east-2.amazonaws.com/uploadissueimagepresignedurl-git",
  },
  {
    name: "updateaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://tmadh5lyvh.execute-api.us-east-2.amazonaws.com/updateaccountdata-git",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint:
      "https://3fxpjyr41h.execute-api.us-east-2.amazonaws.com/updatecompanyrole-git",
  },
  {
    name: "getusercompanydata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://c5vakb3hvc.execute-api.us-east-2.amazonaws.com/getusercompanydata-git",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://s0c7zpymwh.execute-api.us-east-2.amazonaws.com/getacompanyusers-git",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint:
      "https://br8u51kq25.execute-api.us-east-2.amazonaws.com/invitecompanyusersarray-git",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://451v7nipof.execute-api.us-east-2.amazonaws.com/deletecompanyroles-git",
  },
  {
    name: "doc-createdoc",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://p1jpg52741.execute-api.us-east-2.amazonaws.com/doc-createdoc-git",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://mgl8xudllg.execute-api.us-east-2.amazonaws.com/doc-getdocdata-git",
  },
  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://rdjx9fjp40.execute-api.us-east-2.amazonaws.com/getissuesfromarray-git",
  },
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://emu3bp4w29.execute-api.us-east-2.amazonaws.com/AddChildIssuesToParent-git",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://dait2ogmu7.execute-api.us-east-2.amazonaws.com/addorderlineitems-git",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://32avxgnsg9.execute-api.us-east-2.amazonaws.com/ccamwebhookphoto-git",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://8t3ee8jojj.execute-api.us-east-2.amazonaws.com/cloneissues-git",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://6sgei8qff7.execute-api.us-east-2.amazonaws.com/createissue-git",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://pgre56ecw2.execute-api.us-east-2.amazonaws.com/createorder-git",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://6y6m78nhbd.execute-api.us-east-2.amazonaws.com/DeleteIssue-git",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://wi5z7eq3hl.execute-api.us-east-2.amazonaws.com/deleteorder-git",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://rf5m3yue5j.execute-api.us-east-2.amazonaws.com/deleteorderlineitem-git",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://qt1asdbh7j.execute-api.us-east-2.amazonaws.com/deleteproject-git",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://u30joczhug.execute-api.us-east-2.amazonaws.com/getaccountdata-git",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://z4kikf6dze.execute-api.us-east-2.amazonaws.com/getcategories-git",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://v12ke4o4d2.execute-api.us-east-2.amazonaws.com/getcompanyorders-git",
  },
  {
    name: "getinvitedusersproperty",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://xpc0f3gsac.execute-api.us-east-2.amazonaws.com/getinvitedusersproperty-git",
  },
  {
    name: "getorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://46ouyj7clj.execute-api.us-east-2.amazonaws.com/getorder-git",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ne9ai6oqwe.execute-api.us-east-2.amazonaws.com/getprojectorders-git",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ta5nowmpx0.execute-api.us-east-2.amazonaws.com/getprojects-git",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://3xhcz726s5.execute-api.us-east-2.amazonaws.com/getprojectsfromuserdata-git",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://4qfo5304t6.execute-api.us-east-2.amazonaws.com/getservertimestamp-git",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://66op3uer58.execute-api.us-east-2.amazonaws.com/inviteprojectuser-git",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://msy0nksv3h.execute-api.us-east-2.amazonaws.com/loginuser-git",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://nym1873qal.execute-api.us-east-2.amazonaws.com/queryissues-git",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://rb0ikey8ab.execute-api.us-east-2.amazonaws.com/refreshaccesstoken-git",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://4ucw6qq5pg.execute-api.us-east-2.amazonaws.com/RemoveChildIssueFromParent-git",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://z3xoda8g3i.execute-api.us-east-2.amazonaws.com/updateorder-git",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://wxjadw6zgl.execute-api.us-east-2.amazonaws.com/UpdatePhotos1-git",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint: "",
  },
  {
    name: "issueidreportbug",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://f17cm5fwz5.execute-api.us-east-2.amazonaws.com/issueidreportbug-git",
  },
  {
    name: "deleteprojectuser",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://dgqfgvgfkj.execute-api.us-east-2.amazonaws.com/deleteprojectuser-git",
  },
];

/////////

export const DEV_ENDPOINTS = [
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint: "https://593ixvxczb.execute-api.us-east-1.amazonaws.com/",
  },

  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint: "https://nvxuau7noh.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getapikeys",
    stage: "staging",
    method: "POST",
    endpoint: "https://s2ww2y3de6.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createexternalapirecord",
    stage: "staging",
    method: "POST",
    endpoint: "https://dnuwzmkz6d.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createapikey",
    stage: "staging",
    method: "POST",
    endpoint: "https://8s91k4li37.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "uploadaccountlogo",
    stage: "staging",
    method: "PUT",
    endpoint: "https://ycl8mbq5pl.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getusercompanydata",
    stage: "staging",
    method: "POST",
    endpoint: "https://mdguo95pzb.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint: "https://56hpv3pp1b.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://cn4dc6iyp3.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint: "https://bbvtnr6k0a.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://9lusf5x223.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint: "https://0yqwzhp8y2.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://doc4bb97ni.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint: "https://f427m1kg87.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://hdfak0srm9.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-createdoc",
    stage: "staging",
    method: "POST",
    endpoint: "https://rytg30jsv0.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://a45wu86vs2.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://9lgn16qyua.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://7msbyba86g.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://0r17iqvw21.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://exah1b7jve.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://d93kv5f1vc.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "updateaccountdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://mqtcrdwh60.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint: "https://ikmw18b8vb.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint: "https://g04ha1nmxk.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getinvitedusersproperty",
    stage: "staging",
    method: "POST",
    endpoint: "https://egx17fugg3.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getorderandlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://i507yl4k6e.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://irc6w5n1d7.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint: "https://2tnftz5frh.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://h54zl21y4g.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint: "https://b81e5xp191.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://je36s1u6yh.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "login-post-authentication",
    stage: "staging",
    method: "",
    endpoint: "no-endpoint",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://j1dbcsnlbk.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "loginuserlocalhost",
    stage: "staging",
    method: "POST",
    endpoint: "https://4b2jh3lmuc.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://j1drc7c6d0.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint: "https://jo21k0rgbc.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint: "https://iydkdq7p22.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint: "https://juolok0jo1.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint: "https://lcc3ivna35.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint: "https://worxqucx3l.execute-api.us-east-1.amazonaws.com/prod/",
  },
  {
    name: "issueidreportbug",
    stage: "staging",
    method: "POST",
    endpoint: "https://txs2sx0xk9.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint: "https://n2224cs5th.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint: "https://t3boprig26.execute-api.us-east-1.amazonaws.com/",
  },
];

export const STAGING_ENDPOINTS = [
  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint: "https://10g6xi7g89.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint: "https://doxuunddle.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getusercompanydata",
    stage: "staging",
    method: "POST",
    endpoint: "https://o6w451eku9.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://oos2fgjvek.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://8o1qslf6zi.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint: "https://bsvtfqjkle.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint: "https://cxzldsyv5h.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "doc-createdoc",
    stage: "staging",
    method: "POST",
    endpoint: "https://uzpgt9mo74.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://k2gudens39.execute-api.us-east-1.amazonaws.com/AddChildIssuesToParent",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://c46qekyxo3.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint: "https://hn35f61eid.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://0tdmq6v1zf.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://gt6ukjxjzb.execute-api.us-east-1.amazonaws.com/createissue",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://h4lmky7eqb.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint:
      "https://hcny84gux4.execute-api.us-east-1.amazonaws.com/DeleteIssue",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://qv30a2nwcl.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://hgi083c51l.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://9en5yyovh0.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://6kvpx64mz0.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint: "https://qosqdycc07.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint: "https://f8kno9j26a.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getinvitedusersproperty",
    stage: "staging",
    method: "POST",
    endpoint: "https://bx93reui18.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getorderandlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://13d8kgjpye.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://g5ys04ddck.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint: "https://i5l7lg3lxi.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://fp8f7yhc6k.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint: "https://hsyftv32hj.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://52ioj3fnhg.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "login-post-authentication",
    stage: "staging",
    method: "",
    endpoint: "no-endpoint",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://3fkg3rpyxj.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "loginuserlocalhost",
    stage: "staging",
    method: "POST",
    endpoint: "https://du25x1kdl6.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://1b5le3ei6l.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint: "https://ig3ujww0yg.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://8m3yxxva45.execute-api.us-east-1.amazonaws.com/RemoveChildIssueFromParent",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint: "https://qx5anoskee.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://xvm1xkddo8.execute-api.us-east-1.amazonaws.com/UpdatePhotos1",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint: "https://zhsviglgyc.execute-api.us-east-1.amazonaws.com/prod/",
  },
  {
    name: "issueidreportbug",
    stage: "staging",
    method: "POST",
    endpoint: "https://txs2sx0xk9.execute-api.us-east-1.amazonaws.com/",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint: "https://q63e5g20r5.execute-api.us-east-1.amazonaws.com/",
  },
];

export const PROD_ENDPOINTS = [
  {
    name: "createproject",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://9mv0r0dig6.execute-api.us-east-2.amazonaws.com/createproject-git",
  },

  {
    name: "getapikeys",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ggzsfv9otc.execute-api.us-east-2.amazonaws.com/getapikeys-git",
  },
  {
    name: "createapikey",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://j7nr8bww91.execute-api.us-east-2.amazonaws.com/createapikey-git",
  },
  {
    name: "createexternalapirecord",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://ua32w1ylnj.execute-api.us-east-2.amazonaws.com/createexternalapirecord-git",
  },

  {
    name: "uploadaccountlogo",
    stage: "staging",
    method: "PUT",
    endpoint:
      "https://5yjq6x4ht4.execute-api.us-east-2.amazonaws.com/uploadaccountlogo-git",
  },
  {
    name: "updatecompanyrole",
    stage: "staging",
    method: "post",
    endpoint: "https://3fxpjyr41h.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getusercompanydata",
    stage: "prod",
    method: "POST",
    endpoint: "https://c5vakb3hvc.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getacompanyusers",
    stage: "staging",
    method: "POST",
    endpoint: "https://s0c7zpymwh.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "invitecompanyusersarray",
    stage: "staging",
    method: "post",
    endpoint: "https://br8u51kq25.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "deletecompanyroles",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://451v7nipof.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "updateaccountdata",
    stage: "staging",
    method: "POST",
    endpoint:
      "https://tmadh5lyvh.execute-api.us-east-2.amazonaws.com/updateaccountdata-git",
  },
  {
    name: "doc-getdocdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://mgl8xudllg.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getissuesfromarray",
    stage: "staging",
    method: "POST",
    endpoint: "https://rdjx9fjp40.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "AddChildIssues",
    stage: "staging",
    method: "POST",
    endpoint: "https://emu3bp4w29.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "addorderlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://dait2ogmu7.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "ccamwebhookphoto",
    stage: "staging",
    method: "POST",
    endpoint: "https://32avxgnsg9.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "cloneissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://8t3ee8jojj.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "createissue",
    stage: "staging",
    method: "POST",
    endpoint: "https://6sgei8qff7.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "createorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://pgre56ecw2.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "DeleteIssue",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://6y6m78nhbd.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "deleteorder",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://wi5z7eq3hl.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "deleteorderlineitem",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://rf5m3yue5j.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "deleteproject",
    stage: "staging",
    method: "DELETE",
    endpoint: "https://qt1asdbh7j.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getaccountdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://u30joczhug.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getcategories",
    stage: "staging",
    method: "POST",
    endpoint: "https://z4kikf6dze.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getcompanyorders",
    stage: "staging",
    method: "POST",
    endpoint: "https://v12ke4o4d2.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getinvitedusersproperty",
    stage: "staging",
    method: "POST",
    endpoint: "https://xpc0f3gsac.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getorderandlineitems",
    stage: "staging",
    method: "POST",
    endpoint: "https://46ouyj7clj.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getprojectorder",
    stage: "staging",
    method: "POST",
    endpoint: "https://ne9ai6oqwe.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getprojects",
    stage: "staging",
    method: "POST",
    endpoint: "https://ta5nowmpx0.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getprojectsfromuserdata",
    stage: "staging",
    method: "POST",
    endpoint: "https://3xhcz726s5.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "getservertimestamp",
    stage: "staging",
    method: "POST",
    endpoint: "https://4qfo5304t6.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "inviteprojectuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://66op3uer58.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "loginuser",
    stage: "staging",
    method: "POST",
    endpoint: "https://msy0nksv3h.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "queryissues",
    stage: "staging",
    method: "POST",
    endpoint: "https://nym1873qal.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "refreshaccesstoken",
    stage: "staging",
    method: "POST",
    endpoint: "https://rb0ikey8ab.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "RemoveChildIssueFromParent",
    stage: "staging",
    method: "POST",
    endpoint: "https://4ucw6qq5pg.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "updateorder",
    stage: "staging",
    method: "PUT",
    endpoint: "https://z3xoda8g3i.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "UpdatePhotos-v1",
    stage: "staging",
    method: "PUT",
    endpoint: "https://wxjadw6zgl.execute-api.us-east-2.amazonaws.com/",
  },
  {
    name: "sluggyapi",
    stage: "staging",
    method: "",
    endpoint: "",
  },
];
