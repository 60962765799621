import { useState, useEffect, useCallback } from "react";

function getWindowScroll() {
  const { scrollY: scroll } = window;
  return scroll;
}

export function useWindowScroll(enable?: boolean) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowScroll());
  const handleScroll = useCallback(() => {
    setWindowDimensions(getWindowScroll());
  }, []);

  useEffect(() => {
    if (enable) {
      window.addEventListener("scroll", handleScroll, false);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll, enable]);

  return windowDimensions;
}
