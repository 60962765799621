const origin = window.location.origin;
const isLocalhost = origin.includes("localhost");

const devLoginURL = `https://devissueidpool1.auth.us-east-1.amazoncognito.com/login?client_id=jpoijcdnt2109cg5lo8o0ahe&response_type=code&scope=email+openid+phone&redirect_uri=${origin}/`;
const stagingURL = `https://zordforester.auth.us-east-1.amazoncognito.com/login?client_id=hcq9jkoukn1ser3n9p2pkdlqq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${origin}/`;
const prodLoginURL = `https://appv1-issueid.auth.us-east-2.amazoncognito.com/login?client_id=74ehj2078pnlbtjqio3dt7ghvo&response_type=code&scope=email+openid+phone&redirect_uri=${origin}/`;
export const loginUrl =
  process.env.REACT_APP_ENVIRONMENT === "STAGING"
    ? stagingURL
    : process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
    ? prodLoginURL
    : devLoginURL;

export const vanillaJsOriginURL = isLocalhost
  ? "http://localhost:8080"
  : process.env.REACT_APP_ENVIRONMENT === "STAGING"
  ? "https://v1.issueid.io"
  : process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
  ? "https://app.issueid.io"
  : "https://d211oy3j6vgaee.cloudfront.net";

export const helpCenterURL = "https://www.issueid.io/help-centre";
export const termsURL = "https://www.issueid.io/terms-conditions/";
