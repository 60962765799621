import { Form, useFormikContext } from "formik";
import {
  FIELD_NAMES,
  InviteUserFormInitialState,
  userRoleLevel,
} from "./InviteUserForm.constants";
import { PrimaryButton } from "components/Elementary/PrimaryButton/PrimaryButton";
import { useEffect, useRef } from "react";
import { ReactComponent as CloseButtonGray } from "../../../assets/img/general/closeButtonGray.svg";
import { FormikAdvanceSelect } from "components/Elementary/FormikAdvancedSelect";
import { ReactComponent as InfoIcon } from "assets/img/general/infoIcon.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import FormikToggleField from "components/Elementary/FormikToggleField/FormikToggle";
import FormikDropdownField from "components/Elementary/FormikDropdownField/FormikDropdownField";
import FormikCheckboxField from "components/Elementary/FormikCheckbox/FormikCheckbox";
import FormikTextarea from "components/Elementary/FormikTextarea";

export interface IInvitedUserFormComponentProps {
  className?: string;
  onClose?: () => void;
}

export function InviteUserFormComponent({
  onClose,
  className,
}: IInvitedUserFormComponentProps) {
  const { setValues, errors, values, isSubmitting, handleSubmit } =
    useFormikContext<InviteUserFormInitialState>();
  // removed style: absolute max-w-[416px] max-h-[482px]
  const ref = useRef<HTMLDivElement | undefined | null>(null);

  useEffect(() => {
    errors.emails &&
      errors.emails instanceof Array &&
      (errors.emails as unknown as Array<any>)?.map((err, index) => {
        setValues((prevValues) => {
          const emails = prevValues.emails.filter(
            (email, emailIndex) => index !== emailIndex
          );
          prevValues.emails = emails;
          return prevValues;
        });
      });
  }, [errors, setValues]);
  useEffect(() => {
    setValues((prevValues) => {
      prevValues.userManagement = false;
      prevValues.orderNotifications = false;
      return prevValues;
    });
  }, [setValues, values.permission?.value]);

  return (
    <div
      className=" z-[100]  inset-0 bg-white rounded border-surfaceAlt shadow"
      //@ts-ignore
      ref={ref}
    >
      <Form className=" w-full flex flex-col">
        <div className="pl-[16px] flex justify-between pt-[16px] items-center">
          <div className="font-inter font-medium text-[16px] leading-6">
            Invite new users
          </div>
          <CloseButtonGray
            className="pr-[20px] w-[32px] h-[32px] cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="px-[16px] pt-[5px] border-gray-300 rounded flex justify-between">
          <FormikAdvanceSelect
            name={FIELD_NAMES.EMAILS}
            className="w-[230px] rounded"
            id="emails"
            placeholder="emails"
            isClearable
            isCreatable
            isMulti
            menuIsOpen={false}
            styles={{
              indicatorsContainer: (provided) => {
                return {
                  ...provided,
                  transform: "scale(0.7)",
                  display: "none",
                };
              },
              valueContainer: (provided) => {
                return {
                  ...provided,
                  maxHeight: "70px",
                  overflow: "auto",
                };
              },
              control: (provided) => {
                return {
                  ...provided,
                  backgroundColor: "!gray-300",
                  borderRadius: "rounded",
                  borderColor: "border-gray-300",
                  boxShadow: "border-gray-300",
                  "&:hover": {
                    boxShadow: "border-gray-300",
                  },
                  "&:focus": {
                    border: "border-gray-300",
                  },
                  border: "border-gray-300",
                };
              },
            }}
          />
          <FormikDropdownField
            name={FIELD_NAMES.PERMISSION}
            placeholder="Choose role"
            options={userRoleLevel}
            sizes="xs"
            classNames={{
              dropdown: "w-[115px] h-[40px]",
            }}
            //@ts-ignore
            portalNode={ref.current}
          />
        </div>
        <div className="px-[16px] pt-[1px] rounded">
          <div className="font-inter font-normal text-[12px] leading-[18px] text-[#717171]">
            User Permissions
          </div>
          <div className="flex justify-between bg-surface">
            <div className="flex items-baseline">
              <div className="pt-[8px] font-inter font-normal text-[14px] leading-[18px] text-gray-900 pl-[8px] relative">
                User Management
              </div>
              <InfoIcon
                className=" cursor-pointer pl-[4px] w-[12px] h-[12px]"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Allows User to add/edit Users with matching permissions."
              />
            </div>
            <ReactTooltip
              id="my-tooltip"
              place="bottom"
              className="z-10 max-w-[195px] text-left bg-gray-800 text-gray-400"
            />
            <div className="pt-[8px]">
              <FormikToggleField
                disabled={
                  !values?.permission?.value ||
                  Number(values?.permission?.value) < 800
                }
                checked={values?.userManagement}
                name={FIELD_NAMES.USER_MANAGEMENT}
              />
            </div>
          </div>
          <div className="flex justify-between bg-surface">
            <div className="bg-surface pt-[8px] font-inter font-normal text-[14px] leading-[18px] text-gray-900 pl-[8px]">
              Order Notifications
            </div>
            <div className="pt-[8px]">
              <FormikToggleField
                disabled={
                  !values?.permission?.value ||
                  Number(values?.permission?.value) < 800
                }
                checked={values?.orderNotifications}
                name={FIELD_NAMES.ORDER_NOTIFICATIONS}
              />
            </div>
          </div>
          {/* <div className="flex justify-between bg-surface">
            <div className="bg-surface pt-[8px] font-inter font-normal text-[14px] leading-[18px] text-gray-900 pl-[8px]">
              Billing
            </div>
            <div className="pt-[8px]">
              <FormikToggleField name={FIELD_NAMES.ORDER_NOTIFICATIONS} />
            </div>
          </div> */}
        </div>
        <div className="px-[16px]"></div>
        {/* <div className="px-[16px] pt-[16px] rounded flex ">
          <div className="bg-surface w-full pb-[7px]">
            <FormikCheckboxField
              textLabel="Notify people"
              className="bg-surface"
              name={FIELD_NAMES.NOTIFY_PEOPLE}
            />
          </div>
        </div> */}
        <div className="px-[16px] pt-[16px]">
          <FormikTextarea
            name={FIELD_NAMES.MESSAGE}
            classNames={{
              input:
                "border-gray-300 h-[80px] focus:outline-none active:outline-none",
            }}
          />
        </div>
        <div className="px-[16px] pt-[16px] pb-[20px]">
          <PrimaryButton
            type="button"
            className="min-w-[376px] min-h-[40px]"
            text="Send Invite"
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting || values.permission === null}
          />
        </div>
      </Form>
    </div>
  );
}
