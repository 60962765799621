import {
  DEV_ENDPOINTS_GIT,
  PROD_ENDPOINTS_GIT,
  STAGING_ENDPOINTS_GIT,
} from "const/endpoints";
import { axiosDELETE, axiosPOST, axiosPUT } from "./methods";
import { CancelTokenSource } from "axios";
import {
  ICreatePropertyPayload,
  IInviteCompanyUsers,
  IPropertyItem,
} from "types/entities";
import { identity, pickBy } from "lodash";
import { ICompanyInfo } from "types/auth";
import { ITemplateIssue } from "modules/CompanySettings/subroutes/Templates";

const endpoints =
  process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT"
    ? DEV_ENDPOINTS_GIT
    : process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
    ? PROD_ENDPOINTS_GIT
    : STAGING_ENDPOINTS_GIT;
console.log("env", process.env.REACT_APP_ENVIRONMENT);

export async function uploadImage(
  file: any,
  uploadUrl: string,
  contentType: string
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", uploadUrl, true);
    xhr.setRequestHeader("Content-Type", contentType);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        console.error("Error:", xhr.status, xhr.statusText);
        reject(new Error("Image upload failed."));
      }
    };

    xhr.onerror = () => {
      console.error("Error:", xhr.status, xhr.statusText);
      reject(new Error("Image upload failed."));
    };

    xhr.send(file);
  });
}

export async function loginByCode(code: string, source?: CancelTokenSource) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "loginuser"
  )?.endpoint;
  const prepareCode = code.replace(/['"]+/g, "");

  const dataObj = {
    code: prepareCode,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchAuthDataByRefreshToken(
  token: string,

  source?: CancelTokenSource
) {
  const guest = localStorage.getItem("guest");
  const isGuest = guest === "true" || guest === "True";

  const url = endpoints.find(
    (endpoint) => endpoint.name === "refreshaccesstoken"
  )?.endpoint;
  const prepareToken = token.replace(/['"]+/g, "");

  const dataObj: any = {
    code: prepareToken,
  };
  if (isGuest) {
    dataObj["guest"] = true;
  }
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchDocument(
  companyId: string,
  projectId: string,
  documentId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "doc-getdocdata"
  )?.endpoint;

  const dataObj = {
    companyId,
    projectId,
    documentId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export interface IFetchCompanyRequestParams {
  companyId: string;
  filters: undefined | { [key: string]: string | undefined };
  PageSize: number;
  LastEvaluatedKey: string | null | undefined;
  source?: CancelTokenSource;
}

export async function fetchCompanyUsers<T = any>(
  companyId: string | undefined,
  filter: { [key: string]: string | undefined } | undefined,
  PageSize: number | undefined,
  LastEvaluatedKey: string | null,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getacompanyusers"
  )?.endpoint;

  const level =
    filter && filter["level"] && filter["level"].length > 0
      ? filter["level"]
      : undefined;
  const email =
    filter && filter["email"] && filter["email"].length > 0
      ? filter["email"]
      : undefined;
  // make sure to remove unnecessary and undefined values
  const dataObj = pickBy(
    {
      companyId,
      level,
      email,
      PageSize,
      LastEvaluatedKey,
    },
    identity
  );

  if (url) {
    return await axiosPOST<T>(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchIssuesFromArray(
  companyId: string,
  projectId: string,
  issueArray: string[],
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getissuesfromarray"
  )?.endpoint;

  const dataObj = {
    companyId,
    projectId,
    issueArray,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchProjects(
  companyId: string,
  projectId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getprojects"
  )?.endpoint;

  const dataObj = {
    CompanyId: companyId,
    projectId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchAllProjects(
  companyId: string,

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getprojectsall"
  )?.endpoint;

  const dataObj = {
    CompanyId: companyId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

interface IUpdateCompanyUser {
  id: string[];
  status?: string;
  order_access?: string;
  user_management?: boolean;
  order_notification?: boolean;
}

export async function updateCompanyUsers(
  data: IUpdateCompanyUser[],
  companyId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "updatecompanyrole"
  )?.endpoint;

  if (url) {
    return await axiosPOST(
      url,
      {
        users_data: data,
        companyId,
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchCompanyData(
  companyId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getaccountdata"
  )?.endpoint;

  const dataObj = {
    companyId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchAllCompanyData(source?: CancelTokenSource) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getusercompanydata"
  )?.endpoint;

  const dataObj = {};
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function inviteCompanyUsers<T = any>(
  data: Partial<IInviteCompanyUsers>[],
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "invitecompanyusersarray"
  )?.endpoint;

  if (url) {
    return await axiosPOST<T>(url, data, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function deleteCompanyRoles(
  companyId: string,
  email: string[],
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "deletecompanyroles"
  )?.endpoint;

  if (url) {
    return await axiosDELETE(
      url,
      {
        data: { companyId, email },
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createProperty<T = any>(
  data: ICreatePropertyPayload,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createproject"
  )?.endpoint;

  if (url) {
    return await axiosPOST<T>(url, data, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function updateCompanyData(
  data: ICompanyInfo,
  companyId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "updateaccountdata"
  )?.endpoint;

  if (url) {
    return await axiosPOST(
      url,
      {
        companyId,
        address: {
          city: data.address?.city, // City
          street: data.address?.street, // to parse from Address Line 1 before comma
          street_number: data.address?.street_number, // to parse from Address line 1 after comma
          state: data.address?.state, // State/Province/Region
          postal_code: data.address?.postal_code, // Postal Code
          address_line_2: data.address?.address_line_2,
        },
        reply_to_email: data?.reply_to_email,
        logo: data?.logo,
        name: data?.name,
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchApiKeys(
  companyId: string,
  whichKeys: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getapikeys"
  )?.endpoint;

  const dataObj = {
    companyId,
    whichKeys,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createApiKeys(
  companyId: string,

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createapikey"
  )?.endpoint;

  const dataObj = {
    companyId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function uploadAccountLogo(
  filedata: File,
  companyId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "uploadaccountlogo"
  )?.endpoint;

  let formData = new FormData();
  formData.append("filedata", filedata);
  const dataObj = filedata;
  if (url) {
    return await axiosPUT(url, dataObj, source, {
      headers: { "Content-Type": filedata.type, companyid: companyId },
    });
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createExternalApiRecord(
  companyId: string,
  value: string,
  description: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createexternalapirecord"
  )?.endpoint;

  const dataObj = {
    companyId,
    id: "CCAM",
    value,
    tool_name: "CCAM API Key",
    description,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function updateCCIntegration(
  companyId: string,
  action: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "companycamintegration"
  )?.endpoint;

  const dataObj = {
    companyId,
    action,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createDocumentURL(
  companyId: string,
  propertyId: string,
  documentId: string,
  origin: string,
  private_desc: boolean,
  price: boolean,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createdocurl"
  )?.endpoint;

  const dataObj = {
    companyId,
    propertyId,
    documentId,
    origin,
    private_desc,
    price,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function getCategories(
  companyId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getcategories"
  )?.endpoint;

  const dataObj = {
    companyId,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createCategory(
  companyId: string,
  category: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createcategory"
  )?.endpoint;

  const dataObj = {
    companyId,
    category,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function deleteCategory(
  companyId: string,
  category: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "deletecategory"
  )?.endpoint;

  const dataObj = {
    companyId,
    category,
  };
  if (url) {
    return await axiosDELETE(
      url,
      {
        data: dataObj,
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function getTemplates(
  companyId: string | undefined,

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "gettemplates"
  )?.endpoint;
  let dataObj = {};
  if (companyId) {
    dataObj = {
      companyId,
    };
  }

  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createTemplateFromGlobal(
  companyId: string,
  id: string,

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createtemplatefromglobal"
  )?.endpoint;

  const dataObj = {
    companyId,
    id,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function updateDocument(
  companyId: string,
  propertyId: string,
  id: string,
  form_data: string[],
  name: string,

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "doc-updateildoc"
  )?.endpoint;

  const dataObj = {
    companyId,
    propertyId,
    id,
    form_data,
    name,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createTemplate(
  companyId: string,
  fileName: string,
  priority: number,
  category: string,
  hazzard: boolean,
  price: number,
  recommend: boolean,
  label: string,
  description: string,
  description_int: string,
  status: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createtemplate"
  )?.endpoint;

  const dataObj = {
    companyId,
    fileName,
    priority,
    category,
    hazzard,
    price,
    recommend,
    label,
    description,
    description_int,
    status,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function uploadIssueImagePresigned(
  companyId: string,
  projectId: string,
  imageNames: string[],
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "uploadissueimagepresignedurl"
  )?.endpoint;

  const dataObj = {
    companyId,
    projectId,
    images: [...imageNames],
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function uploadS3Image(
  url: string,
  image: File,
  contentType: string,
  source?: CancelTokenSource
) {
  if (url) {
    return await axiosPUT(
      url,
      image,
      source,
      {
        headers: { "Content-Type": contentType },
      },
      true
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function deleteTemplate(
  companyId: string,
  templateId: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "deletetemplate"
  )?.endpoint;

  if (url) {
    return await axiosDELETE(
      url,
      {
        data: { companyId, id: templateId },
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function updateTemplate(
  data: Partial<ITemplateIssue>,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "updatetemplate"
  )?.endpoint;

  const dataObj = {
    ...data,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function updateProperty(
  data: Partial<IPropertyItem>,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "updateproject"
  )?.endpoint;

  if (url) {
    return await axiosPOST(
      url,
      {
        ...data,
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function getProjectsWithSearchPagination<T = any>(
  companyId: string,
  pageSize: number,
  lastEvaluatedKey?: string,
  search?: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "getprojects"
  )?.endpoint;

  const dataObj: any = {
    CompanyId: companyId,
    projectId: "",
    filter: {
      filterName: "name",
      filterValue: "",
      sort: "forward",
    },
    PageSize: pageSize || 1000,
  };

  if (lastEvaluatedKey) {
    dataObj.LastEvaluatedKey = lastEvaluatedKey;
  }

  if (search) {
    dataObj.filter = {
      filterName: "name",
      filterValue: search,
      sort: "forward",
    };
  }
  if (url) {
    return await axiosPOST<T>(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function createIssuesFromTemplateMultipleProperties(
  propertyId: string | string[],
  companyId: string,
  templateId: string,
  numIssues: string,
  template: {
    fileName: string;
    priority: number;
    hazzard: boolean;
    price: number;
    category: string;
    recommend: boolean;
    label: string;
    description: string;
    description_int: string;
    status: string;
  },

  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "createissuesfromtemplate"
  )?.endpoint;

  const dataObj = {
    propertyId,
    companyId,
    templateId,
    numIssues,
    template,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function fetchDocumentList(
  companyId: string,

  filter?: {
    filterList?: {
      filterName: string;
      filterValue: string;
    }[];
    sort?: string;
  },
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "doc-getdocumentlist"
  )?.endpoint;

  const dataObj = {
    companyId,

    filter,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function deleteDocument(
  companyId: string,
  propertyId: string,
  id: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "doc-deletedocument"
  )?.endpoint;

  if (url) {
    return await axiosDELETE(
      url,
      {
        data: { companyId, propertyId, id },
      },
      source
    );
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}

export async function initCCamIntegration(
  companyId: string,
  addIssues: boolean,
  apiKey: string,
  source?: CancelTokenSource
) {
  const url = endpoints.find(
    (endpoint) => endpoint.name === "initccamintegration"
  )?.endpoint;

  const dataObj = {
    companyId,
    addIssues,
    apiKey,
  };
  if (url) {
    return await axiosPOST(url, dataObj, source);
  } else {
    throw new Error("No endpoint provided for the request!");
  }
}
