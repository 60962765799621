import { IDropdownOptions, ISelectOption } from "types/utility";
//what we need to add for all and property only?
export const userRoleLevel: IDropdownOptions[] = [
  {
    label: "Can edit",
    value: "800",
    textStyling: " text-sm",
  },
  {
    label: "Can order",
    value: "500",
    textStyling: " text-sm",
  },
  {
    label: "Can view",
    value: "300",
    textStyling: " text-sm",
  },
];

//
interface IInviteUser {
  emails: string[];
  permission: string | null | undefined;
  userManagement: boolean;
  orderNotifications: boolean;
  billing: boolean;
  notifyPeople: boolean;
  message: string;
}

export enum FIELD_NAMES {
  EMAILS = "emails",
  PERMISSION = "permission",
  USER_MANAGEMENT = "userManagement",
  ORDER_NOTIFICATIONS = "orderNotifications",
  BILLING = "billing",
  NOTIFY_PEOPLE = "notifyPeople",
  MESSAGE = "message",
}

export class InviteUserFormInitialState {
  emails: ISelectOption[] = [];
  permission: IDropdownOptions | null = null;
  userManagement: boolean = false;
  orderNotifications: boolean = false;
  billing: boolean = false;
  notifyPeople: boolean = false;
  message: string = "";

  constructor(data?: IInviteUser) {
    this.addEmails(data?.emails);
    this.addUserManagement(data?.userManagement);
    this.addPermission(data?.permission);
    this.addOrderNotifications(data?.orderNotifications);
    this.addBilling(data?.billing);
  }
  addEmails(emails?: string[]) {
    if (emails?.length) {
      this.emails = emails.map((email) => {
        return { value: email, label: email };
      });
    }
  }
  addPermission(permission?: string | null | undefined) {
    if (permission) {
      this.permission =
        userRoleLevel.find((i) => i.value === permission) || null;
    }
  }
  addUserManagement(userManagement?: boolean) {
    if (userManagement) {
      this.userManagement = userManagement;
    }
  }
  addOrderNotifications(orderNotifications?: boolean) {
    if (orderNotifications) {
      this.orderNotifications = orderNotifications;
    }
  }
  addBilling(billing?: boolean) {
    if (billing) {
      this.billing = billing;
    }
  }
  addNotifyPeople(notifyPeople?: boolean) {
    if (notifyPeople) {
      this.notifyPeople = notifyPeople;
    }
  }
  addMessage(message?: string) {
    if (message?.length) {
      this.message = message;
    }
  }
}

export class InviteUserFormPayload {
  emails: string[] = [];
  companyId: string = "";
  inviter_email: string | null | undefined = null;
  inviterFullName: string | null | undefined = null;
  permission: string | null = null;
  companyName: string | null | undefined = null;
  notifyPeople: boolean = false;
  userManagement: boolean = false;
  orderNotifications: boolean = false;
  billing: boolean = false;
  message: string = "";

  constructor(
    data: Partial<InviteUserFormInitialState> & {
      companyId?: string | undefined;
      inviter_email: string | undefined;
      inviterFullName?: string | undefined;
      companyName: string | undefined;
    }
  ) {
    this.addEmails(data?.emails);
    this.addCompanyId(data?.companyId);
    this.addPermission(data.permission);
    this.addUserManagement(data?.userManagement);
    this.addOrderNotifications(data?.orderNotifications);
    this.addBilling(data?.billing);
    this.addNotifyPeople(data?.notifyPeople);
    this.addMessage(data?.message);
    this.inviter_email = data.inviter_email;
    this.inviterFullName = data.inviterFullName;
    this.companyName = data.companyName;
  }
  addEmails(emails: ISelectOption[] | undefined) {
    if (emails?.length) {
      this.emails = emails.map((email) => {
        return email.value;
      });
    }
  }
  addCompanyId(companyId: string | undefined) {
    if (companyId) {
      this.companyId = companyId;
    }
  }
  addPermission(permission?: IDropdownOptions | null) {
    if (permission) {
      this.permission = permission.value;
    }
  }

  addUserManagement(userManagement: boolean | undefined) {
    if (userManagement) {
      this.userManagement = userManagement;
    }
  }
  addOrderNotifications(orderNotifications: boolean | undefined) {
    if (orderNotifications) {
      this.orderNotifications = orderNotifications;
    }
  }
  addBilling(billing: boolean | undefined) {
    if (billing) {
      this.billing = billing;
    }
  }
  addNotifyPeople(notifyPeople: boolean | undefined) {
    if (notifyPeople) {
      this.notifyPeople = notifyPeople;
    }
  }
  addMessage(message: string | undefined) {
    if (message?.length) {
      this.message = message;
    }
  }
  getPayload() {
    return this.emails.map((email) => {
      return {
        email,
        companyId: this.companyId,
        inviter_email: this.inviter_email,
        level: this.permission,
        companyName: this.companyName,
        send_email: true,
        user_management: this.userManagement,
        receive_notifications: this.orderNotifications,
        notes: this.message,
      };
    });
  }
}
