import InviteUserForm from "components/Complex/InviteUserForm/InviteUserForm.container";
import { twMerge } from "tailwind-merge";
import { CustomModal } from "../CustomModal/CustomModal";
import classnames from "classnames";
import AddTemplateFormComponent from "components/Complex/AddTemplateForm/AddTemplateForm.component";

interface IInviteUserModal {
  open: boolean;
  onClose: () => void;
  className?: string;
}

export function InviteUserModal({ open, onClose }: IInviteUserModal) {
  return (
    <CustomModal
      open={open}
      className={twMerge(classnames("modal-invite-user-modal"))}
      onClose={onClose}
      closeOnOutsideClick
    >
      <InviteUserForm />
    </CustomModal>
  );
}
