import classnames from "classnames";
import {
  default as ReactSelect,
  GroupBase,
  MenuListProps,
  Props,
  components,
  OptionProps,
  MultiValueRemoveProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { useMemo, useRef } from "react";
import { ISelectOption } from "types/utility";
import { getStylesForReactSelectInputs } from "utilities/common";
import Label from "../Label";
import { ReactComponent as CloseButton } from "assets/img/general/closeButton.svg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

function MultiValueRemove(props: MultiValueRemoveProps) {
  return (
    <components.MultiValueRemove {...props}>
      <div>
        <CloseButton width="12px" />
      </div>
    </components.MultiValueRemove>
  );
}
function Option(props: OptionProps<any>) {
  return (
    <div className="">
      <components.Option {...props}>
        <label className="pl-1">{props.label}</label>
      </components.Option>
    </div>
  );
}
function MenuListWrapper(props: MenuListProps<any>) {
  return (
    <div className="  ">
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
}
export interface IAdvanceFieldProps extends Props<ISelectOption> {
  withErrorMessage?: boolean;
  label?: string;
  message?: string;
  className?: string;
  require?: boolean;
  required?: boolean;
  square?: boolean;
  isCreatable?: boolean;
  disableOption?: boolean;
  bottomMenuListComponent?: React.ReactNode;
  isError?: boolean;
  components?:
    | Partial<
        SelectComponents<ISelectOption, boolean, GroupBase<ISelectOption>>
      >
    | undefined;
}
export default function AdvanceSelect({
  withErrorMessage = true,
  message,
  className,
  classNames,
  label,
  require,
  required,
  square = false,
  bottomMenuListComponent,
  isCreatable,
  disableOption = false,
  components,
  styles,
  isError = false,
  ...rest
}: IAdvanceFieldProps) {
  const Select = isCreatable ? CreatableSelect : ReactSelect;
  const buildedComponents = useMemo(() => {
    const MenuList = bottomMenuListComponent
      ? (props: MenuListProps) =>
          MenuListWrapper({
            ...props,
            children: (
              <>
                {props.children}
                {bottomMenuListComponent}
              </>
            ),
          })
      : MenuListWrapper;
    return {
      MenuList,
      MultiValueRemove,
      ...components,
    };
  }, [bottomMenuListComponent, components]);
  if (!disableOption && !buildedComponents.Option) {
    buildedComponents.Option = Option;
  }
  const selectRef = useRef(null);
  return (
    <div
      className={classnames("flex text-[12px] flex-col ", className, {
        "": !message,
      })}
    >
      <>
        {label && (
          <Label
            name={label}
            className={" mt-[4px]"}
            required={require || required}
          />
        )}
        <Select
          ref={selectRef}
          className={classnames({ "mt-1": label })}
          menuPlacement="auto"
          styles={getStylesForReactSelectInputs({
            ...styles,
          })}
          hideSelectedOptions={false}
          //@ts-ignore
          components={buildedComponents}
          {...rest}
        />
        ​{withErrorMessage && <p className="text-red-400">{message}</p>}
      </>
    </div>
  );
}
