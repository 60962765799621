import classnames from "classnames";
import { twMerge } from "tailwind-merge";

export interface IToggleButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  classNames?: {
    input?: string;
    inputBottom?: string;
    inputBottomElement?: string;
  };
}

export function ToggleButtonComponent({
  className,
  classNames,
  ...rest
}: IToggleButtonProps) {
  return (
    <label
      className={twMerge(
        classnames(
          "relative inline-flex items-center  cursor-pointer",
          className
        )
      )}
    >
      <input
        type="checkbox"
        className={twMerge(classnames("sr-only peer", classNames?.input))}
        {...rest}
      />
      <div
        className={twMerge(
          classnames(
            "w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700  peer-focus:ring-green-300 dark:peer-focus:primary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-700",
            classNames?.inputBottom
          )
        )}
      />
      <span
        className={twMerge(
          classnames(
            "ml-3 text-sm font-medium text-gray-900 dark:text-gray-300",
            classNames?.inputBottomElement
          )
        )}
      />
    </label>
  );
}
