import {
  default as ReactSelect,
  GroupBase,
  MenuListProps,
  Props,
  components,
  OptionProps,
  ControlProps,
  InputProps,
} from "react-select";

import classnames from "classnames";
import CreatableSelect from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import { memo, useMemo } from "react";
import { getStylesForReactSelectInputs } from "utilities/common";
import { Label } from "../Label";
import { ISelectOption } from "types/utility";

function Option(props: OptionProps<any>) {
  return (
    <div className="mt-2">
      <components.Option {...props}>
        {/* <CustomCheckbox isSelected={props.isSelected} /> */}
        <label className="ml-4">{props.label}</label>
      </components.Option>
    </div>
  );
}

interface IControlProps extends ControlProps<any> {
  square: boolean;
  isSearchInput: boolean | undefined;
  isError: boolean;
  readOnly: boolean;
}

function Control({
  children,
  isError,
  square,
  isSearchInput,
  readOnly,
  ...props
}: IControlProps) {
  return (
    <div
      className={classnames("", {
        "!rounded-none": square,
        " focus-within:outline-none focus-within:outline-transparent":
          isSearchInput,
        "!bg-transparent": readOnly,
      })}
    >
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
}

function MenuListWrapper(props: MenuListProps<any>) {
  return (
    <div className=" ">
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
}

export interface IAdvanceFieldProps extends Props<ISelectOption> {
  label?: string;
  message?: string;
  className?: string;
  classNames?: {
    input?: (
      props: InputProps<
        ISelectOption<string, string>,
        boolean,
        GroupBase<ISelectOption<string, string>>
      >
    ) => string;
    message?: string;
    label?: string;
    inputContainer?: string;
  };
  require?: boolean;
  required?: boolean;
  square?: boolean;
  isSearchInput?: boolean | undefined;
  isCreatable?: boolean;
  disableOption?: boolean;
  bottomMenuListComponent?: React.ReactNode;
  isError?: boolean;
  readOnly?: boolean;
  components?:
    | Partial<
        SelectComponents<ISelectOption, boolean, GroupBase<ISelectOption>>
      >
    | undefined;
}

function AdvanceSelect({
  message,
  className,
  classNames,
  label,
  require,
  required,
  square = false,
  isSearchInput,
  bottomMenuListComponent,
  isCreatable,
  disableOption = false,
  components,
  styles,
  isError = false,
  readOnly = false,
  ...rest
}: IAdvanceFieldProps) {
  const Select = isCreatable ? CreatableSelect : ReactSelect;

  const buildedComponents = useMemo(() => {
    const MenuList = bottomMenuListComponent
      ? (props: MenuListProps) =>
          MenuListWrapper({
            ...props,
            children: (
              <>
                {props.children}
                {bottomMenuListComponent}
              </>
            ),
          })
      : MenuListWrapper;
    return {
      MenuList,
      ...components,
    };
  }, [bottomMenuListComponent, components]);

  if (!disableOption && !buildedComponents.Option) {
    buildedComponents.Option = Option;
  }

  const memoizedControl = useMemo(() => {
    return (props: ControlProps<any>) =>
      Control({ ...props, square, isSearchInput, isError, readOnly });
  }, [isError, isSearchInput, readOnly, square]);
  if (!components?.Control) {
    buildedComponents.Control = memoizedControl;
  }
  return (
    <div
      className={classnames("flex flex-col", className, {
        "pb-5": !message && !readOnly,
      })}
    >
      {label && (
        <Label
          name={label}
          className={classnames(classNames?.label, " ", {
            " font-bold ": readOnly,
          })}
          required={require || required}
        />
      )}
      <Select
        className={classnames("", classNames?.inputContainer, {
          "mt-1": label,
        })}
        menuPlacement="auto"
        styles={getStylesForReactSelectInputs({
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: "#a5abb6",
              fontWeight: 400,
              fontSize: "14px",
              textWrap: "nowrap",
            };
          },
          control: (provided) => ({
            ...provided,
            borderRadius: square ? 0 : "0.375rem",
            fontSize: "14px",
            paddingTop: "0.5px",
            fontWeight: 300,
            paddingBottom: "0.5px",
            borderColor: "transparent",
            background: readOnly ? "transparent" : provided.background,
            pointerEvents: readOnly ? "none" : undefined,
            boxShadow: "none",
            "&:hover": {
              borderColor: "transparent",
            },
          }),

          multiValue: (provided) => ({
            ...provided,
            display: "none",
            backgroundColor: "#11ffee00", // full opacity
            zIndex: 51,
          }),

          option: () => ({
            display: "flex",
            alignItems: "center",
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 50,
          }),
          input: (provided) => ({
            ...provided,
            paddingTop: "3px",
            paddingBottom: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: "8px",
            paddingBottom: "12px",
            zIndex: 51,
          }),
          ...styles,
        })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        //@ts-ignore
        components={buildedComponents}
        {...rest}
        isClearable={rest?.isClearable || !readOnly}
      />

      <div
        className={classnames(
          "text-gray-400  font-light text-sm",
          classNames?.message
        )}
      >
        {typeof message === "string" && message}
      </div>
    </div>
  );
}

export default memo(AdvanceSelect);
