import classnames from "classnames";
import { memo } from "react";

interface ILabelProps {
  name: string;
  className?: string;
  required?: boolean;
  onClick?: () => void;
}

function Label({ name, className, required, ...rest }: ILabelProps) {
  return (
    <label
      className={classnames(
        className,
        "text-[13px] font-inter text-gray-500 font-semibold"
      )}
      {...rest}
    >
      {name}
      {required && <span className="text-green-500 text-sm ml-1 ">*</span>}
    </label>
  );
}

export default memo(Label);
