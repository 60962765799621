import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { IAuthContext } from "types/auth";
import { APP_ROUTES } from "const/appRoutes";
import { useGetSetUrlParamsKeysValues } from "hooks/utility/useUrlParamsKeysValues";
import { ReactComponent as Logo } from "assets/img/general/logo.svg";
import { ReactComponent as Burger } from "assets/img/burger/burger.svg";
import { vanillaJsOriginURL } from "const/urls";

interface HeaderProps extends Partial<React.HTMLAttributes<HTMLDivElement>> {
  classNames?: { input?: string; label?: string };
  setOpenBurgerMenu: React.Dispatch<React.SetStateAction<boolean>>;
  context: Partial<IAuthContext> | undefined;
}

function Header({ classNames, setOpenBurgerMenu, context }: HeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { params, setUrlParams } = useGetSetUrlParamsKeysValues();
  const projectInParams = params.find((param) => param.key === "projectId");
  //project request here

  return (
    <div
      className={classnames(
        "relative flex items-start justify-between pl-[26px] pr-[21px] pt-[16px] bg-black h-[72px] w-full shrink-0"
      )}
    >
      <div className={classnames("flex items-center h-[40px] gap-[28px]")}>
        <a href={vanillaJsOriginURL} target="_self" rel="noopener noreferrer">
          <Logo
            // onClick={() => {
            //   navigate(APP_ROUTES.PROPERTIES);
            // }}
            className={classnames("h-[31px] w-[183px] cursor-pointer")}
          />
        </a>
      </div>

      <div
        className={classnames(
          "flex flex-row items-center text-white gap-[20px]"
        )}
      >
        <button
          onClick={() => {
            setOpenBurgerMenu((val) => !val);
          }}
          className="w-[44px] h-[44px] bg-transparent flex justify-center items-center border-none"
        >
          <Burger fill="white" />
        </button>
      </div>
    </div>
  );
}

export default Header;
