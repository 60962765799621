import React from "react";
import { ReactNode, ReactNodeArray, useContext } from "react";
import { IAuthContext } from "../types/auth";
import { useInitialAuth } from "./auth";

type AuthType = ReturnType<typeof useInitialAuth>;
//profile.id:url
const AuthContext = React.createContext<Partial<AuthType>>({});
///
export function AuthProvider({
  // should be in twilio client context
  children,
  auth,
}: {
  children: ReactNode | ReactNodeArray;
  auth: AuthType;
}) {
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
export function useAuth() {
  return useContext(AuthContext);
}
