import classnames from "classnames";
import { twMerge } from "tailwind-merge";

enum SIZE {
  xs = "prose-baseXsMedium  h-8 px-3 py-2 gap-2 grow-0 max-w-[136px] ",
  sm = "prose-baseSmMedium h-9 px-3 py-2 gap-2 grow-0 max-w-[147px]",
  base = "  h-10 px-4 py-2.5 gap-2 grow-0 max-w-[163px]",
  lg = "prose-baseMedium h-h_12 px-5 py-3 gap-2 grow-0 max-w-[182px]",
  xl = "prose-baseMedium h-h_13 px-6 py-3.5 gap-2 grow-0 max-w-[190px]",
  wide = "prose-baseMedium h-h_13 px-3 py-2 gap-2 w-full",
}

enum CONTENT_TYPE {
  default = "rounded-default",
  iconOnly = "rounded-full",
}

interface IPrimaryButtonProps
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  className?: string;
  classNames?: {
    text?: string;
    tooltip?: string;
  };
  text?: string;
  tooltipOnClick?: boolean;
  tooltipText?: string;
  disableHover?: boolean;
  disableFocus?: boolean;
  contentType?: "default" | "iconOnly";
  styles?: "solid" | "ethereal";
  size?: "xs" | "sm" | "base" | "lg" | "xl" | "wide";
  onlyIcon?: any;
  rightIcon?: any;
  leftIcon?: any;
}

export function PrimaryButton({
  className,
  classNames,

  disableHover = false,
  disableFocus = false,
  contentType = "default",
  size = "base",
  text,
  tooltipOnClick = false,
  tooltipText,
  styles = "solid",
  leftIcon = <></>,
  onlyIcon,
  rightIcon = <></>,
  children,
  ...rest
}: IPrimaryButtonProps) {
  const buttonDefaultStyle =
    "relative flex flex-row bg-primaryBase items-center justify-between text-onPrimary group";

  const buttonSizeStyle = SIZE[size];
  const buttonHoverStyle = disableHover
    ? ""
    : "hover:bg-primaryBaseHover text-onPrimary";
  const buttonFocusStyle = disableFocus
    ? ""
    : "focus:bg-primaryBaseHover focus:outline focus:outline-focusRing  ";
  const buttonDisabledStyle =
    "disabled:bg-[#14201708] disabled:cursor-not-allowed disabled:text-white";
  const buttonContentTypeStyle = CONTENT_TYPE[contentType];

  const textStyle =
    styles === "solid"
      ? " text-onPrimary "
      : "text-primaryBase group-hover:text-white group-focus:text-white";
  const buttonBackgroundStyle =
    styles === "solid" ? "" : "bg-transparent border border-primaryBase";
  const iconOnlyStyle = contentType === "iconOnly" ? "w-max p-2 h-max" : "";

  return (
    <button
      className={twMerge(
        classnames(
          buttonDefaultStyle,
          buttonSizeStyle,
          buttonHoverStyle,
          buttonFocusStyle,
          buttonContentTypeStyle,
          buttonBackgroundStyle,

          buttonDisabledStyle,
          iconOnlyStyle,
          className
        )
      )}
      {...rest}
    >
      {!onlyIcon && leftIcon}
      {onlyIcon}
      {text && contentType !== "iconOnly" && (
        <p className={twMerge(classnames(textStyle, classNames?.text))}>
          {text}
        </p>
      )}
      {!onlyIcon && rightIcon}
      {tooltipOnClick && tooltipText && (
        <div
          className={twMerge(
            classnames(
              "absolute hidden -top-8 left-1/2 transform -translate-x-1/2 bg-primaryBase text-onPrimary text-xs px-2 py-1 rounded whitespace-nowrap",
              classNames?.tooltip
            )
          )}
        >
          {tooltipText}
        </div>
      )}
    </button>
  );
}
