import { IAuthContext } from "types/auth";

interface FooterProps extends Partial<React.HTMLAttributes<HTMLDivElement>> {
  classNames?: { input?: string; label?: string };
}

function Footer({}: FooterProps) {
  return <div></div>;
}

export default Footer;
