import {
  ICompanyRoleStructure,
  IPropertyRoleStructure,
} from "const/interfaceStructures";
import { useAtom } from "jotai/react";
import {
  createContext,
  ReactNode,
  ReactNodeArray,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  authAtom,
  currentCompanyAtom,
  getAsyncAuthAtom,
  guestAtom,
  loadableAuthAtom,
  refreshAtom,
} from "store/atoms";
import {
  IAuth,
  IAuthUser,
  ICompanyRole,
  IPropertyRole,
  IAuthContext,
  RolesType,
  ICompanyInfo,
} from "types/auth";

import { iterateArrayByObjectType } from "utilities/common";
import { useGetSetUrlParamsKeysValues } from "./utility/useUrlParamsKeysValues";
import {
  useAuthByRefreshToken,
  useGetAllCompanyData,
  useGetCompanyData,
  useLoginByCode,
  useMultipleUserCompanies,
} from "api/query-hooks";
import { RESET } from "jotai/utils";
import { at } from "lodash";
import { useLogout } from "./utility/useLogout";
import { fetchCompanyData } from "api/requests";
import axios from "axios";
import { queryClient } from "index";
import { useSegment } from "./utility/useSegment";

export function useInitialAuth() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [atomAuth, setAtomAuth] = useAtom(authAtom); // 1
  const [atomAuthLoadingState, setAtomAuthLoader] = useAtom(loadableAuthAtom);

  const [atomRefresh, setAtomRefresh] = useAtom(refreshAtom); // 2

  const [atomGuest, setAtomGuest] = useAtom(guestAtom); // 3

  const [atomCurrentCompany, setAtomCurrentCompany] =
    useAtom(currentCompanyAtom); // 3

  const logout = useLogout();

  const { setUrlParams, searchParams } = useGetSetUrlParamsKeysValues();

  const codeInParams = searchParams.get("code");

  const refreshInParams = searchParams.get("refresh");

  const previousCompanyInfoId = localStorage.getItem("previousCompanyInfo");

  const ignorePreviousLocation = searchParams.get("ignorePreviousLocation");

  const companyInParams =
    searchParams.get("companyRef") || previousCompanyInfoId;

  const guestInParams = searchParams.get("guest");

  useEffect(() => {
    if (
      guestInParams &&
      (guestInParams === "true" || guestInParams === "True")
    ) {
      setAtomGuest(true);
      searchParams.delete("guest");
      setUrlParams(searchParams);
    }
  }, [guestInParams, searchParams, setAtomGuest, setUrlParams]);

  useEffect(() => {
    if (!!refreshInParams) {
      setAtomRefresh(refreshInParams);
      searchParams.delete("refresh");
      setUrlParams(searchParams);
    }
  }, [refreshInParams, setUrlParams, searchParams, setAtomRefresh]);

  const previousLocation = localStorage.getItem("previousLocation");

  const { data: loginData, isFetching: loginIsFetching } = useLoginByCode({
    enabled: !!codeInParams,
    keepPreviousData: false,
    suspense: true,
    code: codeInParams || "",
  });

  const { data: refreshedLoginData, isFetching: refreshedIsFetching } =
    useAuthByRefreshToken({
      enabled: !!refreshInParams,
      keepPreviousData: false,
      suspense: true,
      token: refreshInParams || "",
    });

  useEffect(() => {
    if (loginData?.data?.error === "invalid_grant") {
      logout();
    }
    if (loginData?.data && loginData.data.refresh_token) {
      setAtomRefresh(loginData.data.refresh_token);
      //@ts-ignore
      setAtomAuth(loginData.data);
    }
  }, [
    loginData,
    searchParams,
    logout,
    setAtomRefresh,
    setUrlParams,
    setAtomAuth,
  ]);
  useEffect(() => {
    if (refreshedLoginData?.data) {
      //@ts-ignore
      setAtomAuth(refreshedLoginData.data);
    }
  }, [
    refreshedLoginData,
    searchParams,

    setAtomRefresh,
    setUrlParams,
    setAtomAuth,
  ]);

  const authObject: IAuth | undefined = useMemo(() => atomAuth, [atomAuth]);

  const user: IAuthUser | undefined = useMemo(() => {
    return authObject?.user;
  }, [authObject]);

  const allRoles = useMemo(
    () => atomAuth?.user?.roles,
    [atomAuth?.user?.roles]
  );

  const propertyRoles = useMemo(
    () =>
      allRoles
        ?.filter((role) => {
          //@ts-ignore
          if (role.projectId) {
            return role;
          }
        })
        .filter((role) => {
          if (role !== undefined) return role as Partial<IPropertyRole>;
        }),
    [allRoles]
  );

  const companyRoles = useMemo(
    () =>
      allRoles
        //@ts-ignore
        ?.filter((role) => !role.projectId && role.companyId)
        .map((role) => {
          return role as Partial<ICompanyRole>;
        }),
    [allRoles]
  );

  const {
    data: companyData,
    isFetched: companyDataIsFetched,
    isFetching: companyDataIsFetching,
  } = useGetCompanyData({
    companyId:
      (companyRoles && companyRoles.length > 0 && companyRoles[0].companyId) ||
      "",
    enabled: !!(
      companyRoles &&
      companyRoles.length > 0 &&
      companyRoles[0].companyId
    ),
    suspense: true,
  });

  const companyDataShouldBeFetched = useMemo(() => {
    return companyRoles && companyRoles.length > 0 && companyRoles[0].companyId;
  }, [companyRoles]);

  const { data: allCompanyData, isFetched: fetchedAllCompanyData } =
    useGetAllCompanyData({
      enabled: !!authObject?.access_token,
    });

  const allCompaniesRequestsFinished = useMemo(() => {
    if (!companyDataShouldBeFetched) return fetchedAllCompanyData;

    return !!(companyDataIsFetched && fetchedAllCompanyData);
  }, [companyDataShouldBeFetched, fetchedAllCompanyData, companyDataIsFetched]);

  const allUserCompaniesInfo = useMemo(
    () =>
      (typeof allCompanyData?.data.body !== "string" &&
        (allCompanyData?.data.body
          ?.filter((companyInfoResponse) => companyInfoResponse.length > 0)
          .map((companyInfo) => companyInfo[0]) as ICompanyInfo[])) ||
      [],
    [allCompanyData]
  );

  useEffect(() => {
    if (!!companyInParams && allCompaniesRequestsFinished) {
      const company = allUserCompaniesInfo.find((company) => {
        return company.id === companyInParams;
      });

      setAtomCurrentCompany(company);
      searchParams.delete("companyRef");
      localStorage.removeItem("previousCompanyInfo");
      setUrlParams(searchParams);
    }
  }, [
    companyInParams,
    allCompaniesRequestsFinished,
    allUserCompaniesInfo,
    setUrlParams,
    searchParams,
    setAtomCurrentCompany,
  ]);

  const companyInfo = useMemo(() => atomCurrentCompany, [atomCurrentCompany]);

  const currentCompanyRole = useMemo(() => {
    return (
      companyRoles &&
      companyRoles.find((role) => role.companyId === companyInfo?.id)
    );
  }, [companyRoles, companyInfo]);

  useEffect(() => {
    if (
      !companyInParams &&
      allCompaniesRequestsFinished &&
      allUserCompaniesInfo.length > 0 &&
      !atomCurrentCompany
    ) {
      const previousCompanyInfo = localStorage.getItem("previousCompanyInfo");

      if (previousCompanyInfo) {
        setAtomCurrentCompany(
          allUserCompaniesInfo.find(
            (company) => company.id === previousCompanyInfo
          )
        );
      } else {
        setAtomCurrentCompany(allUserCompaniesInfo[0]);
      }
    }
  }, [
    companyData?.data,
    companyInParams,
    atomCurrentCompany,
    setAtomCurrentCompany,
    allUserCompaniesInfo,
    allCompaniesRequestsFinished,
  ]);

  const refresh: string = useMemo(() => atomRefresh, [atomRefresh]);

  const accessType = useMemo(
    () => companyRoles && companyRoles[0]?.level,
    [companyRoles]
  );

  // const propertyRoles = useMemo(() => {
  //   return (
  //     atomAuth?.user?.roles &&
  //     (iterateArrayByObjectType<IPropertyRole>(
  //       atomAuth?.user?.roles,
  //       "filter",
  //       IPropertyRoleStructure
  //     ) as IPropertyRole)
  //   );
  // }, [atomAuth]);

  useEffect(() => {
    if (atomAuthLoadingState.state === "hasData") {
      if (!codeInParams && !refreshInParams && allCompaniesRequestsFinished) {
        setIsLoading(false);
      }
      if (
        codeInParams &&
        authObject &&
        authObject?.access_token &&
        allCompaniesRequestsFinished
      ) {
        setIsLoading(false);
        searchParams.delete("code");
        setUrlParams(searchParams);
      }
      if (
        refreshInParams &&
        authObject &&
        authObject?.access_token &&
        allCompaniesRequestsFinished
      ) {
        setIsLoading(false);
        searchParams.delete("refresh");
        setUrlParams(searchParams);
      }

      if (!authObject && !loginData && !refreshedLoginData) {
        logout();
      }
    }
  }, [
    codeInParams,
    searchParams,
    atomAuthLoadingState,
    loginData,
    refreshedLoginData,
    refreshInParams,
    allCompaniesRequestsFinished,
    setUrlParams,
    logout,
    setIsLoading,
    authObject,
    atomAuth,
  ]);

  useEffect(() => {
    if (
      authObject &&
      companyRoles &&
      allCompaniesRequestsFinished &&
      previousLocation
    ) {
      if (!ignorePreviousLocation) {
        navigate(previousLocation);
      }
      localStorage.removeItem("previousLocation");
      searchParams.delete("ignorePreviousLocation");
    }
  }, [
    navigate,
    authObject,
    searchParams,
    previousLocation,
    companyRoles,
    allCompaniesRequestsFinished,
    ignorePreviousLocation,
  ]);

  const authContext: IAuthContext = useMemo(() => {
    return {
      isAuthorized: !!authObject?.access_token,
      refresh: refresh,
      accessType,
      currentCompanyRole,
      companyRoles,
      companyInfo,
      availableCompanies: allUserCompaniesInfo,
      propertyRoles,
      setAuth: setAtomAuth,
      setIsLoading,
      setCompanyInfo: setAtomCurrentCompany,
      setRefresh: setAtomRefresh,
      user,
      guest: atomGuest,
      auth: authObject,
      isLoading,
    };
  }, [
    accessType,
    authObject,
    atomGuest,
    refresh,
    companyInfo,
    currentCompanyRole,
    companyRoles,
    propertyRoles,
    allUserCompaniesInfo,
    setAtomAuth,
    setAtomCurrentCompany,
    setAtomRefresh,
    user,
    isLoading,
  ]);

  const { identifyUserSegment, actionsLoginSegment } = useSegment();
  useEffect(() => {
    if (loginData?.data.user) {
      identifyUserSegment();
      actionsLoginSegment();
    }
  }, [actionsLoginSegment, identifyUserSegment, loginData]);

  return authContext;
}
//
