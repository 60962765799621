import { useLocation, useRoutes } from "react-router-dom";
import routes from "router/router.config";
import { ErrorBoundary } from "react-error-boundary";
import { ReactChild, Suspense, useEffect } from "react";
import { Loader } from "components/Elementary/Loader/Loader";
import { AuthProvider } from "hooks/authContext";
import { useInitialAuth } from "hooks/auth";
import { useSegment } from "hooks/utility/useSegment";

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center h-full flex-1 gap-4"
    >
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
export function AppWrapper({ children }: { children: ReactChild }) {
  const auth = useInitialAuth();
  const location = useLocation()
  const { pageviewSegment } = useSegment()
  useEffect(() => {
    pageviewSegment()
  }, [location.pathname])
  
  return <AuthProvider auth={auth}>{children}</AuthProvider>;
}

function App() {
  const elements = useRoutes(routes);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{elements}</ErrorBoundary>
  );
}

export default App;
