import { useState, useEffect, useCallback } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(enable?: boolean) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const handleResize = useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  useEffect(() => {
    if (enable) {
      window.addEventListener("resize", handleResize, false);
    } else {
      window.removeEventListener("resize", handleResize);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, enable]);

  return windowDimensions;
}
