import { lazy, useEffect } from "react";
import {
  Navigate,
  useLocation,
  Outlet,
  createBrowserRouter,
} from "react-router-dom";

import { APP_ROUTES } from "const/appRoutes";

import { ICustomRouteObject } from "types/auth";

import MainLayout from "layouts/MainLayout";

import { Loader } from "components/Elementary/Loader/Loader";

import { PdfPage } from "modules/Pdf";

import { useAuthByRefreshToken } from "api/query-hooks";
import { useAuth } from "hooks/authContext";

import { useLogout } from "hooks/utility/useLogout";
import { CreateProperty } from "modules/CreateProperty";
import { vanillaJsOriginURL } from "const/urls";
import { AppWrapper } from "App";
import { ErrorBoundary } from "react-error-boundary";

interface IRouteAccess {
  children: any;
  redirectTo: string;
}

interface IAuthorizationCheckProps {
  children: any;
}

// interface IAccessLevelCheckProps {
//   accessLevel: SRole;
//   children: any;
//   redirectIfNotEnoughAccessLevel?: APP_ROUTES;
// }

const Properties = lazy(() => import("modules/Properties"));
const Issues = lazy(() => import("modules/Issues"));
const DocumentList = lazy(() => import("modules/DocumentList"));
const Categories = lazy(
  () => import("modules/CompanySettings/subroutes/Categories")
);
const Orders = lazy(() => import("modules/Orders"));
const Order = lazy(() => import("modules/Order"));
const NoAccess = lazy(() => import("modules/NoAccess"));
const NotFound = lazy(() => import("modules/NotFound"));
const EditDocument = lazy(() => import("modules/EditDocument"));
const TestPage = lazy(() => import("modules/TestPage"));
const CompanySettings = lazy(() => import("modules/CompanySettings"));
const Members = lazy(() => import("modules/CompanySettings/subroutes/Members"));
const Templates = lazy(
  () => import("modules/CompanySettings/subroutes/Templates")
);
const Information = lazy(
  () => import("modules/CompanySettings/subroutes/Information")
);
const Integrations = lazy(
  () => import("modules/CompanySettings/subroutes/Integrations")
);
const AccountContacts = lazy(
  () => import("modules/CompanySettings/subroutes/AccountContacts")
);

function HasAdminAccess({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useAuth();

  const isGuest = authContext?.guest;

  if (
    authContext?.accessType &&
    (Number(authContext?.accessType) !== 800 ||
      authContext.currentCompanyRole?.user_management !==
        (true || "true" || "True"))
  ) {
    window.location.replace(
      `${
        vanillaJsOriginURL +
        `/projects?rcode=${authContext?.refresh}&companyRef=${
          authContext?.companyInfo?.id
        }${isGuest ? "&guest=true" : ""}`
      }`
    );
    return;
  }

  return children;
}

function NotFoundCheck({
  children,
  redirectTo = APP_ROUTES.NOT_FOUND,
}: IRouteAccess) {
  const location = useLocation();
  const pathExists = Object.values<string>(APP_ROUTES).includes(
    location.pathname
  );
  return pathExists ? children : <Navigate to={redirectTo} />;
}

function Access300({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useAuth();
  if (authContext?.accessType && Number(authContext?.accessType) < 300) {
    return <Navigate to={redirectTo} />;
  }

  return children;
}

function Access500({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useAuth();
  if (authContext?.accessType && Number(authContext?.accessType) < 500) {
    return <Navigate to={redirectTo} />;
  }

  return authContext?.isAuthorized ? children : <Navigate to={redirectTo} />;
}

function Access800({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useAuth();
  if (authContext?.accessType && Number(authContext?.accessType) < 800) {
    return <Navigate to={redirectTo} />;
  }

  return authContext?.isAuthorized ? children : <Navigate to={redirectTo} />;
}

function AccessUserManager({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useAuth();
  const isUserManager =
    authContext.companyRoles?.find(
      (role) => role.companyId === authContext.companyInfo?.id
    )?.user_management === (true || "true" || "True");

  if (!isUserManager) {
    return <Navigate to={redirectTo} />;
  }

  return authContext?.isAuthorized ? children : <Navigate to={redirectTo} />;
}

interface IAuthorize {
  children: any;
}
function Authorize({ children }: IAuthorize) {
  const context = useAuth();

  if (context?.isLoading) {
    return <Loader />;
  }

  return children;
}

function AuthorizationCheck({ children }: IAuthorizationCheckProps) {
  const authContext = useAuth();
  const logout = useLogout();

  const triggerRefresh =
    !authContext?.auth?.access_token && !!authContext?.refresh;
  //TODO - CHECK IF LOOP
  useEffect(() => {
    if (triggerRefresh) {
      authContext?.setIsLoading && authContext?.setIsLoading(true);
    }
  }, [triggerRefresh, authContext?.setIsLoading, authContext]);

  const { data: refreshedData } = useAuthByRefreshToken({
    token: authContext?.refresh || "",
    enabled: triggerRefresh,
  });

  useEffect(() => {
    if (refreshedData?.data) {
      authContext?.setAuth && authContext?.setAuth(refreshedData.data);
    }
  }, [refreshedData, authContext?.setAuth, authContext]);

  useEffect(() => {
    if (triggerRefresh) {
      if (authContext?.auth?.access_token) {
        authContext.setIsLoading && authContext.setIsLoading(false);
      }
    }
  }, [triggerRefresh, authContext]);

  if (authContext?.isLoading) {
    return <Loader />;
  }

  if (!authContext?.auth || !authContext?.companyRoles) {
    if (!authContext?.refresh) {
      logout();
      return;
    }
  }
  return children;
}
function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center h-full flex-1 gap-4"
    >
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
const routes = createBrowserRouter([
  {
    element: (
      <AppWrapper>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Authorize>
            <Outlet />
          </Authorize>
        </ErrorBoundary>
      </AppWrapper>
    ),
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: APP_ROUTES.TEST_PAGE,
            element: (
              <AuthorizationCheck>
                <TestPage />
              </AuthorizationCheck>
            ),
          },
          {
            index: true,

            element: (
              <AuthorizationCheck>
                <HasAdminAccess redirectTo={APP_ROUTES.NOT_FOUND}>
                  <Navigate
                    to={APP_ROUTES.COMPANY_SETTINGS + "/information"}
                    replace
                  />
                </HasAdminAccess>
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.COMPANY_SETTINGS,
            element: (
              <AuthorizationCheck>
                <Access800 redirectTo={APP_ROUTES.NOT_FOUND}>
                  <HasAdminAccess redirectTo={APP_ROUTES.NOT_FOUND}>
                    <CompanySettings />
                  </HasAdminAccess>
                </Access800>
              </AuthorizationCheck>
            ),
            children: [
              {
                path: "*",
                element: <NotFound />,
              },
              {
                index: true,

                element: (
                  <Navigate
                    to={APP_ROUTES.COMPANY_SETTINGS + "/information"}
                    replace
                  />
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/information",
                element: (
                  <AuthorizationCheck>
                    <Information />
                  </AuthorizationCheck>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/account-contacts",
                element: (
                  <AuthorizationCheck>
                    <AccountContacts />
                  </AuthorizationCheck>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/categories",
                element: (
                  <AuthorizationCheck>
                    <Categories />
                  </AuthorizationCheck>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/integrations",
                element: (
                  <AuthorizationCheck>
                    <Integrations />
                  </AuthorizationCheck>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/templates",
                element: (
                  <AuthorizationCheck>
                    <Templates />
                  </AuthorizationCheck>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_SETTINGS + "/members",
                element: (
                  <AuthorizationCheck>
                    <Access800 redirectTo={APP_ROUTES.NOT_FOUND}>
                      <AccessUserManager redirectTo={APP_ROUTES.NOT_FOUND}>
                        <Members />
                      </AccessUserManager>
                    </Access800>
                  </AuthorizationCheck>
                ),
              },
            ],
          },
          {
            path: APP_ROUTES.PROPERTIES,
            element: (
              <AuthorizationCheck>
                <Access300 redirectTo={APP_ROUTES.NOT_FOUND}>
                  <Properties />
                </Access300>
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.ISSUES,
            element: (
              <AuthorizationCheck>
                <Issues />
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.DOCUMENTS,
            element: (
              <AuthorizationCheck>
                <DocumentList />
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.EDIT_DOCUMENT,
            element: <EditDocument />,
          },
          {
            path: APP_ROUTES.ORDERS,
            element: (
              <AuthorizationCheck>
                <Orders />
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.ORDER,
            element: (
              <AuthorizationCheck>
                <Order />
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.PDF,
            element: (
              <AuthorizationCheck>
                <Access300 redirectTo={APP_ROUTES.NOT_FOUND}>
                  <PdfPage />
                </Access300>
              </AuthorizationCheck>
            ),
          },
          {
            path: APP_ROUTES.NOT_FOUND,
            element: <NotFound />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
          {
            path: APP_ROUTES.NOT_FOUND,
            element: <NoAccess />,
          },
          // {
          //   path: APP_ROUTES.USER_MANAGEMENT,
          //   element: (
          //     <AuthorizationCheck>
          //       <Access800 redirectTo={APP_ROUTES.NOT_FOUND}>
          //         <AccessUserManager redirectTo={APP_ROUTES.NOT_FOUND}>
          //           <UserManagement />
          //         </AccessUserManager>
          //       </Access800>
          //     </AuthorizationCheck>
          //   ),
          // },
        ],
      },
      {
        element: <MainLayout displayHeader={false} displayFooter={false} />,
        children: [
          {
            path: APP_ROUTES.CREATE_PROPERTY,
            element: (
              <AuthorizationCheck>
                <Access800 redirectTo={APP_ROUTES.NOT_FOUND}>
                  <CreateProperty />
                </Access800>
              </AuthorizationCheck>
            ),
          },
        ],
      },
    ],
  },
]);

export default routes;
