import React, { useState } from "react";
import { ReactComponent as EmptyUserSearchIcon } from "assets/img/general/emptyUserSearchIcon.svg";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { ReactComponent as PlusIcon } from "assets/img/buttons/plus-icon.svg";
import { InviteUserModal } from "components/Complex/Modals/InviteUserModal/InviteUserModal";
import { IAuthContext } from "types/auth";

interface IEmptyUserSearchProps {
  onInviteButtonClick?: () => void | undefined;
  //onInviteButtonClick?: boolean;
  context?: Partial<IAuthContext>;
}

export function EmptyUserSearch({
  onInviteButtonClick,
}: IEmptyUserSearchProps) {
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-center justify-center h-full min-h-[500px]">
      <div>
        <EmptyUserSearchIcon />
      </div>
      <div className="font-inter font-semibold text-lg leading-6 text-center pb-[15px] text-[#202B23]">
        No users match your search
      </div>
      <div className="font-inter font-normal leading-5 text-center pb-[15px] max-w-[350px] text-[#202B23]">
        Try being less specific, using different search terms, or invite a new
        user.
      </div>
      <div>
        <PrimaryButton
          styles="solid"
          contentType="default"
          classNames={{ text: "[@media(max-width:450px)]:hidden" }}
          className="[@media(max-width:450px)]:p-[12px]"
          onlyIcon={<PlusIcon className=" fill-white " />}
          size="lg"
          text="Invite new user"
          onClick={() => {
            setOpenInviteModal(true);
            //@ts-ignore
            onInviteButtonClick();
          }}
        />
      </div>
      {openInviteModal && (
        <InviteUserModal
          open={openInviteModal}
          onClose={() => {
            setOpenInviteModal(false);
          }}
        />
      )}
    </div>
  );
}
