import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import { useEffect, useLayoutEffect, useState } from "react";

interface IProps {
  show: boolean;
  className?: string;
  directionX?: "left" | "right" | "center";
  directionY?: "top" | "bottom";

  listenedDimensionHeight?: number;
  listenedDimensionWidth?: number;
  listenedScroll?: number;
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  parentNode?:
    | HTMLElement
    | HTMLDivElement
    | HTMLButtonElement
    | HTMLInputElement;
  portalNode?: HTMLElement;
  children: React.ReactNode;
}

interface IDimensionClasses {
  height?: number;
  width: number;
  top: number;
  left?: number;
  right?: number;
  hidden: boolean;
}

function DropdownModal({
  show,
  className,
  directionX = "center",
  directionY = "bottom",
  customRef,

  listenedDimensionHeight,
  listenedDimensionWidth,
  listenedScroll,
  parentNode = document.body,
  portalNode = document.body,
  children,
}: IProps) {
  const [parentBounds, setParentBounds] = useState<DOMRect>(
    parentNode.getBoundingClientRect()
  );
  const [dimensionClasses, setDimensionClasses] = useState<IDimensionClasses>({
    height: 0,
    width: 0,
    top: 0,
    hidden: true,
  });

  useEffect(() => {
    setParentBounds(parentNode.getBoundingClientRect());
    window.addEventListener(
      "scroll",
      () => {
        setParentBounds(parentNode.getBoundingClientRect());
      },
      true
    );
    return () => {
      window.removeEventListener(
        "scroll",
        () => {
          setParentBounds(parentNode.getBoundingClientRect());
        },
        true
      );
    };
  }, [
    parentNode,
    listenedDimensionHeight,
    listenedDimensionWidth,
    listenedScroll,
    show,
  ]);
  useEffect(() => {
    const directionHorizontalDimensions =
      directionX === "center"
        ? {
            left: parentBounds?.left || 0,
          }
        : directionX === "left"
        ? {
            left:
              parentBounds?.right - Number(customRef?.current?.offsetWidth) ||
              0,
          }
        : {
            left: parentBounds?.right || 0,
          };
    const directionVerticalDimensions =
      directionY === "bottom"
        ? {
            top: parentBounds.top + parentBounds.height + 10 || 0,
          }
        : {
            top:
              parentBounds.top - Number(customRef?.current?.offsetHeight) - 5 ||
              0,
          };

    setDimensionClasses({
      width: parentBounds?.width || 0,
      hidden: false,
      ...directionHorizontalDimensions,
      ...directionVerticalDimensions,
    });
  }, [parentBounds, customRef, directionX, directionY]);
  const horizontalStyleLeft = dimensionClasses.left
    ? { left: `${dimensionClasses.left}px` }
    : {};
  const horizontalStyleRight = dimensionClasses.right
    ? { right: `${dimensionClasses.right}px` }
    : {};
  const style = {
    top: `${dimensionClasses.top}px`,
    minWidth:
      dimensionClasses.width < 100
        ? "fit-content"
        : `${dimensionClasses.width}px`,
    display: dimensionClasses.hidden ? "none" : "",
    ...horizontalStyleRight,
    ...horizontalStyleLeft,
  };

  return ReactDOM.createPortal(
    <div
      ref={customRef}
      style={style}
      className={twMerge(
        classnames(
          "absolute  left-0 z-[3501]  outline-none  flex-col items-start  justify-center bg-white border border-gray-200 rounded-default ",
          className,
          {
            block: show,
            hidden: !show,
          }
        )
      )}
    >
      {children}
    </div>,
    portalNode
  );
}

export default DropdownModal;
