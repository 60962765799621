import { ReactComponent as SpinnerIcon } from "assets/img/spinner.svg";
import classnames from "classnames";
import { twMerge } from "tailwind-merge";

interface IProps {
  className?: string;
  position?: "relative" | "absolute";
  darken?: boolean;
  classNames?: { spinner?: string };
}

export function Loader({ className, position, darken, classNames }: IProps) {
  return (
    <div
      className={twMerge(
        classnames(
          "flex items-center justify-center h-full",
          { "absolute w-full z-[9999]": position === "absolute" },
          { "bg-black bg-opacity-10": darken },

          className
        )
      )}
    >
      <SpinnerIcon
        className={classnames("h-[50px] w-[50px]", classNames?.spinner)}
      />
    </div>
  );
}
