import { IAuthContext, ICompanyInfo } from "types/auth";
import { ReactComponent as CloseCircle } from "assets/img/burger/close.svg";
import { ReactComponent as BurgerMenuLogo } from "assets/img/burger/burgerLogo.svg";
import { ReactComponent as ProfilePlaceholder } from "assets/img/burger/nonameProfilePic.svg";
import { ReactComponent as PropertiesIcon } from "assets/img/burger/properties.svg";
import { ReactComponent as AllOrdersIcon } from "assets/img/burger/allOrders.svg";
import { ReactComponent as HelpCenterIcon } from "assets/img/burger/helpCenter.svg";
import { ReactComponent as ShovelsIcon } from "assets/img/burger/shovels.svg";
import { ReactComponent as AccountIcon } from "assets/img/buttons/account.svg";
import { ReactComponent as LogoutIcon } from "assets/img/burger/logout.svg";
import { ReactComponent as CompanyIcon } from "assets/img/burger/companyIcon.svg";
import { ReactComponent as GearIcon } from "assets/img/burger/gear.svg";
import { ReactComponent as AngleLeftSolidIcon } from "assets/img/burger/angleLeftSolid.svg";
import { ReactComponent as TemplateIcon } from "assets/img/general/navbar-template.svg";
import { ReactComponent as DocumentListIcon } from "assets/img/burger/file.svg";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "const/appRoutes";
import { helpCenterURL, termsURL, vanillaJsOriginURL } from "const/urls";
import { Dispatch, SetStateAction, useState } from "react";
import { useLogout } from "hooks/utility/useLogout";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import { isBrowser, isMobile } from "react-device-detect";
import { currentCompanyAtom, refreshAtom } from "store/atoms";
import { useAtom } from "jotai/react";
import { useQueryClient } from "@tanstack/react-query";
import { useSegment } from "hooks/utility/useSegment";

interface IChangeProfileComponent {
  className?: string;
  classNames?: {
    container?: string;
  };
  context: Partial<IAuthContext> | undefined;
  companies?: ICompanyInfo[];
  showChangeCompanyPopup?: boolean;
  setShowChangeCompanyPopup?: Dispatch<SetStateAction<boolean>>;
}

function ChangeProfileComponent({
  context,
  showChangeCompanyPopup,
  className,
  classNames,
  setShowChangeCompanyPopup,
  companies,
}: IChangeProfileComponent) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { identifyUserSegment } = useSegment();

  return isMobile ? (
    <div
      className={twMerge(
        classnames(
          "absolute flex flex-col min-h-[70px] w-full bg-[#e4e4e4]  divide-y divide-[#d8d8d8] text-87 z-[-1] duration-300 border border-[#aaa] ",
          {
            "right-0  bottom-0 ": !showChangeCompanyPopup,
            " right-0 sm-h:bottom-[147px] max-h-full sm-h:max-h-[calc(100%-147px)] z-[25] shadow-[0px_0px_12px_rgba(0,0,0,0.26)]":
              showChangeCompanyPopup,
          }
        )
      )}
    >
      <div className="flex flex-row justify-end pr-2 py-1">
        <div className=" flex  items-center  ">
          <button
            type="button"
            className=" "
            onClick={() => {
              companies &&
                companies.length > 1 &&
                setShowChangeCompanyPopup &&
                setShowChangeCompanyPopup(false);
            }}
          >
            <span className="sr-only">Close panel</span>
            <CloseCircle className="h-4 w-4" fill="#aaa" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="flex flex-col overflow-auto">
        {companies
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map((company) => (
            <MenuNavButton
              isActive={context?.companyInfo?.id === company.id}
              key={company.id}
              isInteractive
              type="custom"
              customAction={() => {
                context?.setCompanyInfo && context?.setCompanyInfo(company);
                //
                queryClient.resetQueries(["getCompanyUsers"]);
                // navigate(APP_ROUTES.PROPERTIES);
              }}
              classNames={{ linkContainer: "    " }}
              content={
                <div className="flex flex-row items-start gap-5">
                  {company.logo && company.logo.length ? (
                    <img
                      className="h-[32px] w-[32px] object-cover"
                      alt="logo"
                      src={company.logo[0].uri}
                    />
                  ) : (
                    <AccountIcon className="group-hover:fill-primaryBaseHover w-8" />
                  )}
                  <div className="flex flex-col items-start">
                    {" "}
                    <p>{company?.name}</p>
                    <span className="text-[10px]">{company?.id}</span>
                  </div>
                </div>
              }
              href={"#"}
              icon={
                company.logo && company.logo.length ? (
                  <img alt="logo" src={company.logo[0].uri} />
                ) : (
                  <AccountIcon className="group-hover:fill-primaryBaseHover w-8" />
                )
              }
            />
          ))}
      </div>
    </div>
  ) : (
    <div
      className={twMerge(
        classnames(
          "absolute flex flex-col min-h-[70px] w-full bg-[#e4e4e4] divide-y divide-[#aaa] text-87 z-[-1] duration-300 border border-[#aaa] ",
          {
            "right-0  bottom-0 ": !showChangeCompanyPopup,
            "  bottom-0    right-[350px]   max-h-full   shadow-[0px_0px_12px_rgba(0,0,0,0.26)]":
              showChangeCompanyPopup,
          }
        )
      )}
    >
      <div className="flex flex-col overflow-auto">
        {companies
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map((company) => (
            <MenuNavButton
              isActive={context?.companyInfo?.id === company.id}
              key={company.id}
              isInteractive
              type="custom"
              customAction={() => {
                context?.setCompanyInfo && context?.setCompanyInfo(company);
                queryClient.resetQueries([
                  "getCompanyUsers",
                  "getCompanyInfo",
                  "getApiKeys",
                ]);
                identifyUserSegment();
                // navigate(APP_ROUTES.PROPERTIES);
              }}
              classNames={{ linkContainer: "    " }}
              content={
                <div className="flex flex-row items-start gap-5">
                  {company.logo && company.logo.length ? (
                    <img
                      className="h-[32px] w-[32px] object-cover"
                      alt="logo"
                      src={company.logo[0].uri}
                    />
                  ) : (
                    <AccountIcon className="group-hover:fill-primaryBaseHover w-8" />
                  )}
                  <div className="flex flex-col items-start">
                    <p>{company?.name}</p>
                    <span className="text-[10px]">{company?.id}</span>
                  </div>
                </div>
              }
              href={"#"}
              icon={
                company.logo && company.logo.length ? (
                  <img alt="logo" src={company.logo[0].uri} />
                ) : (
                  <AccountIcon className="group-hover:fill-primaryBaseHover w-8" />
                )
              }
            />
          ))}
      </div>
    </div>
  );
}

interface MenuNavButtonProps {
  type: "internal" | "external" | "decorative" | "custom";
  href: string;
  content: string | React.ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  isInteractive?: boolean;
  icon?: any;
  classNames?: {
    linkContainer?: string;
  };
  customAction?: () => void;
}

function MenuNavButton({
  href,
  icon,
  content,
  type,
  disabled = false,
  isActive,
  isInteractive,
  classNames,
  customAction,
}: MenuNavButtonProps) {
  const internal = (
    <Link
      onClick={customAction}
      className={twMerge(
        classnames(
          "group w-full flex justify-start gap-5 items-center p-[20px] border-b border-b-[#aaa] bg-[#e4e4e4]  hover:bg-white hover:text-primaryBaseHover text-[16px] font-medium ",
          {
            "bg-white text-primaryBaseHover text-[16px]": isActive,
            "bg-gray-300 hover:bg-gray-300 pointer-events-none opacity-60":
              disabled,
          },
          classNames?.linkContainer
        )
      )}
      to={href}
    >
      {icon}
      <p>{content}</p>
    </Link>
  );
  const external = (
    <a
      onClick={customAction}
      rel="noreferrer"
      target="_self"
      className={twMerge(
        classnames(
          "group w-full flex justify-start gap-5 items-center p-[20px]  border-b border-b-[#aaa] bg-[#e4e4e4] hover:bg-white hover:text-primaryBaseHover  text-[16px] font-medium",
          {
            "bg-white text-primaryBaseHover text-[16px]": isActive,
            "bg-gray-300 hover:bg-gray-300 pointer-events-none opacity-60":
              disabled,
          },
          classNames?.linkContainer
        )
      )}
      href={href}
    >
      {icon} <p>{content}</p>
    </a>
  );
  const decorative = (
    <div
      onClick={customAction}
      className={twMerge(
        classnames(
          "group w-full flex justify-start gap-5 items-center p-[20px]  border-b border-b-[#aaa] bg-[#e4e4e4] hover:bg-white hover:text-primaryBaseHover  text-[16px] font-medium cursor-default select-none",

          {
            "bg-white text-primaryBaseHover text-[16px]": isActive,
            "bg-gray-300 hover:bg-gray-300 pointer-events-none opacity-60":
              disabled,
          },
          classNames?.linkContainer
        )
      )}
    >
      {icon} <div>{content}</div>
    </div>
  );
  const custom = (
    <div
      onClick={customAction}
      className={twMerge(
        classnames(
          "w-full flex justify-start gap-5 items-center p-[20px]  border-b border-b-[#aaa] bg-[#e4e4e4] text-[16px] font-medium select-none",
          {
            "bg-white text-primaryBaseHover text-[16px]": isActive,
            "hover:bg-white cursor-pointer": isInteractive,
            "bg-gray-300 hover:bg-gray-300 pointer-events-none opacity-60":
              disabled,
          },
          classNames?.linkContainer
        )
      )}
    >
      {content}
    </div>
  );
  const element =
    type === "internal"
      ? internal
      : type === "external"
      ? external
      : type === "decorative"
      ? decorative
      : custom;
  return element;
}

interface BurgerMenuProps
  extends Partial<React.HTMLAttributes<HTMLDivElement>> {
  classNames?: { input?: string; label?: string };
  context: Partial<IAuthContext> | undefined;
  showChangeCompanyPopup: boolean;
  setShowChangeCompanyPopup: Dispatch<SetStateAction<boolean>>;
  close: () => void;
}

function BurgerMenu({
  classNames,
  context,
  showChangeCompanyPopup,
  setShowChangeCompanyPopup,
  close,
}: BurgerMenuProps) {
  const logout = useLogout();
  const userData = context?.user;
  const companyData = context?.companyInfo;

  const isGuest = context?.guest;

  const isUserManager =
    context?.companyRoles?.find(
      (role) => role.companyId === context.companyInfo?.id
    )?.user_management === (true || "true" || "True");
  return (
    <div className="flex h-full flex-col  bg-[#e4e4e4] shadow-xl z-[1000] relative ">
      <div className="flex h-full flex-col overflow-auto">
        <div className="flex flex-col gap-5 p-5 z-10 bg-[#e4e4e4]  ">
          <div className="flex items-start justify-between">
            <BurgerMenuLogo className="w-32" aria-hidden="true" />

            <div className=" flex  items-center">
              <button type="button" className=" " onClick={close}>
                <span className="sr-only">Close panel</span>
                <CloseCircle
                  className="h-4 w-4"
                  fill="#aaa"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <ProfilePlaceholder
              className="h-11 w-11"
              fill="#aaa"
              aria-hidden="true"
            />
            <p>{userData?.email}</p>
          </div>
        </div>

        <div className="relative flex flex-col justify-between h-full z-10 bg-[#e4e4e4]">
          <div className="flex flex-col w-full border-y border-t-[#aaa]  ">
            <MenuNavButton
              type="external"
              content="Properties"
              href={
                vanillaJsOriginURL +
                `/projects?rcode=${context?.refresh}&companyRef=${
                  context?.companyInfo?.id
                }${isGuest ? "&guest=true" : ""}`
              }
              icon={
                <PropertiesIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
            <MenuNavButton
              type="external"
              content="All Work Orders"
              href={
                vanillaJsOriginURL +
                `/orders?rcode=${context?.refresh}&companyRef=${
                  context?.companyInfo?.id
                }${isGuest ? "&guest=true" : ""}`
              }
              icon={
                <ShovelsIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
            <MenuNavButton
              type="internal"
              content="Documents"
              href={APP_ROUTES.DOCUMENTS}
              icon={
                <DocumentListIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
            <MenuNavButton
              type="external"
              content="Help Center"
              href={helpCenterURL}
              icon={
                <HelpCenterIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
            <MenuNavButton
              // isActive={window.location.pathname.includes(
              //   `${APP_ROUTES.COMPANY_SETTINGS}/templates`
              // )}
              type="internal"
              content="Templates"
              href={`${APP_ROUTES.COMPANY_SETTINGS}/templates`}
              disabled={!isUserManager}
              icon={
                <TemplateIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
            <MenuNavButton
              // isActive={window.location.pathname.includes(
              //   APP_ROUTES.COMPANY_SETTINGS
              // )}
              type="internal"
              content="Company Settings"
              href={APP_ROUTES.COMPANY_SETTINGS}
              disabled={!isUserManager}
              icon={
                <GearIcon className="group-hover:fill-primaryBaseHover w-8" />
              }
            />
          </div>
          <div className="flex flex-col w-full border-y border-t-[#aaa]  z-40 relative">
            <MenuNavButton
              type="decorative"
              customAction={() => setShowChangeCompanyPopup((val) => !val)}
              classNames={{ linkContainer: "z-30 cursor-pointer" }}
              content={
                <div className="flex flex-col items-start">
                  <p>{companyData?.name}</p>
                  <span className="text-[10px]">{companyData?.id}</span>
                </div>
              }
              href={helpCenterURL}
              icon={
                <div className="flex flex-row gap-1 items-center">
                  {context?.availableCompanies &&
                    context?.availableCompanies.length > 1 && (
                      <AngleLeftSolidIcon className="group-hover:fill-primaryBaseHover w-2" />
                    )}
                  <CompanyIcon className="group-hover:fill-primaryBaseHover w-8" />
                </div>
              }
            />
            <MenuNavButton
              type="custom"
              classNames={{ linkContainer: "z-30" }}
              content={
                <div className="flex flex-row justify-between w-full">
                  <div
                    onClick={logout}
                    className="group hover:text-primaryBaseHover flex flex-row gap-5 items-start cursor-pointer"
                  >
                    <LogoutIcon className="group-hover:fill-primaryBaseHover w-8" />
                    <p>Log Out</p>
                  </div>
                  <div className="group hover:text-primaryBaseHover">
                    <a target="_blank" rel="noreferrer" href={termsURL}>
                      Terms
                    </a>
                  </div>
                </div>
              }
              href={helpCenterURL}
            />
          </div>
        </div>
      </div>
      <ChangeProfileComponent
        context={context}
        companies={context?.availableCompanies}
        setShowChangeCompanyPopup={setShowChangeCompanyPopup}
        showChangeCompanyPopup={showChangeCompanyPopup}
      />
    </div>
  );
}

export default BurgerMenu;
