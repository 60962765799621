import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OptionProps,
  ClearIndicatorProps,
  components,
  ValueContainerProps,
  ContainerProps,
  InputActionMeta,
} from "react-select";
import classnames from "classnames";
import { useLocation, useSearchParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "assets/img/search-icon.svg";
import { ReactComponent as NavigationIcon } from "assets/img/general/navigation-pointer.svg";
import { ReactComponent as PinIcon } from "assets/img/general/pinIcon.svg";
import { getDetails } from "use-places-autocomplete";
import {
  AdvancedSelectField,
  IAdvanceFieldProps,
} from "../AdvancedSelectField";
import { ISelectOption } from "types/utility";

enum ERROR_STATUSES {
  INVALID_REQUEST = "Google api invalid request",
  NOT_FOUND = "Not Found",
  OVER_QUERY_LIMIT = "Query limit is used",
  REQUEST_DENIED = "Request denied",
  UNKNOWN_ERROR = "Unknown Error",
}

function Option(props: OptionProps<any>) {
  return (
    <div
      className={classnames("py-1 cursor-pointer gap-2 hover:bg-gray-100", {
        "selected-option-gradient": props.isSelected,
        " hovered-selected-option-gradient px-6": !props.isSelected,
      })}
    >
      <components.Option {...props} className="flex flex-row gap-2">
        <PinIcon />
        <label className=" font-light text-sm cursor-pointer">
          {props.label}
        </label>
      </components.Option>
    </div>
  );
}

function NoOptionsMessage(props: any, handleCurrentLocation: () => void) {
  return (
    <components.NoOptionsMessage {...props} className="  !p-0 !py-2">
      <p> No Options Found</p>
      <button
        onClick={handleCurrentLocation}
        className="flex flex-row gap-3 items-center w-full text-sm underline hover:bg-gray-100  text-left p-2  pl-4 "
      >
        <NavigationIcon />

        <p>Use my current location</p>
      </button>
    </components.NoOptionsMessage>
  );
}

function ValueContainer({
  children,

  ...props
}: ValueContainerProps) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    )
  );
}

function SearchValueContainer({
  children,

  ...props
}: ValueContainerProps) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <SearchIcon className="absolute ml-1" />}
        <div className="pl-4 grid grid-flow-row items-center"> {children}</div>
      </components.ValueContainer>
    )
  );
}

function Input(props: OptionProps<any>) {
  return <components.Input {...props} isHidden={false} />;
}

function SelectContainer(props: ContainerProps<any>) {
  return <components.SelectContainer {...props} className="w-full " />;
}

export type CommonSelectType = Omit<IAdvanceFieldProps, "message"> & {
  name?: string;
  message?: string;
};

export type SearchableSelectType = CommonSelectType & {
  isSearchable: true;
  makeSearch: (value: string) => void;
  isLoading?: boolean;
};

export type AutoCompleteSelectFieldType<T> = (T extends { isSearchable: true }
  ? SearchableSelectType
  : CommonSelectType) & {
  debounceTime?: number;
  isSearchInput?: boolean;
  showSearchIcon?: boolean;
  readOnly?: boolean;
  square?: boolean;
  handleAutocompleteGeocoder?: (value: string) => void;
  handleCurrentLocation: () => void;
  handleClear?: () => void;
  returnValueOnly?: boolean;
  allowAutoFill?: boolean;
};

function getClearIndicatorComponent(clear: () => void) {
  return function ClearIndicator(props: ClearIndicatorProps) {
    const customProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        onMouseDown: () => {
          props.clearValue();
          clear();
        },
      },
    };
    return <components.ClearIndicator {...customProps} />;
  };
}

export default function AutoCompleteSelectField<T>({
  debounceTime = 100,
  showSearchIcon,
  isSearchInput,
  handleAutocompleteGeocoder,
  handleClear,
  handleCurrentLocation,
  square,
  readOnly = false,
  allowAutoFill = true,
  ...rest
}: AutoCompleteSelectFieldType<T>) {
  const {
    ready,
    suggestions: { data, loading, status },
    clearCache,
    clearSuggestions,
    setValue: setPlacesValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "en",
      region: "us",
      // componentRestrictions: { country: ["us", "ca"] },
      /* Define search scope here */
    },
    debounce: 300,
  });

  const restValue: ISelectOption<string, string> = useMemo(
    () => rest.value as ISelectOption<string, string>,
    [rest.value]
  );

  useEffect(() => {
    if (restValue && restValue.value && restValue.label) {
      setInputValue({
        value: restValue.value,
        label: restValue.label,
      });
    }
  }, [restValue]);

  const [inputValue, setInputValue] = useState<{
    value: string;
    label: string;
  } | null>();
  
  const geocodeAddress = useCallback(
    (placeId: string) => {
      getGeocode({ placeId }).then((result) => {
        const address = result[0].formatted_address;
        getDetails({ placeId }).then((place) => {
          const placeName = typeof place !== "string" && place?.name + ", ";
          setInputValue({
            value: placeName + address,
            label: placeId,
          });
        });
      });
    },
    [setInputValue]
  );

  useEffect(() => {
    if (inputValue?.label.length) {
      setPlacesValue(inputValue?.label);
    }
  }, [setPlacesValue, inputValue]);

  type InputOption = {
    label: string;
    value: string;
  };

  const [formattedData, setFormattedData] = useState<InputOption[]>([]);

  useEffect(() => {
    const fetchGeoCodeData = async () => {
      const formattedData: InputOption[] = [];
      for (const loc of data) {
        // const result = await getGeocode({ placeId: loc?.place_id });
        // const formattedAddress = result[0].formatted_address;
        // const placeName = loc?.structured_formatting?.main_text;
        const placeDesc = loc?.description;
        formattedData.push({
          // label:
          //   placeName === formattedAddress
          //     ? formattedAddress
          //     : placeName + ", " + formattedAddress,
          label: placeDesc,
          value: loc?.place_id,
        });
      }
      setFormattedData(formattedData);
    };

    fetchGeoCodeData();
  }, [data]);

  //@ts-ignore
  const error = rest.message || ERROR_STATUSES[status] || "";
  const clear = useCallback(() => {
    handleClear && handleClear();
    setInputValue(null);
    setPlacesValue("", false);
    clearSuggestions();
  }, [setPlacesValue, handleClear, clearSuggestions]);
  const location = useLocation();
  useEffect(() => {
    clear();
  }, [location.pathname, clear]);

  return (
    <AdvancedSelectField
      readOnly={readOnly}
      placeholder={rest.isDisabled ? "" : "Search"}
      isSearchInput={isSearchInput}
      square={square}
      inputValue={inputValue?.label}
      onInputChange={useCallback(
        (value: string, action: InputActionMeta) => {
          if (
            action?.action !== "input-blur" &&
            action?.action !== "menu-close"
          ) {
            setInputValue({ label: value, value });
          }
        },
        [setInputValue]
      )}
      bottomMenuListComponent={
        <button
          onClick={handleCurrentLocation}
          className="flex flex-row gap-3 items-center w-full text-sm underline hover:bg-gray-100  text-left p-2  pl-6 "
        >
          <NavigationIcon />

          <p>Use my current location</p>
        </button>
      }
      closeMenuOnSelect
      isSearchable
      isClearable={true}
      isLoading={loading}
      isDisabled={!ready}
      options={formattedData}
      message={error}
      isError={!!error}
      classNames={useMemo(() => {
        let classes: AutoCompleteSelectFieldType<T>["classNames"] = {};
        if (rest.classNames) {
          classes = { ...rest.classNames };
        }
        if (error) {
          //@ts-ignore
          classes = {
            ...classes,

            message: classes?.message
              ? classes?.message + " !text-red-500"
              : " !text-red-500",
          };
        }
        return classes;
      }, [error, rest.classNames])}
      value={inputValue}
      {...rest}
      components={{
        ...rest.components,

        SelectContainer,
        DropdownIndicator: () => null,
        Option,
        LoadingIndicator: () => null,
        ClearIndicator: getClearIndicatorComponent(clear),
        Input,
        ValueContainer: showSearchIcon ? SearchValueContainer : ValueContainer,
      }}
      onChange={(value: any) => {
        handleAutocompleteGeocoder && handleAutocompleteGeocoder(value?.label);
        //@ts-ignore
        rest?.onChange && rest?.onChange(value);
        setInputValue(value);
        clearSuggestions();
      }}
    />
  );
}
