import { strict } from "assert";
import { useAuth } from "hooks/authContext";
import { useCallback, useMemo } from "react";

export function useSegment() {
  const authStorage = localStorage.getItem("auth");
  const companyDataStorage = localStorage.getItem("currentCompany");

  const auth =
    authStorage && authStorage !== "undefined" ? JSON.parse(authStorage) : null;
  const companyData =
    companyDataStorage && companyDataStorage !== "undefined"
      ? JSON.parse(companyDataStorage)
      : null;
  const userData = useMemo(() => auth?.user, [auth?.user]);

  const identifyUserSegment = useCallback(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" &&
      userData &&
      companyData
    ) {
      const userCompanyRole = userData?.roles.find(
        (role: any) => role.companyId === companyData.id
      );
      const userManagement =
        userCompanyRole?.user_management === (true || "true" || "True")
          ? true
          : false;
      const receiveNotifications = userCompanyRole?.receive_notifications
        ? userCompanyRole.receive_notifications
        : false;

      if (!userCompanyRole) return;
      //@ts-ignore
      window.analytics.identify(userData.email, {
        email: userData.email,
        user_role: userCompanyRole.level,
        user_management_permissions: userManagement,
        order_notification_permissions: receiveNotifications,
      });
      //@ts-ignore
      window.analytics.group(companyData.id, {
        id: companyData.id,
        name: companyData?.name,
        address: companyData?.address,
        avatar:
          companyData?.logo && companyData.logo.length
            ? companyData.logo[0].uri
            : "",
      });
    }
  }, [userData, companyData]);

  const pageviewSegment = useCallback(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" &&
      userData &&
      companyData
    ) {
      identifyUserSegment();
      //@ts-ignore
      window.analytics.page();
    }
  }, [userData, companyData, identifyUserSegment]);

  const actionsLoginSegment = useCallback(() => {
    const date = new Date();
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      //@ts-ignore
      window.analytics.track("Login", {
        timestamp: date.getTime(),
      });
    }
  }, []);

  const propertyCreatedCallback = useCallback(async (data: object) => {
    const date = new Date();
    //@ts-ignore
    const { companyId, propertyId, propertyName } = data;
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      //@ts-ignore
      await window.analytics.track("Property Created", {
        timestamp: date.getTime(),
        company_id: companyId,
        property_id: propertyId,
        property_name: propertyName,
      });
    }
  }, []);

  const memberInvitedCallback = useCallback(
    (data: any) => {
      const date = new Date();

      const {
        emails,
        orderNotifications,
        userManagement,
        permission,
        companyId,
        inviter_email,
        companyName,
      } = data;
      if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
        emails.forEach((email: { value: string }) => {
          //@ts-ignore
          window.analytics.track("Member Invited", {
            timestamp: date.getTime(),
            company_id: companyId,
            company_name: companyName,
            inviter_email: inviter_email,
            recipient_email: email.value,
            recipient_role: permission.value,
            recipient_user_management_permissions: userManagement,
            recipient_order_notification_permissions: orderNotifications,
          });
          //@ts-ignore
          window.analytics.identify(email.value, {
            invite_date: date.getTime(),
            company: {
              id: companyId,
              name: companyName,
            },
            email: email.value,
            user_role: permission.value,
            user_management_permissions: userManagement,
            order_notification_permissions: orderNotifications,
            user_access_level: "company",
          });
          identifyUserSegment();
        });
      }
    },
    [userData, companyData, identifyUserSegment]
  );

  return {
    propertyCreatedCallback: propertyCreatedCallback,
    memberInvitedCallback: memberInvitedCallback,
    pageviewSegment: pageviewSegment,
    identifyUserSegment: identifyUserSegment,
    actionsLoginSegment: actionsLoginSegment,
  };
}
