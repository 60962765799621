import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Map } from "leaflet";
import { ILocation } from "types/utility";
import DraggableMarker from "./DraggableMarker/DraggableMarker";
import mapSelectedPin from "assets/img/general/mapSelectedPin.png";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useEffect, useRef, useState } from "react";

interface ILeafletMapProps {
  location: ILocation | null;
  setSearchAddress?: React.Dispatch<React.SetStateAction<string>>;
  setLocation?: React.Dispatch<React.SetStateAction<ILocation | null>>;
  setSearchResult?: React.Dispatch<React.SetStateAction<any[]>>;
  listOfLocations?: ILocation[];
  onLoad?: (...args: any) => void;
  innerRef?: React.MutableRefObject<any>;
  mapInstance?: Map | null;
  showLayersControl?: boolean;
}

const MarkerIcon = L.icon({
  iconUrl: mapSelectedPin,
  iconRetinaUrl: mapSelectedPin,
  popupAnchor: [-0, -0],
  iconSize: [32, 37],
});

function LeafletMap({
  location,
  setSearchAddress,
  setLocation,
  onLoad,
  setSearchResult,
  mapInstance,
  listOfLocations,
  innerRef,
  showLayersControl = false,
  ...rest
}: ILeafletMapProps) {
  const [layersControlPosition, setLayersControlPosition] =
    useState<L.ControlPosition>("bottomright");
  const layersRef = useRef<any>(null);
  return (
    <MapContainer
      ref={innerRef}
      center={location || [32.7766642, -96.79698789999999]}
      zoom={location?.metaData?.zoom || 4}
      style={{ height: "100%", width: "100%", zIndex: 0 }}
      whenReady={onLoad}
      zoomControl={false}
    >
      {showLayersControl && (
        <LayersControl ref={layersRef} position={layersControlPosition}>
          <LayersControl.BaseLayer name="Google Maps">
            <ReactLeafletGoogleLayer type={"satellite"} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Open Street Map">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
        </LayersControl>
      )}
      {!showLayersControl && (
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      )}
      <ZoomControl position="bottomleft" />
      {location && (
        <DraggableMarker
          draggable
          setLocation={setLocation}
          setSearchAddress={setSearchAddress}
          setSearchResult={setSearchResult}
          mapInstance={mapInstance}
          icon={MarkerIcon}
          position={[location.lat, location.lng]}
        />
      )}
      {listOfLocations &&
        listOfLocations.map((location) => {
          return (
            <Marker position={[location.lat, location.lng]}>
              {location.metaData && (
                <Popup>{location.metaData.description}</Popup>
              )}
            </Marker>
          );
        })}
    </MapContainer>
  );
}

export default LeafletMap;
