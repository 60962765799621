import L from "leaflet";

import {
  IGeocoder,
  GeocoderOptions,
  GeocodingCallback,
  geocodingParams,
  GeocodingResult,
  reverseParams,
} from "types/googleApiTypes";

/**
 * Implementation of the [Google Geocoding API](https://developers.google.com/maps/documentation/geocoding/)
 */
export interface GoogleOptions extends GeocoderOptions {}

export class GoogleCustom implements IGeocoder {
  options: GoogleOptions = {
    serviceUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  };

  constructor(options?: Partial<GoogleOptions>) {
    L.Util.setOptions(this, options);
  }

  geocode(query: string, cb: GeocodingCallback, context?: any): void {
    const params = geocodingParams(this.options, {
      key: this.options.apiKey,
      address: query,
    });

    getJSON(this.options.serviceUrl, params, (data) => {
      const results: GeocodingResult[] = [];
      if (data.results && data.results.length) {
        for (let i = 0; i <= data.results.length - 1; i++) {
          const loc = data.results[i];

          const latLng = L.latLng(loc.geometry.location);
          const latLngBounds = L.latLngBounds(
            L.latLng(loc.geometry.viewport.northeast),
            L.latLng(loc.geometry.viewport.southwest)
          );
          results[i] = {
            name: loc.formatted_address,
            bbox: latLngBounds,
            center: latLng,
            placeId: loc.place_id,
            types: loc.types,
            properties: loc.address_components,
          };
        }
      }

      cb.call(context, results);
    });
  }

  reverse(
    location: L.LatLngLiteral,
    scale: number,
    cb: GeocodingCallback,
    context?: any
  ): void {
    const params = reverseParams(this.options, {
      key: this.options.apiKey,
      latlng: location.lat + "," + location.lng,
    });

    getJSON(this.options.serviceUrl, params, (data) => {
      const results: GeocodingResult[] = [];
      if (data.results && data.results.length) {
        for (let i = 0; i <= data.results.length - 1; i++) {
          const loc = data.results[i];
          const center = L.latLng(loc.geometry.location);
          const bbox = L.latLngBounds(
            L.latLng(loc.geometry.viewport.northeast),
            L.latLng(loc.geometry.viewport.southwest)
          );
          results[i] = {
            name: loc.formatted_address,
            bbox: bbox,
            center: center,
            placeId: loc.place_id,
            types: loc.types,
            properties: loc.address_components,
          };
        }
      }

      cb.call(context, results);
    });
  }
}

/**
 * [Class factory method](https://leafletjs.com/reference.html#class-class-factories) for {@link Google}
 * @param options the options
 */
export function google(options?: Partial<GoogleOptions>) {
  return new GoogleCustom(options);
}

/**
 * @internal
 */
export function getJSON(
  url: string,
  params: Record<string, unknown>,
  callback: (message: any) => void
): void {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState !== 4) {
      return;
    }
    let message;
    if (xmlHttp.status !== 200 && xmlHttp.status !== 304) {
      message = "";
    } else if (typeof xmlHttp.response === "string") {
      // IE doesn't parse JSON responses even with responseType: 'json'.
      try {
        message = JSON.parse(xmlHttp.response);
      } catch (e) {
        // Not a JSON response
        message = xmlHttp.response;
      }
    } else {
      message = xmlHttp.response;
    }
    callback(message);
  };
  xmlHttp.open("GET", url + getParamString(params), true);
  xmlHttp.responseType = "json";
  xmlHttp.setRequestHeader("Accept", "application/json");
  xmlHttp.send(null);
}

/**
 * @internal
 */
export function getParamString(
  obj: Record<string, unknown | unknown[]>,
  existingUrl?: string,
  uppercase?: boolean
): string {
  const params = [];
  for (const i in obj) {
    const key = encodeURIComponent(uppercase ? i.toUpperCase() : i);
    const value = obj[i];
    if (!Array.isArray(value)) {
      params.push(key + "=" + encodeURIComponent(String(value)));
    } else {
      for (let j = 0; j < value.length; j++) {
        params.push(key + "=" + encodeURIComponent(value[j]));
      }
    }
  }
  return (
    (!existingUrl || existingUrl.indexOf("?") === -1 ? "?" : "&") +
    params.join("&")
  );
}
