export enum APP_ROUTES {
  HOME = "/",
  TEST_PAGE = "/test-page",
  PROPERTIES = "/properties",
  ISSUES = "/issues",
  ORDERS = "/orders",
  ORDER = "/order",
  PDF = "/pdf",
  NO_ACCESS = "/no-access",
  EDIT_PROPERTY = "/edit-property",
  CREATE_PROPERTY = "/create-property",
  NOT_FOUND = "/404",
  USER_MANAGEMENT = "/user-management",
  COMPANY_SETTINGS = "/company-settings",
  CATEGORIES = "/categories",
  EDIT_DOCUMENT = "/edit-document",
  DOCUMENTS = "/documents",
}
