import { Formik } from "formik";
import {
  IInvitedUserFormComponentProps,
  InviteUserFormComponent,
} from "./InviteUserForm.component";
import { InviteUserFormInitialState } from "./InviteUserForm.constants";

import { useInitialState, useSubmitHandler } from "./InviteUserForm.hooks";
import formValidationSchema from "./InviteUserForm.schema";

interface IProps extends Partial<IInvitedUserFormComponentProps> {}

export default function InviteUserForm(props: IProps) {
  const initialState = useInitialState();
  const onSubmit = useSubmitHandler(props.onClose);
  return (
    <Formik<InviteUserFormInitialState>
      enableReinitialize
      initialValues={initialState}
      validationSchema={formValidationSchema()}
      onSubmit={onSubmit}
    >
      <InviteUserFormComponent {...props} />
    </Formik>
  );
}
