import * as yup from "yup";

import { FIELD_NAMES } from "./CreatePropertyForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.PROPERTY_NAME]: yup.string(),
    [FIELD_NAMES.COUNTRY]: yup.string(),
    [FIELD_NAMES.ADDRESS_LINE_1]: yup.string(),
    [FIELD_NAMES.ADDRESS_LINE_2]: yup.string(),
    [FIELD_NAMES.CITY]: yup.string(),
    [FIELD_NAMES.STATE_REGION]: yup.string(),
    [FIELD_NAMES.POSTAL_CODE]: yup.string(),
  });
}
