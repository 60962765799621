import * as yup from "yup";

import { FIELD_NAMES } from "./InviteUserForm.constants";

export default function formValidationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.EMAILS]: yup
      .array(
        yup.object({
          value: yup.string().email("Email should be valid"),
          label: yup.string(),
        })
      )
      .min(1),
    [FIELD_NAMES.USER_MANAGEMENT]: yup.bool(),
    [FIELD_NAMES.NOTIFY_PEOPLE]: yup.bool().required(),
  });
}
